import { cloneElement, useState, useEffect } from "react";
import { Close } from "@carbon/icons-react";
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Button } from "../../buttons/button";
import { colors } from "../../../../../constants/theme";

export const Confirm = ({
  button,
  title,
  children,
  isLoading,
  submitHandler,
}) => {
  const [open, setOpen] = useState(false);
  const [container, setContainer] = useState(null);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  useEffect(() => {
    setContainer(document.getElementById("root"));
  }, []);
  return (
    <>
      {cloneElement(button, { onClick: onOpen })}
      <MuiDialog
        fullWidth
        container={container}
        maxWidth={"sm"}
        open={open}
        onClose={onClose}
      >
        <div className="p-4">
          <div
            className="relative"
            style={{ border: `1px solid ${colors.secondary.dark}60` }}
          >
            <DialogTitle
              sx={{
                color: colors.text.dark,
                borderBottom: "1px solid #dee2e6!important",
              }}
              className="d-flex justify-between xl:text-base 2xl:text-lg px-0 mx-4 pb-0"
            >
              <span>{title}</span>
              <IconButton onClick={onClose}>
                <Close size={24} fill={colors.text.dark} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ color: colors.text.dark, mt: 2 }}>{children}</Box>
            </DialogContent>
            <DialogActions
              sx={{
                mx: "20px",
                paddingBottom: "15px",
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                size="small"
                color="secondary"
              >
                Close
              </Button>
              {submitHandler && (
                <Button
                  isLoading={isLoading}
                  size="small"
                  className="ml-4 capitalize  xl:text-sm 2xl:text-base"
                  onClick={() => submitHandler(onClose)}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </div>
        </div>
      </MuiDialog>
    </>
  );
};
