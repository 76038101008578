import { Add, Edit } from "@carbon/icons-react";
import { Grid, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Form, Formik } from "formik";
import { AutoComplete, Input } from "../../../components/layouts/forms/inputs";
import { Button } from "../../../components/layouts/commonLayouts/buttons";
import { toast } from "../../../utils/toaster";
import { useGetAll } from "../../../hooks";
import { url } from "../../../urls";
import serverAPI from "../../../utils/serverAPI";
import { DateTimePicker } from "../../../components/layouts/forms/inputs/dateTimePicker";

const ResolutionInformation = ({ id, state}) => {
  const [loading, setLoading] = useState(true);
    const { data } = useGetAll({
      key: `/problems/resolution/${id}`
    });
  const initialState = {
    resolved_by:"",
    resolved_notes: "",
    resolved_at:"",
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return (
      <Box className="d-flex justify-content-center align-items-center w-full" style={{ height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Formik
         initialValues={{ ...initialState, ...data }}
         onSubmit={async (values) => {
           console.log('Inside___!!!!!!!!!!!!!!!!!!!!!!')
           const payload = {
            resolved_by:values?.resolved_by.manager.id || "",
            resolved_notes:values?.resolved_notes || "",
            resolved_at:values?.resolved_at || "",
           }
           const res = await serverAPI.put(
             `${url}/problems/resolution/${id}`,
             payload,
           );
           if (res?.status === 202) {  
             console.log("Form submitted with values:", values);
             toast(`Clouser added successfully`);
           }
         }}
      >
        <Form>
          <Grid container spacing={3} className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1">
            <Grid item xs={11} >
              <AutoComplete url={"/portal/departments/0"} getOptionLabel={(opt) => opt?.manager?.profile?.full_name|| opt?.profile?.full_name || ''} name="resolved_by" label="Resolved By" disabled={state !== 'Resolved'} required />
            </Grid>
            <Grid item xs={11}>
              {/* <Input name="resolved_at" label="Resolved on" multiline rows={2} required /> */}
              <DateTimePicker label="Resolved on" disabled={state !== 'Resolved'} name= "resolved_at"/>
            </Grid>
            <Grid item xs={11}>
              <Input name="resolved_notes" label="Fix Notes" multiline disabled={state !== 'Resolved'} rows={2} required />
            </Grid>
            <Grid item xs={12}>
              <Box className="mt-5 d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default ResolutionInformation;