import React, { useRef, useState } from "react";
import { useEffect } from "react";

const TaskNotes = ({
  id,
  putTask,
  note: defaultNote = "Add Notes...",
  onChange,
}) => {
  const noteRef = useRef(null);
  const [note, setNote] = useState("");

  useEffect(() => {
    if (!id) return;
    noteRef.current.onblur = function () {
      putTask({ id, note: noteRef.current.value });
    };
  }, [defaultNote, id]);
  useEffect(() => {
    setNote(defaultNote);
  }, [defaultNote]);
  return (
    <>
      <i className=" ms-3 fas fa-sticky-note"></i>
      <label className="ms-3">Add Notes :</label>
      <textarea
        ref={noteRef}
        style={{
          width: "100%",
          resize: "none",
          minHeight: "100px",
          outline: "none",
          border: "none",
          fontSize: "16px",
          padding: "0 1rem",
        }}
        name="note"
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
          onChange(e);
        }}
      />
    </>
  );
};

export default TaskNotes;
