import { colors } from "../../../../constants/theme";
import React from "react";

export const StatusCard = ({ text, variant }) => {
  return (
    <div
      className={`px-4 py-2 rounded-xl text-center`}
      style={{ background: colors.status[variant].background }}
    >
      <p
        style={{
          color: colors.status[variant].text,
        }}
        className="m-0  xl:text-[13px]	2xl:text-semi-base"
      >
        {text}
      </p>
    </div>
  );
};
