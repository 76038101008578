import React from "react";
import InputLabel from "../../components/layouts/commonLayouts/InputLabel";
import SelectFieldNF from "../../components/layouts/forms/NonFormik/SelectFieldNF";
import { Button, Chip, Grid, IconButton } from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import InputFieldNF from "../layouts/forms/NonFormik/InputFieldNF";

const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];
const TriggerForm = ({
  filters,
  setFilters,
  triggerData,
  setTriggerData,
  handleTriggerSubmit,
  setActionsData,
}) => {
  const [showCondition, setShowCondition] = React.useState(false);
  const [id, setId] = React.useState(0);
  const incident = triggerData?.table === "Incident";
  const ConditionFields = (key) => {
    let obj = {
      // caller: () => {
      //   return {
      //     url: "/accounts/usernamewithid",
      //     label: "full_name",
      //     value: "id",
      //   };
      // },
      IsCaller: () => {
        return {
          url: "/accounts/usernamewithid",
          label: "full_name",
          value: "id",
        };
      },
      "Raised By": () => {
        return {
          url: "/accounts/usernamewithid",
          label: "full_name",
          value: "id",
        };
      },
      Category: () => {
        return {
          url: incident
            ? `/portal/categories/0`
            : `/requests/product/category/get-all`,
          label: "name",
          value: "id",
        };
      },
      "Sub Category": () => {
        return {
          url: incident
            ? `/portal/get-all-subcategories`
            : `/requests/product/subcategory/get-all/${id}`,
          label: incident ? "name" : "sc_name",
          value: "id",
        };
      },
      // sub_category: () => {
      //   return {
      //     url: incident
      //       ? `/portal/get-all-subcategories`
      //       : `/requests/product/subcategory/get-all/${id}`,
      //     label: incident ? "name" : "sc_name",
      //     value: "id",
      //   };
      // },
      service: () => "text",
      contact_type: () => "text",
      contact_email: () => "text",
      contact_phone: () => "text",
      Status: () =>
        incident
          ? ["New", "In Progress", "On Hold", "Resolved", "Closed", "Cancelled"]
          : [
              "Pending Approval",
              "In Progress",
              "Awaiting Replace/Return",
              "Closed Complete",
              "Cancelled",
            ],
      impact: () => ["High", "Medium", "Low"],
      urgency: () => ["High", "Medium", "Low"],
      assigned_grp: () => {
        return { url: `/portal/departments/0`, label: "name", value: "id" };
      },
      assigned_to: () => {
        return {
          url: `/portal/usersbysubs-workflow`,
          label: "name",
          value: "id",
        };
      },
      "Short Description": () => "text",
      "Requested By": () => {
        return {
          url: "/accounts/usernamewithid",
          label: "full_name",
          value: "id",
        };
      },
      "Product/Service": () => {
        return {
          url: "/requests/product/get-all",
          label: "name",
          value: "id",
        };
      },
      Price: () => "text",
      Quantity: () => "text",
      "Is Open": () => ["True", "False"],
      Justification: () => "text",
      Approval: () => [
        "Requested",
        "1st level approval",
        "Approved",
        "Rejected",
        "No Longer Required",
        "Duplicate",
      ],
      "Comments 1": () => "text",
      "Comments 2": () => "text",
    };
    return obj[key] ? obj[key]() : [];
  };

  const conditions = [
    "Caller",
    "Raised By",
    "Category",
    "Sub Category",
    "service",
    "contact_type",
    "contact_email",
    "contact_phone",
    "Status",
    "impact",
    "urgency",
    "assigned_grp",
    "assigned_to",
    "Short Description",
  ];

  const requestConditions = [
    "Raised By",
    "Requested By",
    "Product/Service",
    "Price",
    "Status",
    "Approval",
    "Quantity",
    "Is Open",
    "Short Description",
    "Justification",
    "Comments 1",
    "Comments 2",
    "Category",
    "Sub Category",
  ];

  const handleChange = (array, id, name, value) => {
    let newArray = [];
    array.map((item) => {
      if (item.id === id) {
        item?.field_name === "Category" && !incident && setId(value?.id);
        item?.field_name === "Product/Service" &&
          value?.is_ad_related &&
          setActionsData((prev) => ({ ...prev, is_ad_related: true }));
        item?.field_name === "Product/Service" &&
          !value?.is_ad_related &&
          setActionsData((prev) => ({ ...prev, is_ad_related: false }));
        newArray.push({
          ...item,
          [name]: value,
        });

        return null;
      } else {
        newArray.push(item);
        return null;
      }
    });

    return newArray;
  };

  return (
    <>
      <Grid container direction={"column"}>
        <h5 className="mb-4"> TRIGGER</h5>

        <Grid container xs={6} className="px-2 mb-3">
          <Grid item xs={4}>
            <InputLabel>Trigger</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectFieldNF
              name="type"
              value={triggerData?.type ? triggerData?.type : ""}
              onChange={(value, reason) => {
                if (reason === "clear") {
                  setTriggerData({
                    table: "",
                  });
                }
                setTriggerData((prev) => ({ ...prev, type: value }));
              }}
              options={["Created", "Updated", "Created or Updated"]}
            />
          </Grid>
        </Grid>

        <Grid container xs={6} className="px-2 mb-3">
          <Grid item xs={4}>
            <InputLabel required>Module</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectFieldNF
              name="table"
              disable={!triggerData?.type}
              defaultValue={triggerData?.table ? triggerData?.table : ""}
              value={triggerData?.table ? triggerData?.table : ""}
              onChange={(value) =>
                setTriggerData((prev) => ({ ...prev, table: value }))
              }
              options={["Incident", "ServiceRequest"]}
            />
          </Grid>
        </Grid>
      </Grid>

      {triggerData.table && (
        <Grid container className="px-2 mb-3">
          <Grid item xs={2}>
            <InputLabel required>Condition</InputLabel>
          </Grid>
          <Grid item xs={10}>
            {!showCondition && (
              <div
                className="d-flex align-items-center py-1"
                style={{ cursor: "pointer" }}
                onClick={() => setShowCondition(true)}
              >
                <AddCircleOutline className="me-2" /> Add filters
              </div>
            )}

            {showCondition && (
              <div>
                {filters?.map((item, index) => (
                  <Grid container key={item.id} className="mb-1">
                    <Grid container>
                      <Grid item xs={3} className="pe-1">
                        <SelectFieldNF
                          name="field_name"
                          value={item.field_name}
                          onChange={(value) => {
                            setFilters(
                              handleChange(
                                handleChange(
                                  filters,
                                  item.id,
                                  "field_name",
                                  value
                                ),
                                item.id,
                                "field_value",
                                ""
                              )
                            );
                          }}
                          options={
                            triggerData?.table === "Incident"
                              ? conditions
                              : requestConditions
                          }
                          getOptionLabel={(option) =>
                            option?.replaceAll("_", " ")
                          }
                        />
                      </Grid>
                      {item?.field_name !== "is_AD_related" && (
                        <Grid item xs={3} className="pe-1">
                          <SelectFieldNF
                            name="comparison_operator"
                            onChange={(value) => {
                              setFilters(
                                handleChange(
                                  filters,
                                  item.id,
                                  "comparison_operator",
                                  value
                                )
                              );
                            }}
                            defaultValue={"is"}
                            options={["is", "is not", "is like"]}
                            value={
                              item?.comparison_operator
                                ? item?.comparison_operator
                                : null
                            }
                          />
                        </Grid>
                      )}

                      <Grid item xs={3}>
                        {ConditionFields(item?.field_name) === "text" ? (
                          <InputFieldNF
                            name="field_value"
                            value={item?.field_value ? item?.field_value : ""}
                            onChange={(e) =>
                              setFilters(
                                handleChange(
                                  filters,
                                  item.id,
                                  "field_value",
                                  e.target.value
                                )
                              )
                            }
                          />
                        ) : (
                          <SelectFieldNF
                            id="field1"
                            name="field_value"
                            value={item?.field_value ? item?.field_value : null}
                            onChange={(value) => {
                              setFilters(
                                handleChange(
                                  filters,
                                  item.id,
                                  "field_value",
                                  value
                                )
                              );
                            }}
                            getOptionLabel={(option) =>
                              !Array.isArray(ConditionFields(item?.field_name))
                                ? option[
                                    ConditionFields(item?.field_name)?.label
                                  ]
                                : option
                            }
                            url={
                              ConditionFields(item?.field_name)
                                ? ConditionFields(item?.field_name)?.url
                                : false
                            }
                            options={
                              Array.isArray(ConditionFields(item?.field_name))
                                ? ConditionFields(item?.field_name)
                                : []
                            }
                          />
                        )}
                      </Grid>

                      <Grid item xs={3}>
                        <div className="d-flex align-items-center h-100">
                          <IconButton
                            onClick={() => {
                              filters[index]?.field_name ===
                                "Product/Service" &&
                                filters[index]?.field_value?.is_ad_related &&
                                setActionsData((prev) => ({
                                  prev,
                                  is_ad_related: false,
                                }));
                              if (filters.length !== 1) {
                                setFilters(
                                  filters.filter((i) => item.id !== i.id)
                                );
                              } else {
                                setShowCondition(false);
                                setFilters([
                                  {
                                    id: Date.now(),
                                    field_name: "",
                                    field_value: "",
                                    comparison_operator: "",
                                    logical_operator: "",
                                  },
                                ]);
                              }
                            }}
                          >
                            <RemoveCircleOutline />
                          </IconButton>

                          <Chip
                            label="OR"
                            className="me-2"
                            color="warning"
                            onClick={() => {
                              setFilters(
                                insert(
                                  filters.map((i) =>
                                    i.id === item.id
                                      ? { ...i, logical_operator: "or" }
                                      : i
                                  ),
                                  index + 1,
                                  {
                                    id: Date.now(),
                                    field_name: "",
                                    field_value: "",
                                    comparison_operator: "",
                                    logical_operator: item.logical_operator,
                                  }
                                )
                              );
                            }}
                          />
                          <Chip
                            label="AND"
                            color="info"
                            onClick={() =>
                              setFilters(
                                insert(
                                  filters.map((i) =>
                                    i.id === item.id
                                      ? { ...i, logical_operator: "and" }
                                      : i
                                  ),
                                  index + 1,
                                  {
                                    id: Date.now(),
                                    field_name: "",
                                    field_value: "",
                                    comparison_operator: "",
                                    logical_operator: item.logical_operator,
                                  }
                                )
                              )
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                    {item.logical_operator && (
                      <p className="m-0 mt-2">{item.logical_operator}</p>
                    )}
                  </Grid>
                ))}
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container className="my-3" justifyContent={"flex-end"}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleTriggerSubmit()}
          disabled={!filters[0]?.field_value}
        >
          save
        </Button>
      </Grid>
    </>
  );
};

export default TriggerForm;
