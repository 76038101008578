import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import CardHeader from "./CardHeader";

const dummyData = [
  {
    name: "Parking",
    Open: 100,
    "In-Progress": 150,
    Closed: 200,
    Cancelled: 50,
  },
  {
    name: "AD Compliance",
    Open: 300,
    "In-Progress": 100,
    Closed: 50,
    Cancelled: 80,
  },
  {
    name: "CMS",
    Open: 200,
    "In-Progress": 150,
    Closed: 250,
    Cancelled: 30,
  },
];

const DashboardBarChart = ({ data, title }) => {
  return (
    <>
      <div className="d-flex flex-column flex-start h-100 pt-3">
        <CardHeader title={title} />
        <ResponsiveContainer>
          <BarChart
            data={data ? data : dummyData}
            margin={{
              top: 20,
              right: 30,
              left: -20,
              bottom: 20,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              stroke={"#889696"}
              axisLine={{ stroke: "#5581A0" }}
            />
            <YAxis stroke={"#889696"} axisLine={false} />
            <Tooltip />
            <Legend />
            <Bar dataKey="Open" fill="#5AC2F7" />
            <Bar dataKey="In-Progress" fill="#F7BA1E" />
            <Bar dataKey="Resolved" fill="#6CBE45" />
            <Bar dataKey="Closed" fill="#14C9C9" />
            <Bar dataKey="Cancelled" fill="#F7685B" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
export default DashboardBarChart;
