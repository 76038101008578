import { useEffect, useState } from "react";
import DtPicker from "react-calendar-datetime-picker";
import "react-calendar-datetime-picker/dist/index.css";

const TaskDateFilter = ({ onChange }) => {
  const defaultValue = {
    from: null,
    to: null,
  };
  const [date, setDate] = useState(defaultValue);

  useEffect(() => {
    onChange("Date", date);
    console.log(date);
    if (!date?.from || !date?.to) return () => {};
  }, [date]);

  
  return (
    <DtPicker
      initValue={date}
      onChange={(e) => {
        setDate(e);
      }}
      type="range"
    />
  );
};

export default TaskDateFilter;
