import React, { useState } from "react";
import { useEffect } from "react";
import TaskCheckbox from "../TaskCheckbox";

const TaskDetailsHeader = ({
  // data,
  // putTask,
  onChange,
  formData,
  getAssignedSelf,
  USER_ID,
  disablityOfInput,
}) => {
  const [assignedSelf, setAssignedSelf] = useState(false);

  useEffect(() => {
    // const USER_ID = localStorage.getItem("data").USER_ID;
    setAssignedSelf(formData.users.some((u) => u.user.id === USER_ID));
  }, []);

  const assignedSelfHandler = () => {
    if (disablityOfInput) return;
    setAssignedSelf(!assignedSelf);
    getAssignedSelf && getAssignedSelf(!assignedSelf);
  };
  // const titleRef = useRef(null);
  // const descRef = useRef(null);

  let id = null;

  // useEffect(() => {
  //   id = formData.id;

  //   descRef.current.onblur = function () {
  //     putTask({ id: formData.id, description: descRef.current.value });
  //   };
  //   titleRef.current.onblur = function () {
  //     putTask({ id: formData.id, title: titleRef.current.value });
  //   };
  // }, [formData]);

  return (
    <>
      <div className="task-item-details-header d-flex  align-items-center">
        {/* <TaskCheckbox checked={selected} onClick={selectHandler} /> */}

        <input
          contentEditable={!true}
          // ref={titleRef}
          style={{
            fontSize: "20px",
            flex: 1,
            marginLeft: "1rem",
            border: "none",
            outline: "none",
          }}
          name="title"
          onChange={onChange}
          value={formData.title}
        />
        {/* <StarredChecbox value={starred} onClick={starredHandler} /> */}
      </div>
      <textarea
        // ref={descRef}
        style={{
          paddingTop: "1rem",
          marginTop: "0.5rem",
          borderTop: "1px whitesmoke solid",
          fontSize: "16px",
          flex: 1,
          marginLeft: "1rem",
          border: "none",
          outline: "none",
          width: "100%",
          resize: "none",
        }}
        name="description"
        onChange={onChange}
        value={formData.description}
      />
      {!disablityOfInput && (
        <div className="d-flex ">
          <div style={{ transform: "scale(0.8)" }}>
            <TaskCheckbox
              disabled={disablityOfInput}
              checked={assignedSelf}
              onClick={assignedSelfHandler}
              className="file-input"
            />
          </div>
          <p
            style={{ fontSize: "16px", marginTop: "0px", marginLeft: "0.5rem" }}
          >
            Assign to self
          </p>
        </div>
      )}
    </>
  );
};

export default TaskDetailsHeader;
