import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { url } from "../../../urls";
import serverAPI from "../../../utils/serverAPI";

const FileInput = ({
  icon,
  label,
  type,
  file,
  putTask,
  id,
  onChange,
  onDelete,
  list,
  disabled,
}) => {
  const inputRef = useRef(null);
  const onClick = () => {
    inputRef.current.click();
  };

  const [fileInput, setFileInput] = useState([]);

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!id) return null;
    serverAPI
      .get(url + "/tasks/attachment/" + id)
      .then((res) => {
        setFileList([]);
        res.data?.forEach((data) => {
          const f = FileFormatter(data);
          setFileList((list) => [...list, f]);
        });
      })
      .catch((err) => {
        // console.clear();
      });
  }, [id]);

  const handleFile = (e) => {
    const fd = new FormData();
    fd.append("task", id);
    fd.append("file", inputRef.current.files[0]);
    serverAPI.post(url + "/tasks/attachment/", fd).then((res) => {
      const newFile = FileFormatter(res.data);
      setFileList((list) => [...list, newFile]);
    });
  };

  useEffect(() => setFileInput(file), [file]);

  function FileFormatter(data) {
    return {
      addr: data.file,
      name: data.file.split("/").reverse()[0],
      id: data.id,
      task: data.task,
    };
  }

  const deleteFileHandler = (id) => {
    serverAPI.delete(url + "/tasks/attachment/" + id);
    setFileList((files) => files.filter((f) => f.id !== id));
  };

  return (
    <>
      {!disabled && (
        <div
          onClick={onClick}
          className="d-flex align-items-center w-100 file-input"
          style={{ gap: "1rem", cursor: "pointer" }}
        >
          <div>{icon}</div>
          <div style={{ fontSize: "16px" }}>{label}</div>
          <input
            ref={inputRef}
            hidden
            style={{
              border: "none",
              marginLeft: "0.5rem",
            }}
            type={type || "text"}
            placeholder={label}
            // value={fileInput}
            multiple
            onChange={onChange}
          />
        </div>
      )}
      <div className="mt-1">
        {/* {list.length !== 0 && ( */}
        <>
          {(list.length !== 0 || disabled) && "Files : "} <div></div>{" "}
          {list.length !== 0 ? <div></div> : "No Files Attached"}
        </>
        {/* )} */}
        {list?.map((f, index) => (
          <div className="d-flex" key={index}>
            <a
              target="file"
              key={index}
              download
              href={url + f.addr}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "300px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {index + 1}) {f.name}
            </a>
            {!disabled && (
              <span
                style={{ cursor: "pointer" }}
                className="ms-2 text-danger"
                onClick={() => onDelete(f)}
              >
                Delete
              </span>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default FileInput;
