import { useQuery } from "react-query";
import serverAPI from "../utils/serverAPI";
import setAuthToken from "../utils/setAuthToken";

function getData(url, params) {
  setAuthToken(JSON.parse(localStorage.getItem("token")));

  return serverAPI.get(url, {
    params: params ? params : {},
  });
}

export function useGetAll(options) {
  let key = options?.params
    ? [options.key, JSON.stringify(options?.params)]
    : options?.key;
  return useQuery(key, () => getData(options.key, options?.params), {
    retry: false,
    select: (data) => data?.data || data,

    ...options,
  });
}
