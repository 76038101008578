import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PortalContext from "../../context/portalContext/portalContext";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import DangerAlerts from "../alerts/DangerAlerts";

const Navbar = ({ heading, toHome }) => {
  const userAuthContext = useContext(UserAuthContext);
  const { logout, userError, userSuccess } = userAuthContext;
  const history = useHistory();
  const portalContext = useContext(PortalContext);
  const { error } = portalContext;

  const location = useLocation();
  const logOut = () => {
    logout(history);
  };
  const goToPreviousPath = () => {
    if (toHome) {
      history.push("/");
    } else {
      history.goBack();
    }
  };

  return (
    <header
      className="header-wrapper "
      style={
        location.pathname === "/user-profile" ||
        location.pathname === "/not-acivated"
          ? { paddingLeft: "" }
          : { paddingLeft: "320px" }
      }
    >
      <div className="header-item header-title-wrapper">
        <svg
          onClick={goToPreviousPath}
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="26"
            height="26"
            rx="5"
            fill="#109CF1"
            fillOpacity="0.17"
          />
          <path
            d="M10 13.0625L16.0625 7L16.9112 7.84875L11.6975 13.0625L16.9112 18.2762L16.0625 19.125L10 13.0625Z"
            fill="#109CF1"
          />
        </svg>

        <p className="header-title">{heading}</p>
      </div>
      <div>
        {userSuccess && <DangerAlerts data={userSuccess} />}
        {userError && <DangerAlerts data={userError} />}
        {error && <DangerAlerts data={error} />}
      </div>
      <nav className="header-item header-nav">
        <ul className="header-nav-list">
          <li
            style={{ cursor: "pointer" }}
            onClick={logOut}
            className="header-nav-list-item"
          >
            Log Out
          </li>
        </ul>
      </nav>
    </header>
  );
};

Navbar.defaultProps = {
  toHome: false,
};

export default React.memo(Navbar);
