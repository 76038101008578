import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";

const optionCreater = (data) => {
  let output = data.map(({ full_name: label, id: value, ...rest }) => ({
    label,
    value,
    ...rest,
  }));
  return output;
};

const UsersSelectSearch = ({
  data,
  setUsers,
  selectedData,
  isClear,
  setIsClear,
}) => {
  const Options = optionCreater(data);

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  const [selected, setSelected] = useState([]);

  const selectedOptionCreater = (data, setSelected) => {
    let op =
      data &&
      data.map((item) => ({
        full_name: item.profile ? item.profile.full_name : "",
        id: item.id,
      }));
    let output =
      op &&
      op.map(({ full_name: label, id: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }));
    setSelected(output ? output : []);
  };

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    setUsers(selectedOptions.map((item) => item.value).join());
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
  };

  useEffect(() => {
    selectedOptionCreater(selectedData, setSelected);
    // eslint-disable-next-line
  }, [selectedData]);

  useEffect(() => {
    if (isClear) {
      setSelected([]);
      setUsers("");
      setIsClear(false);
    }
    // eslint-disable-next-line
  }, [isClear]);
  return (
    <Fragment>
      <Select
        useDragHandle
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        id="user"
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        options={Options}
        value={selected}
        onChange={onChange}
        closeMenuOnSelect={false}
      />
    </Fragment>
  );
};
export default React.memo(UsersSelectSearch);
