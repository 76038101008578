  import { Add, Edit } from "@carbon/icons-react";
  import { Grid, Box, CircularProgress } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Form, Formik } from "formik";
  import { Input } from "../../../components/layouts/forms/inputs";
  import { Button } from "../../../components/layouts/commonLayouts/buttons";
  import { toast } from "../../../utils/toaster";
  import serverAPI from "../../../utils/serverAPI"; 
  import { url } from "../../../urls";
  import { useGetAll } from "../../../hooks";

  const AnalysisInformation = ({ id, state }) => {
    const [loading, setLoading] = useState(true);
    const { data } = useGetAll({
      key: `/problems/analysis/${id}`
    });
    const initialState = {
      cause_notes: "",
      description: "",
      work_around: "",
    };
    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 200);
  
      return () => clearTimeout(timer);
    }, []);
  
    if (loading) {
      return (
        <Box className="d-flex justify-content-center align-items-center w-full" style={{ height: '50vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <>
        
        <Formik
          initialValues={{ ...initialState, ...data }}
          onSubmit={async (values) => {
            console.log('Inside___!!!!!!!!!!!!!!!!!!!!!!')
            const payload = {
              cause_notes:values?.cause_notes || "",
              description:values?.description || "",
              work_around:values?.work_around || "",
            }
            const res = await serverAPI.put(
              `${url}/problems/analysis/${id}`,
              payload,
            );
            if (res?.status === 202) {  
              console.log("Form submitted with values:", values);
              toast(`Clouser added successfully`);
            }
          }}
        >
          {({ values, errors}) =>(
          <Form>
            <Grid container spacing={3} className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1">
              <Grid item xs={11}>
                <Input name="cause_notes" label="Cause Notes" multiline rows={2} disabled={state !== 'Root Cause Analysis'} required />
              </Grid>
              <Grid item xs={11}>
                <Input name="work_around" label="Workaround" multiline rows={2} disabled={state !== 'Root Cause Analysis'} required />
              </Grid>
              <Grid item xs={12}>
                <Box className="mt-5 d-flex justify-content-end">
                  <Button
                    variant="contained"
                    className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
          )}
        </Formik>
      </>
    );
  };

  export default AnalysisInformation;
