import React, { useEffect } from "react";
import { ItemDailog } from "./ConfigItemsDialog";
import { DataGrid } from "../../../components/layouts/commonLayouts/dataGrid";
import { DeleteBox } from "../../../components/layouts/commonLayouts/dialogBox/delete";
import { useGetAll } from "../../../hooks";
import { PageHeader } from "../../../components/layouts/pageHeader";

const columns = [
  {
    headerName: "Item Name",
    field: "name",
    flex: 1,
    cellClassName: "text-dark",
  },
  {
    headerName: "Description",
    field: "description",
    flex: 1,
  },

  {
    headerName: "Actions",
    field: "actions",
    flex: 1.2,
    renderCell: ({ row }) => (
      <div className="flex items-center">
        <ItemDailog isUpdate data={row} />
        
          <DeleteBox
            title="Items"
            url="/portal/items"
            data={row.id}
            refetchUrl="/portal/items/0"
          >
            Do you really want to delete this Item?
          </DeleteBox>
      </div>
    ),
  },
];

const ConfigItems = ({ history }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = "All Departments";
    }
    //eslint-disable-next-line
  }, []);

  const { data: configItems,refetch } = useGetAll({
    key: "/portal/items/0",
    params: {
      page: 0,
      limit: 25,
    },
  });
  return (
    <>
      <PageHeader/>
      <section className="main-container ">
      
        <DataGrid
          columns={columns}
          url="/portal/items/0"
          rows={configItems}
          addButton={
            <div className="flex sm:flex-col tab:flex-col  flex-wrap mt-4" >
                <ItemDailog />
            </div>
          }
        />
      </section>
    </>
  );
};

export default ConfigItems;
