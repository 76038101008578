import { Box, Divider, Typography } from "@mui/material";

export const PageHeader = ({
  header,
  divider = true,
  borderColor = "#FF832B",
  ...otherProps
}) => {
  return (
    <>
      <Box
        sx={{
          borderLeft: `4px solid ${borderColor}`,
          //   height: "32px",
        }}
        className={`px-3 ${divider ? "mb-2" : "mb-8"} py-1`}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "550",
          }}
        >
          {header}
        </Typography>
      </Box>
      {divider && <Divider className="mb-8" />}
    </>
  );
};
