import React, { Fragment, useEffect, useContext, useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import { FormContainer } from "../../components/layouts/commonLayouts/container/FormContainer";
import { Formik, Form, useFormik, useFormikContext } from "formik";
import { AutoComplete, Label } from "../../components/layouts/forms/inputs";
import { Box, Grid } from "@mui/material";
import { useCreateOrUpdate, useGetAll } from "../../hooks";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import * as yup from "yup";
import { toast } from "../../utils/toaster";

const SkipLevelManager = ({ history }) => {
  const userAuthContext = useContext(UserAuthContext);
  const { user } = userAuthContext;

  const { data: managerData, refetch } = useGetAll({
    key: `/accounts/skipmanager`,
  });

  //   useEffect(() => {
  //     if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
  //       history.push("/view-status");
  //     } else {
  //       refetch();
  //       document.title = "Skip Management";
  //     }
  //   }, []);

  //   const { mutate, isLoading } = useCreateOrUpdate({
  //     url: `/portal/notificationsconf`,
  //     method: "post",
  //     onSuccess: () => refetch(),
  //   });

  const validationSchema = yup.object().shape({
    manager: yup.object().shape({
      id: yup.number(),
      email: yup.string().email(),
      full_name: yup.string(),
    }),
  });

  console.log(managerData);

  return (
    <Fragment>
      <Navbar heading="SKIP LEVEL MANAGER" subheading="" />
      <section
        className="main-container d-flex justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <FormContainer className="border rounded">
          <Formik
            initialValues={{
              manager: managerData ? managerData : "",
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
              toast(`Email configuration saved successfully`);
            }}
          >
            {({ values }) => (
              <Box className="w-full">
                <Grid container spacing={4}>
                  <Grid xs={12} item width={"500px"}>
                    <AutoComplete
                      options={[managerData] || []}
                      className=" mb-2"
                      name="manager"
                      label="Manager"
                      getOptionLabel={(option) => option?.full_name || ""}
                      helperText={
                        " All second level approvals of requests have to be approved by this manager"
                      }
                      required
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} item className="flex mt-2 text-sm">
                  <Label
                    colorClass={"text-dark text-sm"}
                    text={"Last Updated At : "}
                    className="mr-2"
                  />
                  {values.manager?.updated_on || "-"}
                </Grid>

                <Grid xs={12} item>
                  <Box className="flex justify-end mt-8">
                    <Button
                      // isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Box>
            )}
          </Formik>
        </FormContainer>
      </section>
    </Fragment>
  );
};

export default SkipLevelManager;
