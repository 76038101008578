import { Grid, Box, Divider, Typography } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Input,
  AutoComplete,
  Label,
  Checkbox,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import Navbar from "../../components/layouts/Navbar";
import { toast } from "../../utils/toaster";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UserAvatar } from "@carbon/icons-react";
import { openUserInfoBox, closeUserInfoBox } from "../../utils/PortalUtils";
import { useHistory } from "react-router-dom";
import { useCreateOrUpdate, useGetAll } from "../../hooks";
import serverAPI from "../../utils/serverAPI";
import { url } from "../../urls";
import { SecondaryAccordion } from "../../components/layouts/commonLayouts/accordion/SecondaryAccordion";

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const userInfo = {
  user: JSON.parse(localStorage.getItem("data")),
};

const validation = yup.object({
  // serial_no: yup.string().when("returnORreplace", {
  //   is: "Replace",
  //   then: yup.string().required("Serila no is required."),
  // }),
  quantity: yup.number().min(0, "Quantity can't be in negative"),
});

const UpdateRequest = ({ match, location }) => {
  const history = useHistory();
  const { data, refetch } = useGetAll({
    key: `/requests/${match.params.req_num}/0`,
  });
  const { data: closureData } = useGetAll({
    key: `/portal/closure/${data?.req_num}`,
  });
  const { data: replaceData } = useGetAll({
    key: `/requests/product/return/${data?.req_num}`,
    select: (data) => {
      if (data?.status === 204) {
        setIsPost(true);
      }
      return data?.data;
    },
    // enabled: true,
  });
  const [justification, setJustification] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [isReplace, setIsReplace] = useState("update");
  const [state, setState] = useState("");
  const [isPost, setIsPost] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState({
    username: "",
    email: "",
    phoneno: "",
    userRole: "",
    department: "",
  });
  const initialState = {
    raised_by: JSON.parse(localStorage.getItem("data")),
    requested_by: JSON.parse(localStorage.getItem("data")),
    product: "",
    quantity: "",
    approval: "",
    state: "",
    short_desc: "",
    price: "",
    comment: "",
    comments1: "",
    comments2: "",
    chng_num: "",
    close_code: "",
    close_notes: "",
    closed_by: "",
    inc_num: "",
    prb_num: "",
    req_num: data?.req_num,
    replaceJustification: replaceData?.justification
      ? replaceData?.justification
      : "",
    returnORreplace: replaceData?.returnORreplace
      ? replaceData?.returnORreplace
      : "",
    returned: replaceData?.returned ? replaceData?.returned : false,
    serial_no: replaceData?.serial_no ? replaceData?.serial_no : "",
  };

  const getPossibleStates = (currState) => {
    if (currState === "Pending Approval") {
      setStateOptions([
        { id: 1, name: "Pending Approval" },
        { id: 3, name: "In Progress" },
        { id: 5, name: "Cancelled" },
      ]);
    } else if (currState === "In Progress") {
      setStateOptions([
        { id: 3, name: "In Progress" },
        { id: 4, name: "Closed Complete" },
        { id: 5, name: "Cancelled" },
      ]);
    } else if (currState === "Closed Complete") {
      setStateOptions([
        { id: 3, name: "Closed Complete" },
        { id: 6, name: "Awaiting Replace/Return" },
      ]);
    }
  };

  // console.log(data, "request data from backend");
  // console.log(userInfo?.user, "login user data");

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/requests/${data?.req_num}/0`,
    method: "put",
  });
  const { mutate: mutateReturn, isLoading: returnLoading } = useCreateOrUpdate({
    url: `/requests/product/return/${data?.req_num}`,
    method: isPost ? "post" : "put",
  });
  const { mutate: mutateClosure } = useCreateOrUpdate({
    url: `/portal/closure/${data?.req_num}`,
    method: "put",
  });

  const updateOnReturn = (values) => {
    if (!isPost) {
      if (
        userInfo?.user?.USER_ID !== data?.requested_by.id &&
        !values?.returned
      ) {
        toast("Please check return/replace checkbox");
      }
      if (values?.returnORreplace === "Replace" && values?.serial_no === "") {
        toast("Serail number is required", "error");
      }
    }
    mutateReturn(
      {
        justification: values?.replaceJustification,
        returnORreplace: values?.returnORreplace,
        returned: values?.returned,
        serial_no: values?.serial_no,
        req_num: data?.id,
      },
      {
        onSuccess: () => {
          toast(`Request for ${values?.returnORreplace} updated successfully`);
          if (state === "Awaiting Replace/Return") {
            mutate(
              {
                requested_by: values?.requested_by?.USER_ID
                  ? values?.requested_by?.USER_ID
                  : values?.requested_by?.id,
                justification: justification,
                short_desc: values?.short_desc,
                quantity: values?.quantity,
                product: values?.product?.id,
                state: "Closed Complete",
                comment: values?.comment,
              },
              {
                onSuccess: () => {
                  refetch();
                  setState("Closed Complete");
                  toast("Request updated succefully.");
                },
              }
            );
          }
          if (state === "Closed Complete") {
            mutate(
              {
                requested_by: values?.requested_by?.USER_ID
                  ? values?.requested_by?.USER_ID
                  : values?.requested_by?.id,
                justification: justification,
                short_desc: values?.short_desc,
                quantity: values?.quantity,
                product: values?.product?.id,
                state: "Awaiting Replace/Return",
                comment: values?.comment,
              },
              {
                onSuccess: () => {
                  refetch();
                  setState("Awaiting Replace/Return");
                  toast("Request updated succefully.");
                },
              }
            );
          }
        },
      }
    );
  };
  const fetchData = async () => {
    if (data?.requested_by) {
      const res1 = await serverAPI.get(
        `${url}/accounts/basicinfo/${data?.requested_by?.id}`
      );
      setTooltipInfo({
        username: res1.data.full_name,
        email: res1.data.email,
        phoneno: res1.data.phone,
        userRole: res1.data.user_role,
      });
    }
  };
  useEffect(() => {
    setJustification(data?.justification);
    getPossibleStates(data?.state);
    setState(data?.state);
    fetchData();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Request Management',
        page_location: 'UpdateRequest',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    if (data?.state === "Closed Complete") {
      setIsReplace("full");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <>
      <Navbar heading="CREATE NEW INCIDENT" subheading="NEW INCIDENT" />
      <section className="main-container ">
        <main className="d-flex flex-column px-4 pt-2">
          <Formik
            initialValues={{
              ...initialState,
              ...data,
              ...replaceData,
              ...closureData,
              requested_by: data?.requested_by ? data?.requested_by : {},
              raised_by: data?.raised_by ? data?.raised_by : {},
              price: data?.product?.price ? data?.product?.price : "",
              returnORreplace: replaceData?.returnORreplace
                ? replaceData?.returnORreplace
                : "",
              returned: replaceData?.returned ? replaceData?.returned : false,
              close_code: closureData?.close_code
                ? closureData?.close_code
                : "",
              comment: data?.comment || closureData?.close_notes,
            }}
            validationSchema={validation}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              if (justification) {
                mutate(
                  {
                    requested_by: values?.requested_by?.USER_ID
                      ? values?.requested_by?.USER_ID
                      : values?.requested_by?.id,
                    justification: justification,
                    short_desc: values?.short_desc,
                    quantity: values?.quantity,
                    product: values?.product?.id,
                    state: values?.state?.name
                      ? values?.state?.name
                      : values?.state,
                    comment: values?.comment,
                  },
                  {
                    onSuccess: (data) => {
                      toast("Request updated succefully.");
                      // const req_num = data.data.req_num;
                      // history.push(`/update-request/${req_num}`);
                      history.push("/requests-raised-by-me");
                    },
                  }
                );
                if (isReplace === "full") {
                  mutateClosure(
                    {
                      inc_num: "",
                      prb_num: "",
                      chng_num: "",
                      req_num: data?.id,
                      close_code: values?.close_code,
                      close_notes: values?.comment,
                      closed_by: userInfo?.user?.USER_ID,
                    },
                    {
                      onSuccess: () => {
                        toast(`Closure updated successfully`);
                      },
                    }
                  );
                }
              } else {
                toast("Justification is required", "error");
              }
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <>
                <Form>
                  <Grid container columnSpacing={6} className="grid-cols-1 sm:flex-col tab:grid-cols-2 p-4">
                    <Grid item xs={6} className="mt-4 justify-between sm:max-w-full ">
                      <AutoComplete
                        getOptionLabel={(opt) =>
                          opt.full_name || opt?.profile?.full_name || ""
                        }
                        label="Raised By"
                        name="raised_by"
                        // value={values?.raised_by}
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 justify-between sm:max-w-full ">
                      <Grid container columnSpacing={0}>
                        <Grid item xs={12}>
                          {/* {userInfo?.user?.user_role !== "User" ? (
                            <>
                              <AutoComplete
                                url="/accounts/usernamewithid"
                                getOptionLabel={(opt) =>
                                  opt.full_name || opt?.profile?.full_name || ""
                                }
                                label="Requested For"
                                value={values?.requested_by}
                                name="requested_by"
                                required
                                onChange={async (_, value) => {
                                  const res = await serverAPI.get(
                                    `${url}/accounts/basicinfo/${value?.id}`
                                  );
                                  setTooltipInfo({
                                    username: res.data.full_name,
                                    email: res.data.email,
                                    phoneno: res.data.phone,
                                    userRole: res.data.user_role,
                                    department: res.data.department,
                                  });
                                  setFieldValue("requested_by", value);
                                }}
                              />
                            </>
                          ) : (
                            <> */}
                          <Input
                            label="Requested For"
                            name="requested_by"
                            value={
                              values?.requested_by.full_name ||
                              values?.requested_by?.profile?.full_name ||
                              ""
                            }
                            disabled
                            required
                          />
                          {/* </>
                          )} */}
                        </Grid>
                        {/* <Grid item xs={1}>
                          <div
                            className="ms-2 d-inline border-2 rounded"
                            onMouseEnter={openUserInfoBox}
                            onMouseLeave={closeUserInfoBox}
                            style={{ position: "relative" }}
                          >
                            <UserAvatar
                              size={30}
                              style={{
                                color: "gray",
                                background: "white",
                              }}
                            />
                            <div
                              className="px-4 bg-white shadow user-info-box rounded my-4 pb-3"
                              style={{ display: "none" }}
                            >
                              <Grid container>
                                <Grid xs={12} item>
                                  <Input
                                    label="Name"
                                    value={tooltipInfo?.username || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Email"
                                    value={tooltipInfo?.email || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Phone no"
                                    value={tooltipInfo?.phoneno || ""}
                                  />
                                </Grid>
                                <Grid xs={12} item>
                                  <Input
                                    label="Role"
                                    value={tooltipInfo?.userRole || ""}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="mt-4 justify-between sm:max-w-full ">
                      <AutoComplete
                        options={stateOptions}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        label="Status"
                        name="state"
                        disabled={
                          !data?.raised_by ||
                          !data?.requested_by ||
                          userInfo?.user?.USER_ID === data?.raised_by?.id ||
                          userInfo?.user?.USER_ID === data?.requested_by?.id ||
                          data?.approval !== "Approved"
                            ? true
                            : false
                        }
                        onChange={(_, value) => {
                          if (value?.name === "Cancelled") {
                            if (data?.approval === "Approved") {
                              setFieldValue("state", value);
                              setState(value?.name);
                            } else {
                              setFieldValue("approval", "Rejected");
                              setFieldValue("state", value);
                              setState(value?.name);
                            }
                          } else if (value?.name === "Closed Complete") {
                            setFieldValue("state", value);
                            setState(value?.name);
                            setIsReplace("full");
                          } else {
                            setFieldValue("state", value);
                            setState(value?.name);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 justify-between sm:max-w-full ">
                      <Input
                        label={`${data?.product?.product_type}`}
                        name="product"
                        value={data?.product?.name ? data?.product?.name : ""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 justify-between sm:max-w-full ">
                      <Input
                        label="Quantity"
                        name="quantity"
                        type="number"
                        min={0}
                        disabled={
                          !data?.requested_by ||
                          !data?.raised_by ||
                          data?.approval !== "Approved" ||
                          data?.state === "Closed Complete" ||
                          data?.state === "Cancelled"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue(
                            "price",
                            Number(data?.product?.price) *
                              Number(e.target.value)
                          );
                          setFieldValue("quantity", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}className="mt-4 justify-between sm:max-w-full ">
                      <Input label="Price" name="price" disabled />
                    </Grid>

                    <Grid item xs={6} className="mt-4 justify-between sm:max-w-full ">
                      <Input
                        label="Approvals"
                        name="approval"
                        disabled
                        onChange={(e) => {
                          if (e.target.value === "Approved") {
                            setFieldValue("approval", e?.target?.value);
                            setFieldValue("state", "In Progress");
                          } else if (
                            e.target.value === "Rejected" ||
                            e.target.value === "No Longer Required" ||
                            e.target.value === "Duplicate"
                          ) {
                            setFieldValue("approval", e?.target?.value);
                            setFieldValue("state", "Cancelled");
                          } else {
                            setFieldValue("approval", e?.target?.value);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 justify-between sm:max-w-full ">
                      <Input
                        label="Short Description"
                        name="short_desc"
                        required
                        disabled={
                          !data?.requested_by ||
                          !data?.raised_by ||
                          data?.approval !== "Approved" ||
                          data?.state === "Closed Complete" ||
                          data?.state === "Cancelled"
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4 justify-between sm:max-w-full ">
                      <Label text="Justification" required />
                      <CKEditor
                        editor={ClassicEditor}
                        data={justification ? justification : ""}
                        disabled={
                          !data?.requested_by ||
                          !data?.raised_by ||
                          data?.approval !== "Approved" ||
                          data?.state === "Closed Complete" ||
                          data?.state === "Cancelled"
                            ? true
                            : false
                        }
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "300px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setJustification(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>

                    {data?.approval === "1st level approval" && (
                      <Grid item xs={12} className="mt-4 justify-between sm:max-w-full ">
                        <Input
                          multiline
                          disabled
                          rows={2}
                          label="Comment By Department Manager"
                          name="comments1"
                          required
                          helperText={
                            data?.approval === "Rejected"
                              ? "Rejected By Department Head."
                              : "Approved By Department Head."
                          }
                        />
                      </Grid>
                    )}
                    {data?.approval === "Rejected" && !data?.comments2 && (
                      <Grid item xs={12} className="mt-4 justify-between sm:max-w-full ">
                        <Input
                          multiline
                          disabled
                          rows={2}
                          label="Rejection Reason"
                          name="comments1"
                          required
                          helperText={
                            data?.approval === "Rejected"
                              ? "Rejected By Department Head."
                              : "Approved By Department Head."
                          }
                        />
                      </Grid>
                    )}
                    {data?.approval === "Approved" && (
                      <Grid item xs={12} className="mt-4">
                        <Input
                          multiline
                          disabled
                          rows={2}
                          label="Comments By Department Head"
                          name="comments1"
                          required
                          helperText={"Approved By Department Head."}
                        />
                      </Grid>
                    )}
                    {data?.comments2 && (
                      <Grid item xs={12} className="mt-4">
                        <Input
                          disabled
                          multiline
                          rows={2}
                          label="Comments By Skip Level Manager"
                          name="comments2"
                          required
                          helperText={
                            data?.approval === "Rejected"
                              ? "Rejected By Skip Level Manager."
                              : "Approved By Skip Level Manager."
                          }
                        />
                      </Grid>
                    )}
                    {data?.requested_by && data?.raised_by && (
                      <>
                        {state !== "Cancelled" && (
                          <>
                            <Box className="ml-9 mb-4 d-flex justify-content-end">
                              {state !== "Closed Complete" && (
                                <>
                                  <Grid item xs={12} className="mt-4 justify-between sm:max-w-full ">
                                    <Button
                                      variant={
                                        isReplace === "update"
                                          ? "contained"
                                          : "outlined"
                                      }
                                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                                      onClick={() => {
                                        setIsReplace("update");
                                      }}
                                    >
                                      Update Log
                                    </Button>
                                  </Grid>
                                </>
                              )}
                              {(state === "Closed Complete" ||
                                state === "Awaiting Replace/Return") && (
                                <>
                                  <Grid item xs={12} className="mt-4 justify-between sm:max-w-full ">
                                    <Button
                                      variant={
                                        isReplace === "full"
                                          ? "contained"
                                          : "outlined"
                                      }
                                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                                      onClick={() => {
                                        setIsReplace("full");
                                      }}
                                      sx={{ width: 180 }}
                                    >
                                      fulfillment Report
                                    </Button>
                                  </Grid>
                                </>
                              )}
                              {(userInfo?.user?.USER_ID ===
                                data?.requested_by.id ||
                                userInfo?.user?.in_assets_team ||
                                userInfo?.user?.user_role === "Admin") &&
                                (state === "Closed Complete" ||
                                  state === "Awaiting Replace/Return") && (
                                  <>
                                    <Grid item xs={12} className="mt-4">
                                      <Button
                                        variant={
                                          isReplace === "return"
                                            ? "contained"
                                            : "outlined"
                                        }
                                        className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                                        onClick={() => {
                                          setIsReplace("return");
                                        }}
                                        sx={{ width: 250 }}
                                      >
                                        Replace / Return Request
                                      </Button>
                                    </Grid>
                                  </>
                                )}
                            </Box>
                            <Grid container className="ml-14 p-3">
                              {isReplace === "update" &&
                                state !== "Closed Complete" && (
                                  <Grid xs={12} item className="mt-4 justify-between sm:max-w-full flex flex-col ">
                                    <Input
                                      multiline
                                      rows={3}
                                      name="comment"
                                      label="Update Log"
                                      required
                                    />
                                  </Grid>
                                )}
                              {isReplace === "full" &&
                                (state === "Closed Complete" ||
                                  state === "Awaiting Replace/Return") && (
                                  <>
                                    <Grid xs={12} item>
                                      <Input
                                        name="close_code"
                                        label="Serial Number"
                                      />
                                    </Grid>
                                    <Grid xs={12} item>
                                      <Input
                                        multiline
                                        rows={3}
                                        name="comment"
                                        label="Fullfillment Info"
                                        required
                                      />
                                    </Grid>
                                  </>
                                )}
                              {isReplace === "return" &&
                                (userInfo?.user?.USER_ID ===
                                  data?.requested_by?.id ||
                                  userInfo?.user?.in_assets_team ||
                                  state === "Awaiting Replace/Return" ||
                                  userInfo?.user?.user_role === "Admin") && (
                                  <>
                                    <Grid xs={12} item>
                                      <Input
                                        className="mt-2"
                                        name="replaceJustification"
                                        label="Justification"
                                        disabled={
                                          JSON.parse(
                                            localStorage.getItem("data")
                                          ).USER_ID === data?.requested_by?.id
                                            ? false
                                            : true
                                        }
                                        placeHolder="Write Justification Information.."
                                      />
                                    </Grid>
                                    <Grid xs={12} item>
                                      <AutoComplete
                                        options={["Return", "Replace"]}
                                        className="mt-2"
                                        name="returnORreplace"
                                        label="Return / Replace"
                                        disabled={
                                          JSON.parse(
                                            localStorage.getItem("data")
                                          ).USER_ID === data?.requested_by?.id
                                            ? false
                                            : true
                                        }
                                      />
                                    </Grid>
                                    {values?.returnORreplace === "Replace" && (
                                      <Grid xs={12} item>
                                        <Input
                                          className="mt-2"
                                          name="serial_no"
                                          label="New Serial Number"
                                          disabled={
                                            JSON.parse(
                                              localStorage.getItem("data")
                                            ).USER_ID === data?.requested_by?.id
                                              ? true
                                              : false
                                          }
                                        />
                                      </Grid>
                                    )}
                                    <Grid xs={12} item>
                                      <Checkbox
                                        className="mt-2"
                                        name="returned"
                                        label="Returned / Replace"
                                        disabled={
                                          JSON.parse(
                                            localStorage.getItem("data")
                                          ).USER_ID === data?.requested_by?.id
                                            ? true
                                            : false
                                        }
                                      />
                                    </Grid>
                                    <Grid xs={12} item>
                                      <Box className="mt-3 d-flex justify-content-end">
                                        <Button
                                          isLoading={returnLoading}
                                          variant="outlined"
                                          className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                                          onClick={() => updateOnReturn(values)}
                                        >
                                          update
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </>
                                )}
                            </Grid>
                          </>
                        )}
                      </>
                    )}

                    <Grid xs={12} item>
                      <Box className="mt-5 mb-4 d-flex justify-content-end">
                        <Button
                          isLoading={isLoading}
                          variant="contained"
                          className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                          type="submit"
                          onClick={() => {
                            console.log({ errors }, { values });
                          }}
                        >
                          Update Request
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        </main>
        {data?.update_logs && data?.update_logs?.length !== 0 && (
          <main className="d-flex flex-column px-4 pt-2 pb-8 mt-5 mb-5">
            <Label
              text="Update Log History"
              colorClass={"dark-blue"}
              className="mt-4 mb-4 text-2xl font-extrabold underline"
            />
            {data?.update_logs?.map((item) => (
              <SecondaryAccordion
                status={`Updated By: ${item?.updated_by || ""}`}
                key={item?.id}
                header={` ${item?.created_on?.split("T")[0] || ""} At ${
                  item?.created_on?.split("T")[1]?.split(".")[0]
                }`}
                className="mt-1"
              >
                <Typography className="text-lg mb-3">
                  <span className="ml-2 font-bold">Update Log : </span>
                  {item?.text || ""}
                </Typography>
                {item?.logs?.length !== 0 && (
                  <>
                    <Grid
                      container
                      className="mt-2 ml-0 border-b-2 border-black"
                      columnSpacing={2}
                    >
                      <Grid xs={6} item>
                        Field
                      </Grid>
                      <Grid xs={6} item>
                        Previous Value
                      </Grid>
                      <Grid xs={6} item>
                        Updated Value
                      </Grid>
                    </Grid>
                    <Divider />
                    {item?.logs?.map((item) => (
                      <Grid container className="mt-2 ml-0" columnSpacing={2}>
                        <Grid xs={6} item>
                          {item?.changed_field_name
                            ? item?.changed_field_name
                            : ""}
                        </Grid>
                        <Grid xs={6} item>
                          {item?.old_value ? item?.old_value : ""}
                        </Grid>
                        <Grid xs={6} item>
                          {item?.new_value ? item?.new_value : ""}
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </SecondaryAccordion>
            ))}
          </main>
        )}
      </section>
    </>
  );
};

export default UpdateRequest;
