import $ from "jquery";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";

const ToggleSideNavBar = (e) => {
  $(".sub-items").hide();
  $(".side-navbar").toggleClass("sideNavWidth");
  $("nav").toggleClass("navWidth");
  $(".main-container").toggleClass("main-ani");
  $(".far.fa-chevron-down").toggle();
  $(".sidenav-item h5").toggle(500);
  $(".sidenav-item p").toggle(500);
  $(".myprofile").removeClass("px-2");
};

const openSubSideBar = (e) => {
  if ($(".side-navbar").hasClass("sideNavWidth")) {
  } else if (
    e.target.classList.contains("h5") ||
    e.target.classList.contains("fs-5")
  ) {
    $(e.target).parent().next().toggle(500);
    if ($(e.target).hasClass("h5") || $(e.target).hasClass("fs-5")) {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).parent().toggleClass("active-list");
    } else {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).toggleClass("active-list");
    }
  } else {
    $(e.target).next().toggle(500);
    if ($(e.target).hasClass("h5") || $(e.target).hasClass("fs-5")) {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).parent().toggleClass("active-list");
    } else {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).toggleClass("active-list");
    }
  }
};

const CalculatePriority = (impact, urgency) => {
  if (impact === "High" && urgency === "High") {
    return "Critical";
  } else if (impact === "High" && urgency === "Medium") {
    return "Important";
  } else if (impact === "Medium" && urgency === "High") {
    return "Important";
  } else if (impact === "Medium" && urgency === "Medium") {
    return "Important";
  } else if (impact === "Low" && urgency === "High") {
    return "Normal";
  } else if (impact === "Low" && urgency === "Medium") {
    return "Normal";
  } else if (impact === "Low" && urgency === "Low") {
    return "Normal";
  } else if (impact === "Medium" && urgency === "Low") {
    return "Normal";
  } else if (impact === "High" && urgency === "Low") {
    return "Normal";
  }
};

const incState = [
  { id: 1, name: "New" },
  { id: 2, name: "In Progress" },
  { id: 3, name: "Resolved" },
  { id: 4, name: "On Hold" },
  { id: 5, name: "Closed" },
  { id: 6, name: "Cancelled" },
];

const impt = [
  { id: 1, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" },
];
const updated = [
  { id: 1, name: "Latest" },
  { id: 2, name: "Oldest" },
];
const priority = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Important" },
  { id: 3, name: "Critical" },
];
const prbState = [
  { id: 1, name: "New" },
  { id: 2, name: "Assess" },
  { id: 4, name: "Root Cause Analysis" },
  { id: 5, name: "Fix in Progress" },
  { id: 3, name: "Resolved" },
  { id: 6, name: "Closed" },
  { id: 7, name: "Discard" },
];

const chngState = [
  { id: 1, name: "New" },
  { id: 2, name: "Assess" },
  { id: 3, name: "Authorize" },
  { id: 4, name: "Scheduled" },
  { id: 5, name: "Implement" },
  { id: 6, name: "Review" },
  { id: 7, name: "Closed" },
  { id: 8, name: "Cancelled" },
];

const approvalsChoice = [
  { id: 1, name: "Requested" },
  { id: 2, name: "1st level approval" },
  { id: 7, name: "Approved" },
  { id: 3, name: "Rejected" },
  { id: 4, name: "No Longer Required" },
  { id: 5, name: "Duplicate" },
];

const reqState = [
  { id: 1, name: "Pending Approval" },
  { id: 2, name: "In Progress" },
  { id: 3, name: "Awaiting Replace/Return" },
  { id: 4, name: "Closed Complete" },
  { id: 5, name: "Cancelled" },
];

const getSearchInc = (text, tableData, setSearch) => {
  const data = tableData.filter((inc) => {
    if (text === "") {
      setSearch();
      return inc;
    } else {
      const regx = new RegExp(`${text}`, "gi");
      return (
        (inc.inc_num ? inc.inc_num.match(regx) : "") ||
        (inc.caller &&
          (inc.caller.profile
            ? inc.caller.profile.full_name.match(regx)
            : "-")) ||
        (inc.raised_by &&
          (inc.raised_by.profile
            ? inc.raised_by.profile.full_name.match(regx)
            : "-")) ||
        (inc.short_desc ? inc.short_desc.match(regx) : "") ||
        (inc.assigned_grp ? inc.assigned_grp.name.match(regx) : "") ||
        (inc.priority ? inc.priority.match(regx) : "") ||
        (inc.state ? inc.state.match(regx) : "") ||
        (inc.category ? inc.category.name.match(regx) : "") ||
        (inc.assigned_to &&
          (inc.assigned_to.profile
            ? inc.assigned_to.profile.full_name.match(regx)
            : "-")) ||
        (inc.sub_category ? inc.sub_category.name.match(regx) : "")
      );
    }
  });
  return data;
};

const getSearchPrb = (text, tableData) => {
  return tableData.filter((inc) => {
    if (text === "") {
      return inc;
    } else {
      const regx = new RegExp(`${text}`, "gi");
      return (
        (inc.prb_num ? inc.prb_num.match(regx) : "") ||
        (inc.caller &&
          (inc.caller.profile
            ? inc.caller.profile.full_name.match(regx)
            : "-")) ||
        (inc.raised_by &&
          (inc.raised_by.profile
            ? inc.raised_by.profile.full_name.match(regx)
            : "-")) ||
        (inc.prb_statement ? inc.prb_statement.match(regx) : "") ||
        (inc.assigned_to &&
          (inc.assigned_to.profile
            ? inc.assigned_to.profile.full_name.match(regx)
            : "-")) ||
        (inc.assigned_grp ? inc.assigned_grp.name.match(regx) : "") ||
        (inc.priority ? inc.priority.match(regx) : "") ||
        (inc.state ? inc.state.match(regx) : "") ||
        (inc.category ? inc.category.name.match(regx) : "")
      );
    }
  });
};

const getSearchChng = (text, tableData) => {
  let data = tableData.filter((inc) => {
    if (text === "") {
      return inc;
    } else {
      const regx = new RegExp(`${text}`, "gi");
      return (
        (inc.chng_num ? inc.chng_num.match(regx) : "") ||
        (inc.requested_by &&
          (inc.requested_by.profile
            ? inc.requested_by.profile.full_name.match(regx)
            : "-")) ||
        (inc.raised_by &&
          (inc.raised_by.profile
            ? inc.raised_by.profile.full_name.match(regx)
            : "-")) ||
        (inc.short_desc ? inc.short_desc.match(regx) : "") ||
        (inc.assigned_to &&
          (inc.assigned_to.profile
            ? inc.assigned_to.profile.full_name.match(regx)
            : "-")) ||
        (inc.assigned_grp ? inc.assigned_grp.name.match(regx) : "") ||
        (inc.priority ? inc.priority.match(regx) : "") ||
        (inc.state ? inc.state.match(regx) : "") ||
        (inc.category ? inc.category.name.match(regx) : "") ||
        (inc.chng_type ? inc.chng_type.match(regx) : "")
      );
    }
  });
  return data;
};

const getSearchReq = (text, tableData) => {
  let data = tableData.filter((inc) => {
    if (text === "") {
      return inc;
    } else {
      const regx = new RegExp(`${text}`, "gi");
      return (
        (inc.req_num ? inc.req_num.match(regx) : "") ||
        (inc.requested_by &&
          (inc.requested_by.profile
            ? inc.requested_by.profile.full_name.match(regx)
            : "-")) ||
        (inc.raised_by &&
          (inc.raised_by.profile
            ? inc.raised_by.profile.full_name.match(regx)
            : "-")) ||
        (inc.short_desc ? inc.short_desc.match(regx) : "") ||
        (inc.assigned_to &&
          (inc.assigned_to.profile
            ? inc.assigned_to.profile.full_name.match(regx)
            : "-")) ||
        (inc.assigned_grp ? inc.assigned_grp.name.match(regx) : "") ||
        (inc.product ? inc.product.name.match(regx) : "") ||
        (inc.state ? inc.state.match(regx) : "") ||
        (inc.product ? inc.product.category.name.match(regx) : "") ||
        (inc.product ? inc.product.product_type.match(regx) : "")
      );
    }
  });
  return data;
};

const getAccordingToFilter = (text, key, data) => {
  let matchInc = data.filter((inc) => {
    let toCheck = inc[key];
    const regx = new RegExp(`^${text}`, "gi");
    return toCheck ? toCheck.match(regx) : "";
  });
  return matchInc;
};

const openUserInfoBox = () => {
  $(".user-info-box").fadeToggle(300);
};

const closeUserInfoBox = () => {
  $(".user-info-box").fadeToggle(300);
};

const backToAll = (history) => {
  history.goBack();
};

const code = [
  { id: 1, name: "Solved (Work Around)" },
  { id: 2, name: "Solved (Permanently)" },
  { id: 3, name: "Solved Remotely (Work Around)" },
  { id: 4, name: "Solved Remotely(Permanent)" },
  { id: 5, name: "Not Solved (Not Reproducible)" },
  { id: 6, name: "Not Solved (Too Costly)" },
  { id: 7, name: "Closed Or Resolved By User" },
  { id: 8, name: "Closed - No Response From User" },
];

const onIpChange = (text, data) => {
  let prd = data.filter((item) => {
    if (text === "") {
      return item;
    } else {
      const regx = new RegExp(`^${text}`, "gi");
      return (
        (item.category ? item.category.name.match(regx) : "") ||
        (item.name ? item.name.match(regx) : "") ||
        (item.price ? item.price.match(regx) : "")
      );
    }
  });
  return prd;
};
const getPrdBycategory = (text, data) => {
  let prd = data.filter((item) => {
    if (text === "") {
      return item;
    } else {
      const regx = new RegExp(`^${text}`, "gi");
      return item.category ? item.category.name.match(regx) : "";
    }
  });
  return prd;
};

const extractContent = (s) => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

const getCsv = async (page, subUrl, name) => {
  const { data } = await serverAPI.get(`${url}/${subUrl}/csv/${page}`);
  const downloadurl = url + "/" + data;
  let a = document.createElement("a");
  a.href = downloadurl;
  a.setAttribute("download", `${name}.csv`);
  a.click();
};

export {
  CalculatePriority,
  getSearchInc,
  getAccordingToFilter,
  getSearchPrb,
  getSearchChng,
  openUserInfoBox,
  closeUserInfoBox,
  backToAll,
  onIpChange,
  getPrdBycategory,
  getSearchReq,
  extractContent,
  getCsv,
  incState,
  impt,
  updated,
  priority,
  prbState,
  chngState,
  code,
  reqState,
  approvalsChoice,
  openSubSideBar,
};

export default ToggleSideNavBar;
