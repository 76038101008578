import React, { useContext, useState } from "react";
import "../../../styles/task-management.css";
import TaskDetailsHeader from "./TaskDetailsHeader";
import TaskDetailsUserList from "./TaskDetailsUserList";
import FileInput from "./FileInput";
import TaskNotes from "./TaskNotes";
import TaskReminder from "./TaskReminder";
import TaskDueDate from "./TaskDueDate";
import { useEffect } from "react";
import TaskPriority from "./TaskPriority";
import moment from "moment";
import { url } from "../../../urls";
import PortalContext from "../../../context/portalContext/portalContext";
import serverAPI from "../../../utils/serverAPI";

const TaskItemDetails = ({
  taskDetails = {},
  deleteTask = () => {},
  putTask = () => {},
  toggleHandler,
  noEditable = false,
}) => {
  const portalContext = useContext(PortalContext);
  const { setAlert, clearError } = portalContext;
  const localData = JSON.parse(localStorage.getItem("data"));

  const [isAssignedSelf, setIsAssignedSelf] = useState(false);

  const [data, setData] = useState(taskDetails);

  const [disablityOfInput, setDisabilityInput] = useState(false);

  useEffect(() => {
    setData(taskDetails);
    return () => {};
  }, [taskDetails]);

  useEffect(() => {
    if (noEditable) {
      setDisabilityInput(noEditable);
      const taskItemDetailsInputs = document.querySelectorAll(
        ".task-item-details input, .task-item-details textarea, .task-item-details select"
      );
      taskItemDetailsInputs.forEach((input) => {
        input.setAttribute("disabled", "true");
        input.style.background = "white";
        input.style.color = "black";
        input.style.cursor = "default";
      });

      document.querySelectorAll(".file-input").forEach((i) => {
        i.style.cursor = "default";
        i.setAttribute("disabled", "true");
        i.style.background = "white";
      });

      const datePicker = document.querySelector(".react-datetime-picker");
      datePicker.style.backgroundColor = "white !important";
    }
  }, []);

  const [formData, setFormData] = useState({
    selected: data?.state,
    starred: data?.favorite,
    priority: null,
    ...data,
  });
  useEffect(() => {
    setIsAssignedSelf(
      formData.users.some((u) => u.user.id === localData.USER_ID)
    );
  }, []);

  const [userList, setUserList] = useState([]);
  const [userListOfServer, setUserLiserOfServer] = useState([]);
  const [deleteUserList, setDeleteUserList] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [fileListOfServer, setFileListOfServer] = useState([]);

  const [reminderDate, setReminderDate] = useState(null);

  useEffect(() => {
    setFormData({
      ...data,
      selected: data?.state,
      starred: data?.favorite,
      due_date: data.due_date === null ? null : new Date(data.due_date),
    });

    getFilesAttachedToTask();
    getReminderOfTask();
    return () => {};
  }, [data]);
  // console.log(formData.due_date);
  useEffect(() => {
    const filteredList = data?.users;
    // ?.filter((u) => !u.is_owner)
    // ?.filter((u) => u.user.id !== localData.USER_ID);
    // const filteredList = data?.users
    // ?.filter((u) => !u.is_owner)
    // ?.filter((u) => u.user.id !== localData.USER_ID);

    setUserLiserOfServer(filteredList);
    setUserList(filteredList);
  }, []);

  // function handlers
  const formDataHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectHandler = () =>
    setFormData({ ...formData, selected: !formData.selected });

  const starredHandler = () => {
    setFormData({ ...formData, starred: !formData.starred });
  };

  const userListHandler = (user) => {
    setUserList((list) => {
      const newList = [...new Set([...list, user])];
      let uniqueObjArray = [
        ...new Map(newList.map((obj) => [JSON.stringify(obj), obj])).values(),
      ];
      return uniqueObjArray;
    });
  };

  const removeUserHandler = (user) => {
    const user_id = user.user.id || user.user;
    const newUserList = userList.filter((user) => {
      return (user.user.id || user.user) !== user_id;
    });
    // .filter((user) => user.user !== user_id);
    if (user.user.id) setDeleteUserList((list) => [...list, user.user.id]);
    // serverAPI.delete(url + "/tasks/user/" + id + "/" + user_id, {
    //   task: id,
    //   user: user_id,
    // });
    setUserList(newUserList);
  };

  function FileFormatter(data) {
    return {
      addr: data.file,
      name: data.file?.split("/")?.reverse()[0],
      id: data.id,
      task: data.task,
    };
  }
  const fileHandler = (e) => {
    const newFile = e.target.files;
    setFileList((list) => [...list, ...newFile]);
  };

  const deleteFileHandler = (file) => {
    const id = file?.id;
    id && serverAPI.delete(url + "/tasks/attachment/" + id);
    setFileList((files) => files.filter((f) => f.id !== id));
  };

  const laterDate = moment(
    new Date().setHours(new Date().getHours() + 6)
  ).format("HH:mm");
  const tomorrowDate = moment().add(15, "days").format("ddd, HH:mm");
  const nextWeekDate = moment(new Date()).add(6, "days").format("ddd, HH:mm");

  function reminderFormatter(data) {
    let returnData = null;
    if (data === laterDate)
      returnData = moment(new Date().setHours(new Date().getHours() + 6));
    if (data === tomorrowDate) returnData = moment().add(15, "days");
    if (data === nextWeekDate) returnData = moment().add(6, "days");
    return returnData?._d;
  }

  const reminderHandler = (r) => {
    setReminderDate(r);
  };

  const dueDataHandler = (date) => {
    setFormData({ ...formData, due_date: new Date(date) });
  };
  // api handlers

  // to get userlist of assigned taks to whole users
  function postUsersOfAssignedTask() {
    const submitData = {
      task: id,
      users: userList
        .filter((user) => {
          user.id = user.user;
          if (!userListOfServer.includes(user)) {
            return user.user;
          }
        })
        .map((user) => user.id),
      assigned_by: localData.USER_ID,
    };

    isAssignedSelf
      ? submitData.users.push(localData.USER_ID)
      : serverAPI.delete(url + "/tasks/user/" + id + "/" + localData.USER_ID);

    deleteUserList.forEach((user_id) => {
      const submitData = {
        task: data.id,
        user: localData.USER_ID,
      };
      serverAPI
        .delete(url + "/tasks/user/" + id + "/" + user_id, submitData)
        .catch((err) => {
          console.log(99);
        });
    });

    return serverAPI.post(url + "/tasks/user/", submitData);
  }
  // to get files attatched to tasks
  function getFilesAttachedToTask() {
    return serverAPI
      .get(url + "/tasks/attachment/" + id)
      .then((res) => {
        setFileList([]);
        // console.log(res.data);
        res.data?.forEach((data) => {
          const f = FileFormatter(data);
          setFileList((list) => [...list, f]);
          setFileListOfServer((list) => [...list, f]);
        });
      })
      .catch((err) => {});
  }
  // to post files attatched to tasks
  function postFilesAttachedToTask() {
    const fd = new FormData();
    fd.append("task", id);
    setFileList((list) => [...new Set(list)]);
    fileList
      .filter((f) => !fileListOfServer.includes(f))
      .forEach((f) => fd.append("files", f));
    return serverAPI
      .post(url + "/tasks/attachment/", fd, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .catch((err) => console.log(err.response));
  }
  function getReminderOfTask() {
    const user_id = localData.USER_ID;
    serverAPI
      .get(url + "/tasks/user-single/" + id + "/" + user_id)
      .then((res) => {
        setReminderDate({
          time: moment(res?.data[0]?.user_reminder).format(
            "ddd, HH:mm | DD/MM/YY"
          ),
        });
      });
  }
  function putReminderOfTask() {
    console.log("Printing the users list state", userList);
    console.log(
      "Printing the users list and using map",
      userList.map((obj) => obj.id.id)[0]
    );

    // const user_id = localData.USER_ID;
    const user_id = userList.map((obj) => obj.id.id)[0];
    const submitData = {
      user_reminder:
        reminderDate?.time?.props?.value ||
        reminderFormatter(reminderDate.time),
    };
    if (reminderDate.timeValue) reminderDate.time = reminderDate.timeValue;
    return serverAPI
      .put(url + "/tasks/user/" + id + "/" + user_id, submitData)
      .then((res) => console.log(res));
  }

  const id = formData.id;

  const getAssignedSelf = (bool) => {
    setIsAssignedSelf(bool);
  };

  const onSubmit = async (e) => {
    const { id, title, description, priority, due_date, starred, selected } =
      formData;
    const submitData = {
      id,
      title,
      description,
      priority,
      due_date,
      state: selected,
      favorite: starred,
    };
    if (!title)
      return setAlert({
        msg: "Title will not be empty!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    if (!formData.due_date)
      return setAlert({
        msg: "Due Date will not be empty!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    if (!userList.length)
      return setAlert({
        msg: "Please Select to Assign to Users",
        type: "danger",
        icon: "exclamation-triangle",
      });
    // todo : use serverAPI.all
    putTask(submitData);
    postUsersOfAssignedTask();
    postFilesAttachedToTask();
    putReminderOfTask();
    toggleHandler();
    setAlert({
      msg: "Changes Saved",
      type: "success",
      icon: "check-circle",
    });
    setTimeout(() => {
      clearError(null);
    }, 5000);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="task-item-details">
        <div>
          <TaskDetailsHeader
            disablityOfInput={disablityOfInput}
            USER_ID={localData.USER_ID}
            onChange={formDataHandler}
            selectHandler={selectHandler}
            starredHandler={starredHandler}
            formData={formData}
            getAssignedSelf={getAssignedSelf}
          />
          {!disablityOfInput && <br />}
          <TaskDetailsUserList
            USER_ID={localData.USER_ID}
            disabled={disablityOfInput}
            onChange={userListHandler}
            handleDelete={removeUserHandler}
            userList={userList}
          />
        </div>
        <div>
          <TaskPriority
            disabled={disablityOfInput}
            priority={formData.priority}
            onChange={formDataHandler}
          />
        </div>
        <div>
          <FileInput
            disabled={disablityOfInput}
            id={id}
            type="file"
            icon={<i class="fal fa-paperclip"></i>}
            label="Add File"
            onChange={fileHandler}
            onDelete={deleteFileHandler}
            list={fileList}
          />
        </div>
        <div>
          <TaskReminder
            // disabled={disablityOfInput}
            onChange={reminderHandler}
            reminderDate={reminderDate}
            id={id}
          />
        </div>{" "}
        <div>
          <TaskDueDate
            disabled={disablityOfInput}
            putTask={putTask}
            onChange={dueDataHandler}
            id={id}
            dueDate={formData.due_date}
          />
        </div>
        <div>
          <TaskNotes
            disabled={disablityOfInput}
            putTask={putTask}
            id={id}
            note={formData.note}
            onChange={formDataHandler}
          />
        </div>
        <div className="task-item-details-footer flex-ver">
          <span>
            Created {moment(formData?.created_on).format("ddd-MMM-YYYY, HH:mm")}
          </span>
          {!disablityOfInput && (
            <i
              class="fal fa-trash"
              style={{ cursor: "pointer" }}
              onClick={() => deleteTask(id)}
            ></i>
          )}
        </div>
      </div>
      <div className="task-details-action-wrapper">
        {
          // !disablityOfInput &&
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => onSubmit()}
          >
            Save Changes
          </button>
        }
        <button
          type="button"
          class="btn btn-danger ms-3"
          onClick={() => toggleHandler()}
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default TaskItemDetails;
