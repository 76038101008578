import React from "react";

const CardHeader = ({
  options = { "30 days": "30 days" },
  onChange,
  title,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center px-3">
      <p
        className="m-0"
        style={{
          fontSize: "15px",
          fontWeight: "500",
          color: "#242424",
        }}
      >
        {title}
      </p>
      {/* {onChange && (
        <select
          type="text"
          name="filter"
          onChange={onChange}
          className="form-select"
          id="category"
          style={{ width: "150px" }}
        >
          {Object.entries(options).map(([key, value]) => (
            <option key={value} value={value}>
              {key}
            </option>
          ))}
        </select>
      )} */}
    </div>
  );
};

export default CardHeader;
