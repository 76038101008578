import React from "react";
import { Button as MuiButton, CircularProgress } from "@mui/material";

export const SecondaryButton = ({
  isLoading = false,
  children,
  ...otherProps
}) => {
  return (
    <MuiButton
      disableElevation
      variant={otherProps.variant || "contained"}
      sx={{
        "& .MuiButton-startIcon": {
          marginRight: "2px",
        },
        "&:hover": {
          backgroundColor: "#F2F5F7",
          transform: "scale(0.98)",
        },
        backgroundColor: "#F2F5F7",
        borderColor: "#F2F5F7",
        color: "#000",
      }}
      className={`${otherProps.className} xl:text-sm 2xl:text-base`}
      disabled={isLoading || otherProps.disabled}
      startIcon={
        (isLoading && <CircularProgress size={"15px"} color="inherit" />) ||
        otherProps.startIcon
      }
      {...otherProps}
    >
      {children}
    </MuiButton>
  );
};
