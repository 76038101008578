import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { cloneElement } from "react";

const CustomDialog = ({
  button,
  buttonName = "New",
  buttonOnClick,
  title,
  children,
  open,
  setOpen,
  handleSubmit,
}) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {button ? (
        cloneElement(button, {
          onClick: () => {
            handleClickOpen();
            buttonOnClick && buttonOnClick();
          },
        })
      ) : (
        <button
          className="btn btn-primary mx-2"
          type="button"
          onClick={handleClickOpen}
        >
          {buttonName}
        </button>
      )}

      <Dialog maxWidth={"sm"} fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialog;
