import { GuiManagement } from "@carbon/icons-react";
import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { AutoComplete, Label } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { ActionCard } from "../layouts/commonLayouts/cards/ActionCards";
import { useGetAll } from "../../hooks";
import {pushToDataLayer} from "../DataLayer/FormData"

const validations = yup.object({
  manager: yup
    .object()
    .required("Manager is required")
    .typeError("Please select valid data"),
});

export const SkipLevelDialog = () => {
  const { data: managerData, refetch } = useGetAll({
    key: `/accounts/skipmanager`,
  });

  return (
    <Dialog
      title={"Request Approval"}
      button={
        <ActionCard className="mt-8 w-96 h-32" variant="primary">
          <div className="mx-10 flex items-center justify-center">
            <GuiManagement size="32" />
            <Typography
              className="ml-1   xl:text-sm  2xl:text-base"
              sx={{ fontFamily: "'Century Gothic', 'sans-serif'" }}
            >
              Skip Level Management
            </Typography>
          </div>
        </ActionCard>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{ manager: managerData ? managerData : "" }}
          validationSchema={validations}
          enableReinitialize={true}
          onSubmit={(values) => {
              pushToDataLayer(values);

          }}
        >
          {({ values, errors }) => (
            <Form
            onSubmit={() => {
              pushToDataLayer(values);
            }}
            >
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={12} item className="mt-4">
                  <AutoComplete
                    options={[managerData] || []}
                    className=" mb-2"
                    name="manager"
                    label="Manager"
                    getOptionLabel={(option) => option?.full_name || ""}
                    helperText={
                      " All second level approvals of requests have to be approved by this manager"
                    }
                    required
                  />
                </Grid>

                <Grid xs={12} item className="mt-4">
                  <Label
                    text={`Last Updated At : ${
                      values.manager?.updated_on || "-"
                    }`}
                  />
                </Grid>

                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Discard
                    </Button>
                    <Button
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                      onClick={() => console.log(errors, "errors")}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
