import { toast as rToast } from "react-hot-toast";

let toaster = {
  success: (content) => rToast.success(content),
  error: (content) => rToast.error(content),
};

export function toast(content, type = "success") {
  if (content) {
    console.log(content);

    rToast.dismiss();
    if (toaster[type]) {
      toaster[type](content);
    }
  }
}
