import React from "react";
import { Box } from "@mui/material";

export const FormContainer = ({ children, className }) => {
  return (
    <Box className={`flex items-center justify-center ${className}`}>
      <Box className="p-10 rounded-xl border-2 flex flex-col items-center justify-center">
        {children}
      </Box>
    </Box>
  );
};
