import {
  GET_INCIDENTS,
  GET_RESOLVED_INCIDENTS,
  GET_OPENED_INC,
  GET_INC_OVERVIEW_DATA,
  GET_OPENEUNASIGNED_INC,
  INC_CREATED,
  INC_NOT_CREATED,
  GET_RAISEDBY_ME,
  SET_LOADING,
  GET_ASSIGENDTO_ME,
  GET_CATEGORIES_DATA,
  GET_TABLE_DATA,
  GET_M_TO_M_DATA,
  GET_INCIDENTS_TREND_DATA,
} from "../types";

const incidentReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_INCIDENTS:
      return {
        ...state,
        incTableData: action.payload,
      };
    case INC_CREATED:
      return {
        ...state,
        error: {
          msg: action.payload,
          type: "success",
          icon: "check-circle",
        },
      };
    case INC_NOT_CREATED:
      return {
        ...state,
        error: {
          msg: action.payload,
          type: "danger",
          icon: "exclamation-triangle",
        },
      };
    case GET_CATEGORIES_DATA:
      return {
        ...state,
        catData: action.payload,
      };
    case GET_RESOLVED_INCIDENTS:
      return {
        ...state,
        resolvedInc: action.payload,
      };
    case GET_OPENED_INC:
      return {
        ...state,
        openInc: action.payload,
      };
    case GET_RAISEDBY_ME:
      return {
        ...state,
        raisedByMe: action.payload,
      };
    case GET_ASSIGENDTO_ME:
      return {
        ...state,
        assignedToMe: action.payload,
      };
    case GET_OPENEUNASIGNED_INC:
      return {
        ...state,
        openUnassigned: action.payload,
      };
    case GET_INC_OVERVIEW_DATA:
      return {
        ...state,
        pichartDataNew: action.payload.piDataNew,
        pichartData: action.payload.piData,
        linechartData: action.payload.lineData,
      };
    case GET_TABLE_DATA:
      return {
        ...state,
        tableData: action.payload,
      };
    case GET_M_TO_M_DATA:
      return {
        ...state,
        barData: action.payload,
      };
    case GET_INCIDENTS_TREND_DATA:
      return {
        ...state,
        incidentTrendData: action.payload,
      };
    default:
      return state;
  }
};

export default incidentReducer;
