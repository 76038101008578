import * as yup from "yup";
import { Grid, Box, Typography, Divider, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Input,
  AutoComplete,
  Label,
  DropZone,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
// import Navbar from "../../components/layouts/Navbar";
import { toast } from "../../utils/toaster";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useGetAll } from "../../hooks"; 
import { Download } from "@carbon/icons-react";
import { openUserInfoBox, closeUserInfoBox } from "../../utils/PortalUtils";
import serverAPI from "../../utils/serverAPI";
import { useHistory, useLocation } from "react-router-dom";
import { CalculatePriority, importance } from "../../utils/IncidentUtils";
import { url, mediaUrl } from "../../urls";
import AttachmentUpdateModel from "../../components/layouts/AttachmentUpdateModel";
// import { PrimaryAccordion } from "../../components/layouts/commonLayouts/accordion/PrimaryAccordion";
import { SecondaryAccordion } from "../../components/layouts/commonLayouts/accordion/SecondaryAccordion";
import { PageHeader } from "../../components/layouts/pageHeader";

const contactModeOption = ["Email", "Phone", "Self Service", "Walk-in"];
const userInfo = {
  user: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
};
const initialState = {
  raised_by: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
  caller: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
  category: {},
  sub_category: {},
  service: "",
  assigned_grp: {},
  assigned_to: {},
  attachment: "",
  priority: "Normal",
  contact_type: "",
  state: "",
  impact: "",
  urgency: "",
  contact_email: "",
  contact_phone: "",
  update_log: "",
};
const incidentValidations = yup.object({
  raised_by: yup
    .object()
    .required("Field is required")
    .typeError("Please enter valid data"),
  caller: yup
    .object()
    .required("Field is required")
    .typeError("Please enter valid data"),
  update_log: yup.string().required("Update log is required"),
  attachment: yup.mixed().test(
    "fileSize",
    "Only documents up to 25MB are permitted.",
    (files) =>
      !files || // Check if `files` is defined
      Array.from(files).every((file) => file.size <= 25_000_000)
  ),
});

const IncUpdateForm = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const location = useLocation();
  const history = useHistory();
  const arr = location.pathname.split("/");
  const [description, setDescription] = useState("");
  const [statusOption, setStatusOption] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const { data } = useGetAll({
    key: `/incidents/update/${arr[arr.length - 1]}`,
  });
  const { data: tooltipData } = useGetAll({
    key: `/accounts/basicinfo/${data?.caller?.id}`,
  });
  const getAttachments = async () => {
    const res = await serverAPI.get(`${url}/incidents/attachment/${data?.id}`);
    if (res.status === 200) {
      setAttachments(res?.data);
    }
  };
  const [tooltipInfo, setTooltipInfo] = useState({
    username: "",
    email: "",
    phoneno: "",
    userRole: "",
  });
  const getPossibleStates = (currState) => {
    if (currState === "New") {
      setStatusOption([
        { id: 1, name: "New" },
        { id: 2, name: "In Progress" },
        { id: 6, name: "Cancelled" },
      ]);
    } else if (currState === "In Progress") {
      setStatusOption([
        { id: 2, name: "In Progress" },
        { id: 3, name: "On Hold" },
        { id: 4, name: "Resolved" },
        { id: 6, name: "Cancelled" },
      ]);
    } else if (currState === "Resolved") {
      setStatusOption([
        { id: 4, name: "Resolved" },
        { id: 3, name: "On Hold" },
        { id: 5, name: "Closed" },
        { id: 6, name: "Cancelled" },
      ]);
    } else if (currState === "On Hold") {
      setStatusOption([
        { id: 3, name: "On Hold" },
        { id: 2, name: "In Progress" },
        { id: 4, name: "Resolved" },
      ]);
    } else if (currState === "Closed" || currState === "Cancelled") {
      setStatusOption([
        { id: 2, name: "In Progress" },
        { id: 3, name: "On Hold" },
        { id: 5, name: "Closed" },
        { id: 6, name: "Cancelled" },
      ]);
    }
  };
  useEffect(() => {
    setTooltipInfo({
      username: tooltipData?.full_name,
      email: tooltipData?.email,
      phoneno: tooltipData?.phone,
      userRole: tooltipData?.user_role,
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Incident Management',
        page_location: 'UpdateIncident',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipData]);

  useEffect(() => {
    setDescription(data?.short_desc || "");
    getPossibleStates(data?.state);
    getAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // console.log(data, "data");
  return (
    <>
      <section className="main-container sm:p-0 m-0">
        <main className="d-flex flex-column px-4 pt-2 sm:p-0 sm:w-[100%] ">
          <Formik
            initialValues={{
              ...initialState,
              data,
              caller: data?.caller ? data?.caller : {},
              raised_by: data?.raised_by ? data?.raised_by : {},
              category: data?.category ? data?.category : {},
              sub_category: data?.sub_category ? data?.sub_category : {},
              assigned_grp: data?.assigned_grp ? data?.assigned_grp : {},
              assigned_to: data?.assigned_to ? data?.assigned_to : {},
              contact_type: data?.contact_type ? data?.contact_type : "",
              impact: data?.impact ? data?.impact : {},
              urgency: data?.urgency ? data?.urgency : {},
              state: data?.state ? data?.state : {},
              service: data?.service ? data?.service : "",
            }}
            enableReinitialize={true}
            validationSchema={incidentValidations}
            onSubmit={async (values, { resetForm }) => {
              const old_attachment = attachments?.map((item) =>
                parseInt(item?.id)
              );
              const formData = new FormData(this);
              formData.append(
                "raised_by",
                values?.raised_by?.USER_ID
                  ? values?.raised_by?.USER_ID
                  : values?.raised_by?.id
              );
              formData.append(
                "caller",
                values?.caller?.USER_ID || values?.caller?.id
              );
              formData.append("category", values?.category?.id || "");
              formData.append("sub_category", values?.sub_category?.id || "");
              formData.append("service", values?.service);
              formData.append("assigned_grp", values?.assigned_grp?.id);
              formData.append("assigned_to", values?.assigned_to?.id || "");
              Object.entries(values?.attachment).forEach(([key, value]) => {
                formData.append("attachments", values?.attachment[key]);
              });
              formData.append("old_attachments", old_attachment);
              formData.append("priority", values?.priority);
              formData.append("contact_type", values?.contact_type);
              formData.append(
                "state",
                values?.state?.name ? values?.state?.name : values?.state
              );
              formData.append(
                "impact",
                values?.impact?.name ? values?.impact?.name : values?.impact
              );
              formData.append(
                "urgency",
                values?.urgency?.name ? values?.urgency?.name : values?.urgency
              );
              formData.append("contact_phone", values?.contact_phone);
              formData.append("contact_email", values?.contact_email);
              formData.append("short_desc", description);
              formData.append("comment", values?.update_log);
              // for (var pair of formData.entries()) {
              //   console.log(pair[0] + ", " + pair[1]);
              // }
              if (description.length !== 0) {
                const config = {
                  headers: {
                    "Content-Type":
                      "multipart/form-data; boundary=<calculated when request is sent>",
                  },
                };
                const res = await serverAPI.put(
                  `${url}/incidents/update/${data?.inc_num}`,
                  formData,
                  config
                );
                if (res?.status === 202) {
                  // console.log("in if block");
                  resetForm();
                  setDescription("");
                  getPossibleStates(
                    values?.state?.name ? values?.state?.name : values?.state
                  );
                  toast("Incident Updated successfully");
                  // <Redirect to={`/update-incident/${res?.data?.inc_num}`} />;
                  //history.push(`/all-incidents`);
                  //incidents-raised-by-me
                  history.push(`/incidents-raised-by-me`);
                } else {
                  toast("Something went wrong", "error");
                }
              } else {
                toast("Description is required", "error");
              }
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <Form className=''>
              <PageHeader header= {"Update Incidents"} className="mb-4"/>

                <Grid
                  container
                  columnSpacing={isMobile?1:6}
                  className="sm:flex-col sm:p tab:grid-cols-2 pr-6 sm:w-[100%] "
                >
                  <Grid item xs={isMobile?10:6} className="mt-4 justify-between sm:max-w-full tab:max-w-full ">
                    <AutoComplete
                      getOptionLabel={(opt) =>
                        opt?.full_name || opt?.profile?.full_name
                      }
                      label="Raised By"
                      name="raised_by"
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={isMobile?10:6} className="mt-4 sm:max-w-full">
                    <Grid container columnSpacing={0}>
                      <Grid item xs={12}>
                        {userInfo?.user?.user_role !== "User" ? (
                          <>
                            <AutoComplete
                              url="/accounts/usernamewithid"
                              getOptionLabel={(opt) =>
                                opt?.full_name || opt?.profile?.full_name
                              }
                              label="Raised For"
                              name="caller"
                              required
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <Input
                              label="Raised For"
                              name="caller"
                              value={values?.caller?.profile?.full_name}
                              disabled
                            />
                          </>
                        )}
                      </Grid>
                      {/* <Grid item xs={1} className="mt-4 sm:max-w-full">
                        <div
                          className="ms-2 d-inline border-2 rounded"
                          onMouseEnter={openUserInfoBox}
                          onMouseLeave={closeUserInfoBox}
                          style={{ position: "relative" }}
                        >
                          <UserAvatar
                            size={30}
                            style={{
                              color: "gray",
                              background: "white",
                            }}
                          />
                          <div
                            className="px-4 bg-white shadow user-info-box rounded my-4 pb-3"
                            style={{ display: "none" }}
                          >
                            <Grid container >
                              <Grid xs={12} item className="mt-4 sm:max-w-full">
                                <Input
                                  label="Name"
                                  value={tooltipInfo?.username || ""}
                                  disabled
                                />
                              </Grid>
                              <Grid xs={12} item className="mt-4 sm:max-w-full">
                                <Input
                                  label="Email"
                                  value={tooltipInfo?.email || ""}
                                  disabled
                                />
                              </Grid>
                              <Grid xs={12} item className="mt-4 sm:max-w-full">
                                <Input
                                  label="Phone no"
                                  value={tooltipInfo?.phoneno || ""}
                                  disabled
                                />
                              </Grid>
                              <Grid xs={12} item className="mt-4 sm:max-w-full">
                                <Input
                                  label="Role"
                                  value={tooltipInfo?.userRole || ""}
                                  disabled
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className="mt-4  sm:max-w-full">
                    <Input
                      label="Your Department"
                      name="assigned_grp"
                      value={values?.assigned_grp?.name || ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4 sm:max-w-full">
                    <AutoComplete
                      url={"/portal/allcats"}
                      getOptionLabel={(opt) => {
                        return opt?.name || "";
                      }}
                      label="Category"
                      name="category"
                      disabled={
                        !data?.caller ||
                        !data?.raised_by ||
                        data?.state === "Closed" ||
                        data?.state === "Cancelled"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4 sm:max-w-full">
                    <AutoComplete
                      options={values?.category?.sub_categories}
                      getOptionLabel={(opt) => opt?.name || ""}
                      label="Sub Category"
                      name="sub_category"
                      disabled={
                        !data?.caller ||
                        !data?.raised_by ||
                        data?.state === "Closed" ||
                        data?.state === "Cancelled"
                          ? true
                          : false
                      }
                    />
                  </Grid>{" "}
                  {((data?.assigned_to &&
                    data?.assigned_to.id === userInfo?.user?.USER_ID) ||
                    userInfo?.user?.user_role !== "User") && (
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        name="assigned_to"
                        label="Assign To"
                        options={values?.sub_category?.users}
                        getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                        disabled={
                          !data?.caller ||
                          !data?.raised_by ||
                          data?.state === "Closed" ||
                          data?.state === "Cancelled"
                            ? true
                            : false
                        }
                        onChange={(_, value) => {
                          if (
                            (data?.sub_category &&
                              values?.id === data?.sub_category.head.id) ||
                            (value === "" && values?.state === "New")
                          ) {
                            setFieldValue("state", "In Progress");
                            setFieldValue("assigned_to", value);
                          } else {
                            setFieldValue("assigned_to", value);
                          }
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} className="mt-4 sm:max-w-full">
                    <AutoComplete
                      options={statusOption}
                      getOptionLabel={(opt) => opt?.name || opt || ""}
                      label="Status"
                      name="state"
                      disabled={
                        (data?.caller || data?.raised_by) &&
                        (data?.state === "Closed" ||
                          (data?.assigned_to &&
                          data?.assigned_to.id === data?.caller
                            ? true
                            : userInfo?.user?.USER_ID !== data?.caller))
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4 sm:max-w-full">
                    <AutoComplete
                      options={contactModeOption}
                      getOptionLabel={(opt) => opt || ""}
                      label="Preferred Contact Mode"
                      name="contact_type"
                      value={values?.contact_type}
                      disabled
                    />
                  </Grid>
                  {values.contact_type === "Email" ? (
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input
                        name="contact_email"
                        label="Email"
                        value={
                          values.contact_type === "Email"
                            ? tooltipInfo?.email
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                  ) : values.contact_type === "Phone" ? (
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input
                        name="contact_phone"
                        label="Mobile Number"
                        value={
                          values.contact_type === "Phone"
                            ? tooltipInfo?.phoneno
                            : ""
                        }
                        disabled
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={isMobile?10:6} className="mt-4"></Grid>
                  )}
                  <Grid item xs={isMobile?10:6} className="mt-4 sm:max-w-full">
                    <AutoComplete
                      options={importance}
                      getOptionLabel={(opt) => opt?.name || opt || ""}
                      label="Impact"
                      name="impact"
                      disabled={
                        !data?.caller ||
                        !data?.raised_by ||
                        (data?.raised_by &&
                          userInfo?.user.USER_ID === data?.raised_by?.id) ||
                        data?.state === "Closed" ||
                        data?.state === "Cancelled"
                          ? true
                          : false
                      }
                      onChange={(_, value) => {
                        let pt = CalculatePriority(
                          value?.name,
                          values?.urgency?.name
                        );
                        setFieldValue("priority", pt);
                        // impact: $("#Impact").val(),
                        // urgency: $("#Urgency").val()
                        setFieldValue("urgency", values?.urgency);
                        setFieldValue("impact", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={isMobile?10:6} className="mt-4 sm:max-w-full">
                    <AutoComplete
                      options={importance}
                      getOptionLabel={(opt) => opt?.name || opt || ""}
                      label="Urgency"
                      name="urgency"
                      disabled={
                        !data?.caller ||
                        !data?.raised_by ||
                        (data?.raised_by &&
                          userInfo?.user?.USER_ID === data?.raised_by?.id) ||
                        data?.state === "Closed" ||
                        data?.state === "Cancelled"
                          ? true
                          : false
                      }
                      onChange={(_, value) => {
                        let pt = CalculatePriority(
                          values?.impact?.name,
                          value?.name
                        );
                        setFieldValue("priority", pt?.name);
                        // impact: $("#Impact").val(),
                        // urgency: $("#Urgency").val()
                        setFieldValue("impact", values?.impact);
                        setFieldValue("urgency", value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={isMobile?10:6} className="mt-4 sm:max-w-full">
                    <Input label="Priority" name="priority" disabled />
                  </Grid>
                  <Grid item xs={isMobile?10:6} className="mt-4 sm:max-w-full">
                    <Input
                      multiline
                      rows={5}
                      label="Affected Service"
                      name="service"
                    />
                  </Grid>
                  <Grid item xs={isMobile?10:6} className="mt-4 sm:w-[100%] ">
                    <DropZone
                      name="attachment"
                      label="Add Attachments"
                      fileType={{
                        "file/*": [".xlsx", ".xls", ".pdf", ".jpeg"],
                      }}
                      // setFile
                      multiple={true}
                      disabled={
                        !data?.caller ||
                        !data?.raised_by ||
                        values?.state?.name === "Closed" ||
                        values?.state?.name === "Cancelled"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-4 ">
                    {attachments.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        <Label text="Attachments" />
                        <div
                          style={
                            !data?.caller ||
                            !data?.raised_by ||
                            data?.state === "Closed" ||
                            data?.state === "Cancelled"
                              ? { width: "100%" }
                              : {
                                  width: "100%",
                                  background: "#e9ecef !important",
                                }
                          }
                          className="col-md-8 px-2 py-2 d-flex border justify-end rounded flex-wrap attachment-wrapper"
                        >
                          {attachments.map((item) => {
                            return (
                              <>
                                <div
                                  key={item.id}
                                  className="me-1 attachment-badge d-flex px-2 py-1 justify-content-between align-items-center col-md-3"
                                >
                                  <a
                                    className="text-truncate text-dark"
                                    style={{ width: "80%" }}
                                    href={`${mediaUrl}${item.file}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Download size={16} className="mr-1" />
                                    {item.file.slice(29)}
                                  </a>
                                  {!data?.caller ||
                                  !data?.raised_by ||
                                  data?.state === "Closed" ||
                                  data?.state === "Cancelled" ? (
                                    ""
                                  ) : (
                                    <AttachmentUpdateModel
                                      item={item}
                                      getAttachments={getAttachments}
                                    />
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} className="mt-4 sm:w-[11 0%] tab:w-full">
                    <Label text="Short Description" required />
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log("Editor is ready to use!", editor);
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "300px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                      onError={() => toast("Error in CKEditor", "error")}
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-4 sm:w-[90%]">
                    <Input
                      multiline
                      rows={2}
                      label="Update Log"
                      name="update_log"
                      required
                    />
                  </Grid>
                  <Grid xs={12} item className="mt-4 sm:w-[90%]">
                    <Box className="mt-5 mb-4 flex justify-content-end sm:flex sm:items-start">
                      <Button
                        // isLoading={isLoading}
                        variant="contained"
                        className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                        type="submit"
                        onClick={() => {
                          console.log({ errors }, { values });
                        }}
                      >
                        Update Incident
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </main>
        {data?.update_logs && data?.update_logs?.length !== 0 && (
          <main className="d-flex flex-column px-4 pt-2 pb-8 mt-5 mb-5 sm:w-[100%]">
            <Label
              text="Update Log History"
              colorClass={"dark-blue"}
              className="mt-4 mb-4 text-2xl font-extrabold underline"
            />
            {data?.update_logs?.map((item) => (
              <SecondaryAccordion
                status={`Updated By: ${item?.updated_by || ""}`}
                key={item?.id}
                header={` ${item?.created_on?.split("T")[0] || ""} At ${
                  item?.created_on?.split("T")[1]?.split(".")[0]
                }`}
                className="mt-1 sm:flex sm:flex-wrap sm:w-[100%]"
              >
                <Typography className="text-lg mb-3">
                  <span className="ml-2 font-bold">Update Log : </span>
                  {item?.text || ""}
                </Typography>
                {item?.logs?.length !== 0 && (
                  <>
                    <Grid
                      container
                      className="mt-2 ml-0 border-b-2 border-black"
                      columnSpacing={2}
                    >
                      <Grid xs={4} item>
                        Field
                      </Grid>
                      <Grid xs={4} item>
                        Previous Value
                      </Grid>
                      <Grid xs={4} item>
                        Updated Value
                      </Grid>
                    </Grid>
                    <Divider />
                    {item?.logs?.map((item) => (
                      <Grid container className="mt-2 ml-0" columnSpacing={2}>
                        <Grid xs={4} item>
                          {item?.changed_field_name
                            ? item?.changed_field_name
                            : ""}
                        </Grid>
                        <Grid xs={4} item>
                          {item?.old_value ? item?.old_value : ""}
                        </Grid>
                        <Grid xs={4} item>
                          {item?.new_value ? item?.new_value : ""}
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </SecondaryAccordion>
            ))}
          </main>
        )}
      </section>
    </>
  );
};

export default IncUpdateForm;
