const useSLAConfiguration = () => {
  const initialValues = {
    p1_day: 0,
    p1_time: "",
    p2_day: 0,
    p2_time: "",
    p3_day: 0,
    p3_time: "",
  };
  return { initialValues };
};

export default useSLAConfiguration;
