import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
import React from "react";

export const Checkbox = ({
  label,
  checkBoxProps,
  className,
  sx,
  ...others
}) => {
  return (
    <FormControlLabel
      sx={{
        ...sx,
        "& .MuiTypography-root": {
          fontSize: "inherit",
          color: "#78909C",
          padding: "9px",
        },
      }}
      label={label ? label : undefined}
      className={`${className}`}
      {...others}
      control={
        <MuiCheckbox
          sx={{
            color: "#c4c4c4",
          }}
          {...checkBoxProps}
        />
      }
    />
  );
};
