import React, {useEffect } from 'react';
import Navbar from '../../components/layouts/Navbar';
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { Link } from "react-router-dom";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { AllIncidentDialog } from "../../components/incidents/AllIncidentsDialog";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import { columns } from "./IncidentColumns";

const OpenUnAssigned = () => {

  useEffect(() => {
    document.title = "All Incidents UnAssigned";
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Incident Management',
        page_location: 'AllIncidentsUnAssigned',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
  }, []);



  const { data: incidents } = useGetAll({
    key: `/incidents/v2/unassigned`,
    params: {
      page: 0,
      limit: 25,
    },
  });

  return (
    <>
      <Navbar heading="INCIDENTS UN-ASSIGNED " subheading="" />
      <section className="main-container">
        <DataGrid
          columns={columns}
          url="/incidents/v2/unassigned"
          rows={incidents}
          getRowId={(row) => row?.inc_num}
          addButton={
            <div className="flex-row-reverse">
              <Link to="/create-incident">
                <Button className="ml-4">New Incident</Button>
              </Link>
            </div>
          }
        />
      </section>
    </>
  );

};

export default React.memo(OpenUnAssigned);
