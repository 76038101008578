import React, { Fragment, useContext, useEffect } from 'react';
import Navbar from './Navbar';
import UserAuthContext from '../../context/userAuthContext/userAuthContext';
import waitingImg from '../../public/img/fogg-waiting-1.png';

const UserAlert = (props) => {
  const userAuthContext = useContext(UserAuthContext);
  const { isAuthenticated, isActivated, loadUser, hasProfile } =
    userAuthContext;
  const redirect = props.location.search
    ? props.location.search.split('=')[1]
    : '/';
  const redirectToHasProfile = props.location.search
    ? `user-profile${props.location.search}`
    : '/user-profile';
  const redirectToNotActivated = props.location.search
    ? `/not-acivated${props.location.search}`
    : '/not-acivated';

  useEffect(() => {
    loadUser();
    if (isAuthenticated) {
      if (hasProfile === false) {
        props.history.push(redirectToHasProfile);
      } else if (!isActivated) {
        props.history.push(redirectToNotActivated);
      } else {
        props.history.push(redirect);
      }
    }
    document.title = "Not Activated User | ITSM"
    // eslint-disable-next-line
  }, [props.history, isAuthenticated, isActivated, hasProfile]);
  return (
    <Fragment>
      <Navbar heading='WAIT TILL ADMIN APPROVES' />
      <section
        className='my-profile-container col-md-10 mx-auto text-center'
        style={{ height: '100% !important', marginTop: '10rem' }}>
        <img
          src={waitingImg}
          style={{ width: '500px', height: 'auto' }}
          alt='you are not activated user'
          className='d-block mx-auto'
        />
        <h3 className='h3 fs-4 dark-blue fw-bold text-center'>
          Your account has not been activated yet <br />
          please contact the admin to know more!
        </h3>
      </section>
    </Fragment>
  );
};

export default React.memo(UserAlert);
