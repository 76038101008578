// import { Edit } from "@carbon/icons-react";
import { Link } from "react-router-dom";
import { AllIncidentDialog } from "../../components/incidents/AllIncidentsDialog";
// import { Button } from "../../components/layouts/commonLayouts/buttons";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import removeTags from "../../utils/removeTags";
// import { Link } from "react-router-dom";

export const columns = [
  {
    headerName: "Number",
    field: "inc_num",
    minWidth: 10,
    flex: 0.5,
    // cellClassName: "text-dark",
    renderCell: ({ row }) => {
      return (
        <Link to={`/update-incident/${row?.inc_num}`}>
          <div className=" text-black underline">{row?.inc_num}</div>
        </Link>
      );
    },
  },
  // {
  //   headerName: "Opened",
  //   field: "created_on",
  //   minWidth: 200,
  // },
  {
    headerName: "Short Description",
    field: "short_desc",
    minWidth: 300,
    flex: 1.5,
    renderCell: ({ row }) => {
      return removeTags(row?.short_desc)
      // return row?.short_desc
      //   .replace(/(<([^>]+)>)/gi, "")
      //   .replace(/&(nbsp|amp|quot|lt|gt);/g, "");
    },
  },
  {
    headerName: "Category",
    field: "category",
    // minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => {
      if (row?.category?.name) {
        return <div>{row?.category?.name}</div>;
      } else {
        return <div>-</div>;
      }
    },
  },
  // },
  {
    headerName: "Raised By",
    field: "raised_by",
    // minWidth: 180,
    flex: 1,
    renderCell: ({ row }) => {
      if (row?.raised_by?.profile?.full_name) {
        return <div>{row?.raised_by?.profile?.full_name}</div>;
      } else {
        return <div>-</div>;
      }
    },
  },
  // {
  //   headerName: "Raised For",
  //   field: "caller",
  //   minWidth: 200,
  //   renderCell: ({ row }) => {
  //     if (row?.caller?.profile?.full_name) {
  //       return <div>{row?.caller?.profile?.full_name}</div>;
  //     } else {
  //       return <div>-</div>;
  //     }
  //   },
  // },
  {
    headerName: "Priority",
    field: "priority",
    minWidth: 100,
    flex: 0.5,
  },
  {
    headerName: "Status",
    field: "state",
    // minWidth: 150,
    flex: 1,
    // headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <StatusCard
        text={row?.state}
        variant={row?.state?.toLowerCase()?.replace(/ /g, "_")}
      />
    ),
  },
  // {
  //   headerName: "Closed On",
  //   field: "resolved_on",
  //   minWidth: 180,
  //   renderCell: ({ row }) => {
  //     if (row?.resolved_on) {
  //       return <div>{row?.resolved_on}</div>;
  //     } else {
  //       return <div>-</div>;
  //     }
  //   },
  // },

  // {
  //   headerName: "Sub-Category",
  //   field: "sub_category",
  //   minWidth: 160,
  //   renderCell: ({ row }) => {
  //     if (row?.sub_category?.name) {
  //       return <div>{row?.sub_category?.name}</div>;
  //     } else {
  //       return <div>-</div>;
  //     }
  //   },
  // },
  // {
  //   headerName: "Department",
  //   field: "assigned_grp",
  //   minWidth: 160,
  //   renderCell: ({ row }) => {
  //     if (row?.assigned_grp?.name) {
  //       return <div>{row?.assigned_grp?.name}</div>;
  //     } else {
  //       return <div>-</div>;
  //     }
  //   },
  // },
  // {
  //   headerName: "Assigned To",
  //   field: "assigned_to",
  //   minWidth: 160,
  //   renderCell: ({ row }) => {
  //     if (row?.assigned_to?.profile?.full_name) {
  //       return <div>{row?.assigned_to?.profile?.full_name}</div>;
  //     } else {
  //       return <div>-</div>;
  //     }
  //   },
  // },

  {
    headerName: "Actions",
    field: "actions",
    flex: 1,
    // minWidth: 135,
    renderCell: ({ row }) => {
      return (
        // <Link to={`/update-incident/${row?.inc_num}`}>
        //   <Button
        //     startIcon={<Edit />}
        //     className="capitalize xl:text-sm 2xl:text-semi-base"
        //     variant="text"
        //   >
        //     View/Edit
        //   </Button>
        // </Link>
        <AllIncidentDialog data={row} />
      );
    },
  },
];
