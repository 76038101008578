import { Grid, InputAdornment } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import InputLabel from "../../../components/layouts/commonLayouts/InputLabel";
import InputField from "../../../components/layouts/forms/InputField";
import Navbar from "../../../components/layouts/Navbar";
import useCreateOrUpdate from "../../../config/useCreateOrUpdate";
import userAuthContext from "../../../context/userAuthContext/userAuthContext";
import useSLAConfiguration from "./useSLAConfiguration";

const SLAConfiguration = () => {
  const { successAlert } = useContext(userAuthContext);
  const { initialValues } = useSLAConfiguration();
  const { mutate } = useCreateOrUpdate({
    url: `/sla-configuration/`,
    onSuccess: () => {
      successAlert(`Configuration saved successfully`);
    },
  });

  useEffect(() => {
    document.title = "SLA Configuration";
  }, []);

  return (
    <>
      <Navbar heading={"SLA CONFIGURATION"} />
      <section
        className="main-container d-flex justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <Grid container xs={8} direction={"column"} className="border p-5">
          <h1
            className="fs-4 dark-blue"
            style={{ borderBottom: "2px solid var(--ninth)" }}
          >
            SLA Configuration
          </h1>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              mutate(values, { onSuccess: () => resetForm() });
            }}
          >
            {({ values }) => (
              <Form>
                {console.log(values)}
                <Grid container className="mb-3 mt-5">
                  <Grid item xs={4}>
                    <InputLabel required style={{ fontWeight: "500" }}>
                      P1 - Critical
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8} className="d-flex justify-content-between">
                    <InputField
                      sx={{ width: "30%" }}
                      type="number"
                      min={0}
                      max={366}
                      name="p1_day"
                      startAdornment={
                        <InputAdornment position="start">Days</InputAdornment>
                      }
                    />
                    <InputField
                      sx={{ width: "65%" }}
                      type="time"
                      name="p1_time"
                      startAdornment={
                        <InputAdornment position="start">Time</InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item xs={4}>
                    <InputLabel required style={{ fontWeight: "500" }}>
                      P2 - High
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8} className="d-flex justify-content-between">
                    <InputField
                      sx={{ width: "30%" }}
                      type="number"
                      min={0}
                      max={366}
                      name="p2_day"
                      startAdornment={
                        <InputAdornment position="start">Days</InputAdornment>
                      }
                    />
                    <InputField
                      sx={{ width: "65%" }}
                      type="time"
                      name="p2_time"
                      startAdornment={
                        <InputAdornment position="start">Time</InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className="mb-3">
                  <Grid item xs={4}>
                    <InputLabel required style={{ fontWeight: "500" }}>
                      P3 - Normal
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8} className="d-flex justify-content-between">
                    <InputField
                      sx={{ width: "30%" }}
                      type="number"
                      min={0}
                      max={366}
                      name="p3_day"
                      startAdornment={
                        <InputAdornment position="start">Days</InputAdornment>
                      }
                    />
                    <InputField
                      sx={{ width: "65%" }}
                      type="time"
                      name="p3_time"
                      startAdornment={
                        <InputAdornment position="start">Time</InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item display={"flex"} justifyContent={"flex-end"}>
                  <button
                    className="btn white bg-blue fw-bold ms-auto"
                    type="submit"
                    onClick={() => {}}
                    style={{
                      boxShadow: "0 4px 10px #109bf140",
                    }}
                  >
                    Save
                  </button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </section>
    </>
  );
};

export default SLAConfiguration;
