import React, { useEffect, useState } from "react";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { Link } from "react-router-dom";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { columns } from "./ProblemColumns";
import { useMediaQuery } from "@mui/material";
import { PageHeader } from "../../components/layouts/pageHeader";
import MyCard from "../Problem/component/ProblemCard";


const PrbAssignedToMe = () => {
  // const isMobile = useMediaQuery('(max-width:600px)');  
  const isMobile = useMediaQuery('(max-width:1024px)');  //same for tab and phone
  useEffect(() => {
    document.title = "All Problems Assigned To Me";
    window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page_data: {
          country: 'India',
          language: 'en',
          page_url: window.location.href,
          page_category: 'Problem Management',
          page_location: 'Problem Assigned To Me',
          page_referrer: document.referrer,
          page_title: document.title,
          region: 'Pune',
          site_section: 'Support',
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: 'User',
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        }
      });
      console.log(window.dataLayer)

  }, []);
  const id = JSON.parse(localStorage.getItem("data")).USER_ID
  console.log('idididididididid',id)
  const { data: problems } = useGetAll({
    key: `/problems/v2/assignedtome/`,
    params: {
      page: 0,
      limit: 25,
    },
  });

  return (
    <>
      <PageHeader header={"PROBLEMS ASSIGNED TO ME"} className="mb-4" />
      <section className="main-container">
      {
        isMobile?(<div className="flex-col h-60">
          <Link to="/create-problem">
            <Button className="ml-4 mt-8">New Problem</Button>
          </Link>
        </div>
      ):(<></>)
      }
      {
      isMobile?(problems?.rows?.map((row, index) => <MyCard key={index} row={row}/>)
    ):(
      <DataGrid
          columns={columns}
          url={`/problems/v2/assignedtome/`}
          rows={problems}
          getRowId={(row) => row?.prb_num}
          addButton={
            <div className="flex-row-reverse">
              <Link to="/create-problem">
                <Button className="ml-4">New Problem</Button>
              </Link>
            </div>
          }
        />
    )
      }
        
      </section>
    </>
  );
};

export default React.memo(PrbAssignedToMe);
