import React, { Fragment, useEffect, useContext } from "react";
import Navbar from "../../components/layouts/Navbar";
import VisibilitySensor from "react-visibility-sensor";
import "react-circular-progressbar/dist/styles.css";
import IncidentContext from "../../context/incidentContext/incidentContext";
import Spinner from "../../components/layouts/Spinner";
import userAuthContext from "../../context/userAuthContext/userAuthContext";

import DashboardCard from "../../components/dashboardComponents/DashboardCard";
import DashboardBarChart from "../../components/dashboardComponents/DashboardBarChart";
import DashboardLineChart from "../../components/dashboardComponents/DashboardLineChart";
import SemiDonutChart from "../../components/dashboardComponents/SemiDonutChart";
import DashboardPieChart from "../../components/dashboardComponents/DashboardPieChart";
import DateTimeArea from "../../components/dashboardComponents/DateTimeArea";

const DahboardPage = ({ history }) => {
  const incidentContext = useContext(IncidentContext);
  const {
    linechartData,
    pichartDataNew,
    tableData,
    getIncOverviewData,
    loading,
    getTableData,
    getMtoMData,
    incidentTrendData,
    getIncidentTrendData,
  } = incidentContext;

  const {
    cancelled,
    in_progress,
    on_hold,
    all,
    closed,
    resolved,
    resolved_inc_weekly,
    new_inc_weekly,
    inprogress_inc_weekly,
    onhold_inc_weekly,
    cancelled_inc_weekly,
    closed_inc_weekly,
  } = pichartDataNew;

  const UserAuthContext = useContext(userAuthContext);
  const { clearError } = UserAuthContext;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/incidents-raised-by-me");
    } else {
      getIncOverviewData();
      getTableData("1");
      getMtoMData("1");
      getIncidentTrendData();
      document.title = "Overview Of All Incidents";
    }
    clearError();
    // eslint-disable-next-line
  }, []);

  const LineChartData = {
    categories: ["WEEK 1", "WEEK 2", "WEEK 3", "WEEK 4", "WEEK 5"],
    data: [
      {
        name: linechartData.lable2,
        data: linechartData.data2,
      },
      {
        name: linechartData.lable1,
        data: linechartData.data1,
      },
    ],
  };

  let BarGraphData =
    tableData &&
    Object.entries(tableData)?.map(([key, value]) => ({
      "name": key,
      "Open": value?.new,
      "In-Progress": value?.in_progress,
      "Closed": value?.closed,
      "Cancelled": value?.cancelled,
      "Resolved": value?.resolved,
    }));

  let colors = ["#14C9C9", "#F7BA1E", "#5AC2F7", "#F7685B"];
  let SemiDonutData =
    tableData &&
    Object.entries(tableData)?.map(([key, value], index) => ({
      name: key,
      value: value?.all,
    }));

  let IncidentTrendGraphData =
    incidentTrendData &&
    Object.entries(incidentTrendData).map(([_, value]) => ({
      name: "Responses",
      data: value,
    }));

  return (
    <Fragment>
      <Navbar
        heading='OVERVIEW OF INCIDENTS'
        subheading='ANALYTIC INFORMATION OF INCIDENTS'
      />
      <section
        // className='main-container'
        style={{ backgroundColor: "#F5F6F8" }}
      >
        {loading && <Spinner />}
        {!loading && (
          <>
            {/* Row 1 */}
            <div
              className='d-flex py-3 justify-content-between'
              style={{ height: "400px" }}
            >
              <div
                className='d-flex flex-column justify-content-between'
                style={{ width: "49%" }}
              >
                <div
                  className='d-flex justify-content-between'
                  style={{ columnGap: "15px" }}
                >
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <DashboardCard
                        title={"Open Incidents"}
                        count={isVisible ? pichartDataNew?.new : 0}
                        url={"/incident-proggress-bar/incidents?state=New"}
                        info={new_inc_weekly}
                      />
                    )}
                  </VisibilitySensor>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <DashboardCard
                        title={"In-Progress Incidents"}
                        count={isVisible ? in_progress : 0}
                        url={
                          "/incident-proggress-bar/incidents?state=In-Progress"
                        }
                        info={inprogress_inc_weekly}
                      />
                    )}
                  </VisibilitySensor>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <DashboardCard
                        title={"Resolved Incidents"}
                        count={isVisible ? resolved : 0}
                        url={"/incident-proggress-bar/incidents?state=Resolved"}
                        info={resolved_inc_weekly}
                      />
                    )}
                  </VisibilitySensor>
                </div>
                <div
                  className='d-flex justify-content-between'
                  style={{ columnGap: "15px" }}
                >
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <DashboardCard
                        title={"On-Hold Incidents"}
                        count={isVisible ? on_hold : 0}
                        url={"/incident-proggress-bar/incidents?state=On-Hold"}
                        info={onhold_inc_weekly}
                      />
                    )}
                  </VisibilitySensor>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <DashboardCard
                        title={"Closed Incidents"}
                        count={isVisible ? closed : 0}
                        url={"/incident-proggress-bar/incidents?state=Closed"}
                        info={closed_inc_weekly}
                      />
                    )}
                  </VisibilitySensor>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <DashboardCard
                        title={"Cancelled Incidents"}
                        count={isVisible ? cancelled : 0}
                        url={
                          "/incident-proggress-bar/incidents?state=Cancelled"
                        }
                        info={cancelled_inc_weekly}
                        is_cancelled
                      />
                    )}
                  </VisibilitySensor>
                </div>
              </div>
              <div
                style={{ width: "49%", fontSize: "12px" }}
                className='d-flex flex-column justify-content-between card-shadow bg-white'
              >
                <DashboardBarChart
                  data={BarGraphData}
                  title={"Incident Breakup by Status"}
                />
              </div>
            </div>

            {/* Row 2 */}
            <div
              className='d-flex py-3 justify-content-between'
              style={{ height: "400px" }}
            >
              <div
                className='d-flex flex-column justify-content-between card-shadow bg-white'
                style={{ width: "49%" }}
              >
                {/* <DashboardAreaChart
                  title={"Incident Trend"}
                  data={[
                    {
                      name: "Responses",
                      data:
                        incidentTrendData &&
                        Object.values(incidentTrendData)[0],
                    },
                  ]}
                  colors={["#109CF1"]}
                  categories={[
                    "Week 1",
                    "Week 2",
                    "Week 3",
                    "Week 4",
                    "Week 5",
                  ]}
                /> */}
                <DateTimeArea
                  data={IncidentTrendGraphData}
                  title={"Incident Trend"}
                />
              </div>
              <div
                style={{ width: "49%" }}
                className='d-flex flex-column justify-content-between card-shadow bg-white'
              >
                <DashboardLineChart
                  {...LineChartData}
                  title={"Incident Month-on-Month Comparison"}
                  colors={["#109CF1", "#F7BA1E"]}
                />
                {/* <DashboardAreaChart
                  title={"Avg Incident Closure time"}F7685B
                  data={[
                    {
                      name: "Responses",
                      data: [31, 40, 28, 51, 42],
                    },
                  ]}
                  colors={["#6CBE45"]}
                  categories={[
                    "Week 1",
                    "Week 2",
                    "Week 3",
                    "Week 4",
                    "Week 5",
                  ]}
                /> */}
              </div>
            </div>

            {/* Row 3 */}
            <div
              className='d-flex py-3 justify-content-between'
              style={{ height: "400px" }}
            >
              <div
                className='d-flex flex-column justify-content-between card-shadow bg-white'
                style={{ width: "49%" }}
              >
                <SemiDonutChart
                  data={SemiDonutData}
                  cellColors={colors}
                  dataKey='value'
                  width={400}
                  title='Incident Breakup'
                />
              </div>
              <div
                className='d-flex flex-column justify-content-between card-shadow bg-white'
                style={{ width: "49%" }}
              >
                <DashboardPieChart
                  data={[
                    {
                      name: "Open",
                      value: parseInt((pichartDataNew?.new / all) * 100),
                      color: "#00B4FF",
                    },
                    {
                      name: "In-Progress",
                      value: parseInt((in_progress / all) * 100),
                      color: "#F7941D",
                    },
                    {
                      name: "On Hold",
                      value: parseInt((on_hold / all) * 100),
                      color: "#FFD48A",
                    },
                    {
                      name: "Cancelled",
                      value: parseInt((cancelled / all) * 100),
                      color: "#F7685B",
                    },
                    {
                      name: "Closed",
                      value: parseInt((closed / all) * 100),
                      color: "#6CBE45",
                    },
                  ]}
                  cellColors={[
                    "#00B4FF",
                    "#F7941D",
                    "#FFD48A",
                    "#F7685B",
                    "#6CBE45",
                  ]}
                  dataKey='value'
                  title={"Incident Breakup by Status"}
                />
              </div>
            </div>
          </>
        )}
      </section>
    </Fragment>
  );
};

export default React.memo(DahboardPage);
