export const datahandleClick=(category,page_location,link_text,link_url)=>{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event_data: null }); 
    window.dataLayer.push({
     event: 'button_click',
     event_data: {
     page_category:category,
     page_location:page_location,
     page_title:document.title,
     link_text:link_text,
     link_url:link_url,
     },
   
     user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
  console.log('window',window.dataLayer)

}

