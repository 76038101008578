import { Chip as MuiChip } from "@mui/material";
import { colors } from "../../../../constants/theme";

const chipStyles = {
  background: "white",
  color: colors.text.dark,
  border: `2px solid ${colors.primary.light}`,
};

export const Chip = (props) => {
  return <MuiChip {...props} sx={chipStyles} />;
};
