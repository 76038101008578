// import { Edit } from "@carbon/icons-react";
// import { Button } from "../../components/layouts/commonLayouts/buttons";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import UserDialouge from "../../components/users/UserDialouge";
// import { Link } from "react-router-dom";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { Edit } from "@carbon/icons-react";

export const columns = [
  {
    headerName: "Name",
    field: "full_name",
    flex: 1,
    cellClassName: "underline 	",
    renderCell: ({ row }) => {
      return (
        <UserDialouge
          data={row}
          text={
            <Button
              className="capitalize xl:text-sm 2xl:text-semi-base text-ellipsis	overflow-hidden whitespace-nowrap	"
              variant="text"
              sx={{ color: "black" }}
            >
              <span className="text-ellipsis	overflow-hidden whitespace-nowrap">
                {row.full_name}
              </span>
            </Button>
          }
        />
      );
    },
  },
  {
    headerName: "Email",
    field: "user",
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <span className="text-ellipsis	overflow-hidden whitespace-nowrap">
          {row?.user?.email}
        </span>
      );
    },
  },
  {
    headerName: "Department",
    field: "department",
    flex: 1,
    renderCell: ({ row }) => {
      return row?.department?.name;
    },
  },
  {
    headerName: "Phone",
    field: "phone",
    flex: 1,
  },
  {
    headerName: "Role",
    field: "user_role",
    flex: 1,
  },
  {
    headerName: "Status",
    field: "is_active",
    flex: 1.2,
    // headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <StatusCard
        text={row?.is_active ? "Active" : "Not Active"}
        variant={row?.is_active ? "resolved" : "cancelled"}
      />
    ),
  },
  {
    headerName: "Actions",
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <UserDialouge
          data={row}
          text={
            <Button
              startIcon={<Edit />}
              className="capitalize xl:text-sm 2xl:text-semi-base"
              variant="text"
            >
              View/Edit
            </Button>
          }
        />
      );
    },
  },
];
