import React, { useState } from "react";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";

const EmailUpdate = ({ setAlert, clearError, loadUser }) => {
  const [email, setEmail] = useState("");
  const sendEmail = async (e) => {
    e.preventDefault();
    const res = await serverAPI.post(`${url}/accounts/change/email`, {
      email: email.toLocaleLowerCase(),
      id: JSON.parse(localStorage.getItem("data")).USER_ID,
    });
    if (res.status === 202) {
      setAlert({
        msg: "Email Updated Successfully",
        type: "success",
        icon: "check-circle",
      });
      loadUser();
      setEmail("");
    } else {
      setAlert({
        msg: Object.values(res.data)[0][0],
        type: "danger",
        icon: "exclamation-triangle",
      });
    }
    setTimeout(() => {
      clearError(null);
    }, 5000);
  };
  return (
    <form className="col-md-11 mx-auto justify-content-around">
      <h5 className="dark-blue">Email :-</h5>
      <div className="border-top pt-3">
        <div className="col-md-8 mx-auto">
          <div className="row my-2">
            <div className="col-md-5 p-0">
              <label
                style={{ fontWeight: "500" }}
                htmlFor="email"
                className="col-form-label fs-6 dark-blue me-5"
              >
                New Email <span className="d-inline text-danger"> *</span>
              </label>
            </div>
            <div className="col-md-7 p-0">
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                id="email"
                autoComplete="on"
                placeholder="New Email"
              />
            </div>
          </div>
          <div className="row my-2 mb-5">
            <div className="col-md-5 p-0"></div>
            <div className="col-md-7 p-0">
              <button
                className="btn white bg-blue fw-bold"
                onClick={sendEmail}
                style={{
                  boxShadow: "0 4px 10px #109bf140",
                  marginRight: "2rem",
                }}
              >
                Update Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EmailUpdate;
