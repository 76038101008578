import React, { Fragment } from 'react';

const DangerAlerts = ({ data }) => {
  return (
    <Fragment>
      <div
        className={`alert px-3 py-2 mb-0 alert-${data.type} d-flex align-items-center`}
        role='alert'>
        <i className={`far fs-6 fa-${data.icon} me-2`}></i>
        <div>{data.msg}</div>
      </div>
    </Fragment>
  );
};

export default React.memo(DangerAlerts);
