import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { columns } from "./UsersColumns";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { useMediaQuery } from "@mui/material";
import Admincard from "./Admincard";
import BUlkCsvUpload from "./BulkCsvUpload";

const ApprovedUsers = ({ history, key }) => {
  const isMobile = useMediaQuery('(max-width:600px)');  
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = "All Approved Users";
    }
    //eslint-disable-next-line
  }, []);

  const { data } = useGetAll({
    key: "/accounts/v2/approvedprofiles",
    params: {
      page: 0,
      limit: 25,
    },
  });
console.log("User Data Approved:",data);

  return (
    <>
      <section className="main-container ">
      {isMobile ? ( 
        data?.rows?.map((row, index) => <Admincard key={index} row={row || []}/>)
        ) : (
        <DataGrid
          columns={columns}
          url="/accounts/v2/approvedprofiles"
          rows={data}
          getRowId={(row) => row?.id}
          addButton={<BUlkCsvUpload downloadAPI={"accounts/export/profiles"} uploadAPI={"/accounts/upload/profiles"} className={'mt-4'}/>}
      
        />)}
      </section>
    </>
  );
};

export default React.memo(ApprovedUsers);
