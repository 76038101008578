import React, { useReducer } from "react";
import hyperlinkReducer from "./hyperlinkReducer";
import HyperlinkContext from "./hyperlinkContext";
import { GET_LIST_DATA, SET_LOADING, CLEAR_LIST_DATA } from "../types";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";

const HyperlinkState = (props) => {
  const initialState = {
    loading: false,
    list: [],
  };

  const [state, dispatch] = useReducer(hyperlinkReducer, initialState);

  // get list Data
  const getListData = async (link, pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(url + link + pag_no);
    dispatch({ type: GET_LIST_DATA, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // clear list
  const clearList = () => {
    dispatch({ type: CLEAR_LIST_DATA });
  };

  return (
    <HyperlinkContext.Provider
      value={{
        list: state.list,
        getListData,
        clearList,
      }}
    >
      {props.children}
    </HyperlinkContext.Provider>
  );
};

export default HyperlinkState;
