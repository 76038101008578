import { Box } from "@mui/material";
import { colors } from "../../../../constants/theme";
import React from "react";

export const ActionCard = ({
  variant = "primary",
  className,
  children,
  ...boxProps
}) => {
  return (
    <Box
      className={`cursor-pointer p-3 rounded-lg flex items-center justify-center font-light text-xl ${
        className || ""
      }`}
      sx={{
        background: colors[variant].light,
        borderLeft: `2px solid ${colors[variant].dark}`,
        height: "115px",
        minWidth: "220px",
        width: "220px",
        color: colors.text.dark,
        "&:hover": {
          background: `${colors[variant].light}90`,
        },
        ...boxProps.sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
