import $ from "jquery";
import { url } from "../urls";
import serverAPI from "./serverAPI";

// variables
export const updated = [
  { id: 1, name: "Latest" },
  { id: 2, name: "Oldest" },
];

export const code = [
  { id: 1, name: "Solved (Work Around)" },
  { id: 2, name: "Solved (Permanently)" },
  { id: 3, name: "Solved Remotely (Work Around)" },
  { id: 4, name: "Solved Remotely(Permanent)" },
  { id: 5, name: "Not Solved (Not Reproducible)" },
  { id: 6, name: "Not Solved (Too Costly)" },
  { id: 7, name: "Closed Or Resolved By User" },
  { id: 8, name: "Closed - No Response From User" },
];

// function

export const ToggleSideNavBar = (e) => {
  $(".sub-items").hide();
  $(".side-navbar").toggleClass("sideNavWidth");
  $("nav").toggleClass("navWidth");
  $(".main-container").toggleClass("main-ani");
  $(".far.fa-chevron-down").toggle();
  $(".sidenav-item h5").toggle(500);
  $(".sidenav-item p").toggle(500);
  $(".myprofile").removeClass("px-2");
};

export const openSubSideBar = (e) => {
  if ($(".side-navbar").hasClass("sideNavWidth")) {
  } else if (
    e.target.classList.contains("h5") ||
    e.target.classList.contains("fs-5")
  ) {
    $(e.target).parent().next().toggle(500);
    if ($(e.target).hasClass("h5") || $(e.target).hasClass("fs-5")) {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).parent().toggleClass("active-list");
    } else {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).toggleClass("active-list");
    }
  } else {
    $(e.target).next().toggle(500);
    if ($(e.target).hasClass("h5") || $(e.target).hasClass("fs-5")) {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).parent().toggleClass("active-list");
    } else {
      $(".sidenav-item").each(function () {
        if ($(this).hasClass("active-list")) {
          $(this).removeClass("active-list");
        }
      });
      $(e.target).toggleClass("active-list");
    }
  }
};

export const getAccordingToFilter = (text, key, data) => {
  let matchInc = data.filter((inc) => {
    let toCheck = inc[key];
    const regx = new RegExp(`^${text}`, "gi");
    return toCheck ? toCheck.match(regx) : "";
  });
  return matchInc;
};

export const openUserInfoBox = () => {
  $(".user-info-box").fadeToggle(300);
};

export const closeUserInfoBox = () => {
  $(".user-info-box").fadeToggle(300);
};

export const backToAll = (history) => {
  history.goBack();
};

export const extractContent = (s) => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const getCsv = async (page, subUrl, name) => {
  const { data } = await serverAPI.get(`${url}/${subUrl}/csv/${page}`);
  const downloadurl = url + "/" + data;
  let a = document.createElement("a");
  a.href = downloadurl;
  a.setAttribute("download", `${name}.csv`);
  a.click();
};
