import React, { useState } from "react";
import { url } from "../../urls";
import serverAPI from "../../utils/serverAPI";

const PasswordUpdate = ({ setAlert, clearError }) => {
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    new_password2: "",
    id: JSON.parse(localStorage.getItem("data")).USER_ID,
  });

  const { old_password, new_password, new_password2 } = passwordData;

  const [showPassword, setshowPassword] = useState(false);

  const onChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const sendPasswordData = async (e) => {
    e.preventDefault();
    if (old_password === "" || new_password === "" || new_password2 === "") {
      setAlert({
        msg: "Please Fill All Fields!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    } else if (new_password !== new_password2) {
      setAlert({
        msg: "New passwords must match!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    } else {
      const res = await serverAPI.post(
        `${url}/accounts/change/password`,
        passwordData
      );
      if (res.status === 202) {
        setAlert({
          msg: "Password Updated Successfully",
          type: "success",
          icon: "check-circle",
        });
        setPasswordData({
          old_password: "",
          new_password: "",
          new_password2: "",
          id: JSON.parse(localStorage.getItem("data")).USER_ID,
        });
      } else {
        setAlert({
          msg: Object.values(res.data)[0][0],
          type: "danger",
          icon: "exclamation-triangle",
        });
      }
    }
    setTimeout(() => {
      clearError(null);
    }, 5000);
  };

  const toggleShowPassword = (e) => {
    if (!showPassword) {
      setshowPassword(!showPassword);
      e.target.classList.replace("fa-eye-slash", "fa-eye");
      e.target.parentElement.previousElementSibling.type = "text";
    } else {
      setshowPassword(!showPassword);
      e.target.classList.replace("fa-eye", "fa-eye-slash");
      e.target.parentElement.previousElementSibling.type = "password";
    }
  };

  return (
    <form className="col-md-11 mx-auto justify-content-around">
      <h5 className="dark-blue">Password :-</h5>
      <div className="border-top pt-3">
        <div className="col-md-8 mx-auto">
          <div className="row my-2">
            <div className="col-md-5 p-0">
              <label
                style={{ fontWeight: "500" }}
                htmlFor="old_password"
                className="col-form-label fs-6 dark-blue me-5"
              >
                Old Password <span className="d-inline text-danger"> *</span>
              </label>
            </div>
            <div className="col-md-7 p-0 position-relative">
              <input
                type="password"
                name="old_password"
                value={old_password}
                onChange={onChange}
                className="form-control"
                id="old_password"
                autoComplete="on"
                placeholder="Old Password"
              />
              <div className="change_pass_icon" onClick={toggleShowPassword}>
                <i className="far fa-eye-slash text-clr eye-open fs-6"></i>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-md-5 p-0">
              <label
                style={{ fontWeight: "500" }}
                htmlFor="new_password"
                className="col-form-label fs-6 dark-blue me-5"
              >
                New Password <span className="d-inline text-danger"> *</span>
              </label>
            </div>
            <div className="col-md-7 position-relative p-0">
              <input
                type="password"
                name="new_password"
                value={new_password}
                onChange={onChange}
                className="form-control"
                id="new_password"
                placeholder="New Password"
                autoComplete="on"
              />
              <div className="change_pass_icon" onClick={toggleShowPassword}>
                <i className="far fa-eye-slash text-clr eye-open fs-6"></i>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-md-5 p-0">
              <label
                style={{ fontWeight: "500" }}
                htmlFor="confirm_password"
                className="col-form-label fs-6 dark-blue me-5"
              >
                Confirm Password{" "}
                <span className="d-inline text-danger"> *</span>
              </label>
            </div>
            <div className="col-md-7 position-relative p-0">
              <input
                type="password"
                name="new_password2"
                value={new_password2}
                onChange={onChange}
                className="form-control"
                id="confirm_password"
                placeholder="Confirm Password"
                autoComplete="on"
              />
              <div className="change_pass_icon" onClick={toggleShowPassword}>
                <i className="far fa-eye-slash text-clr eye-open fs-6"></i>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5 p-0"></div>
            <div className="col-md-7 p-0">
              <button
                className="btn white bg-blue fw-bold"
                onClick={sendPasswordData}
                style={{
                  boxShadow: "0 4px 10px #109bf140",
                  marginRight: "2rem",
                }}
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PasswordUpdate;
