import React, { useContext } from "react";
import Navbar from "../../../components/layouts/Navbar";
import { useLocation, useParams } from "react-router-dom";
import TriggerForm from "../../../components/flowDesigner/TriggerForm";
import useCreateOrUpdate from "../../../config/useCreateOrUpdate";
import userAuthContext from "../../../context/userAuthContext/userAuthContext";
import ActionsForm from "../../../components/flowDesigner/ActionsForm";
import { Divider, Grid } from "@mui/material";
import useGetAll from "../../../config/useGetAll";
import AccountTree from "@mui/icons-material/AccountTree";

const NewFlow = () => {
  const { successAlert } = useContext(userAuthContext);
  const { state } = useLocation();
  const { id } = useParams();
  const [triggerData, setTriggerData] = React.useState({
    id: null,
    type: "",
    table: state?.module_name ? state?.module_name : "",
  });
  const [filters, setFilters] = React.useState([
    {
      id: Date.now(),
      field_name: "",
      field_value: "",
      comparison_operator: "",
      logical_operator: "",
    },
  ]);

  const [approvers, setApprovers] = React.useState([
    {
      id: Date.now(),
      user: "",
      level: 1,
    },
  ]);

  const [actionsData, setActionsData] = React.useState({
    action_type: "",
    table: state?.module_name ? state?.module_name : "",
    groupname: "",
    is_ad_related: false,
    is_approval_required: false,
  });

  const [actions, setActions] = React.useState([]);

  const { refetch: refetchTrigger } = useGetAll(
    `/workflows/get-trigger/${id}/`,
    {
      onSuccess: (data) => {
        setTriggerData({
          id: data?.data?.id,
          type: data?.data?.type,
          table: data?.data?.table,
        });
      },
    }
  );

  const { mutate: TriggerMutate } = useCreateOrUpdate({
    url: `/workflows/trigger/`,
    onSuccess: () => {
      refetchTrigger();
      successAlert("Trigger Created Successfully");
    },
  });
  const { mutate: ActionMutate } = useCreateOrUpdate({
    url: `/workflows/trigger/`,
    method: "put",
    onSuccess: () => {
      refetchTrigger();
      successAlert("Action Created Successfully");
    },
  });

  const fieldsMapping = {
    IsCaller: "caller",
    "Raised By": "raised_by",
    "Requested By": "requested_by",
    "Product/Service": "product",
    Price: "price",
    Status: "state",
    Approval: "approval",
    Quantity: "quantity",
    "Is Open": "is_open",
    "Short Description": "short_desc",
    Justification: "justification",
    "Comments 1": "comments1",
    "Comments 2": "comments2",
    Category: "category",
    "Sub Category": "sub_category",
  };

  const handleTriggerSubmit = () => {
    let MappedFilters = filters?.map((item) => {
      return {
        ...item,
        field_name: fieldsMapping[item?.field_name]
          ? fieldsMapping[item?.field_name]
          : item?.field_name,
      };
    });
    TriggerMutate({ ...triggerData, workflow: id, conditions: MappedFilters });
  };

  const handleActionSubmit = () => {
    let MappedActions = actions?.map((item) => {
      return {
        ...item,
        field: fieldsMapping[item?.field]
          ? fieldsMapping[item?.field]
          : item?.field,
      };
    });
    ActionMutate({
      ...actionsData,
      workflow: id,
      actions: MappedActions,
      trigger: triggerData?.id,
      approvers: approvers,
    });
  };

  return (
    <>
      <Navbar
        heading={`WORKFLOW CONFIGURATION / ${state?.flow_name?.toUpperCase()}`}
      />
      <section className="main-container">
        <Grid
          container
          className="flow-top-bar"
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid
            item
            xs={4}
            display="flex"
            direction="row"
            alignItems={"center"}
          >
            <AccountTree className="ms-3 me-2 text-success" fontSize="large" />
            <h5 className="m-0"> {state?.flow_name?.toUpperCase()} </h5>
          </Grid>
          {/* <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
            <Button variant={"contained"} className="me-2" disabled={true}>
              Activate
            </Button>
          </Grid> */}
        </Grid>
        <main className="my-4 py-3 px-3">
          <TriggerForm
            filters={filters}
            setFilters={setFilters}
            triggerData={triggerData}
            setTriggerData={setTriggerData}
            handleTriggerSubmit={handleTriggerSubmit}
            setActionsData={setActionsData}
            actionsData={actionsData}
          />
          <Divider className="mb-3" />
          <ActionsForm
            actions={actions}
            setActions={setActions}
            actionsData={actionsData}
            setActionsData={setActionsData}
            handleActionSubmit={handleActionSubmit}
            approvers={approvers}
            setApprovers={setApprovers}
          />
        </main>
      </section>
    </>
  );
};

export default NewFlow;
