import React from "react";

import { alpha, InputBase, styled } from "@mui/material";

const BootstrapInput = styled((props) => <InputBase {...props} />)(
  ({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "0px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiInputBase-input.MuiInputBase-inputMultiline": {
      padding: "10px 14px",
    },
  })
);

const InputFieldNF = ({ name, variant, ...otherProps }) => {
  const configTextfield = {
    ...otherProps,
    fullWidth: true,
    variant: variant ? variant : "outlined",
  };

  return <BootstrapInput {...configTextfield} />;
};

export default InputFieldNF;
