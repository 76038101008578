import { Grid, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  AutoComplete,
  Input,
  Label,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import { Form, Formik } from "formik";
import { Edit } from "@carbon/icons-react";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox";
import serverAPI from "../../utils/serverAPI";
import { toast } from "../../utils/toaster";
import {DateTimePicker} from "../../components/layouts/forms/inputs/dateTimePicker"; 
import { width } from "@mui/system";

export const AddTaskDialog = ({onAddTask}) => {

  const priorityOptions = [
    { id: 0, name: "Min" },
    { id: 1, name: "Normal" },
    { id: 2, name: "Max" },
  ];

  const taskValidations = yup.object({
    title: yup.string().required("Field is required"),
    description: yup.string().required("Field is required"),
    note: yup.string(),
    assigned_to: yup.object().nullable(),
    assigned_grp: yup.object(),
    due_date: yup.string().required("Please select valid Date"),
    priority: yup.object(),
    state: yup.string(),
  });

    const user_id = JSON.parse(localStorage.getItem("data")).USER_ID;
    console.log("User Id =====> ", user_id);

  return (
    <Dialog
      title="New Task"
      button={
        <Button
          className="capitalize xl:text-sm 2xl:text-semi-base"
          variant="contained"
        >
          Add New
        </Button>
      }
    >
      {({ onClose }) => (
        <Formik
          initialValues={{
            title: "",
            description: "",
            note: "",
            due_date: "",
            assigned_grp: "",
            assigned_to: "",
            priority: { id: 1, name: "Normal" },
            owner: '',
          }}
          validationSchema={taskValidations}
          // enableReinitialize={true}
          onSubmit={async (values, { resetForm }) => {
            const payload = {
              title: values?.title,
              description: values?.description,
              note: values?.note,
              due_date: values?.due_date,
              assigned_grp: values?.assigned_grp?.id,
              assigned_to: values?.assigned_grp?.manager?.id,
              priority: values?.priority?.name,
              owner: user_id,
            };
            console.log("Values => ", payload);
            const res = await serverAPI.post('/tasks/', payload);
            // console.log("Status =========> ",res.status);
            if(res?.status == 201) {
              toast("Task created successfully");
              resetForm();
              onClose();
              onAddTask();
            } else {
              toast("Something went wrong", "error")
            }
          }}
        >
          {({ values, errors, setFieldValue }) => (
            <>
              <Form>
                <Grid container columnSpacing={6}>
                  <Grid item xs={6} className="mt-4">
                    <Input
                      label="Title"
                      name="title"
                      required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <DateTimePicker label="Due Date" name="due_date"/>
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      url={"/portal/departments/0"}
                      name="assigned_grp"
                      label="Assign Group"
                      getOptionLabel={(opt) => opt.name || ""}
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      label="Priority"
                      name="priority"
                      options={priorityOptions}
                      getOptionLabel={(opt) => opt?.name || ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <AutoComplete
                      name="assigned_to"
                      label="Assign To"
                      options={[values?.assigned_grp?.manager?.profile]}
                      getOptionLabel={(opt) => opt?.full_name || ""}
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-4">
                    <Input
                      label="Note"
                      name="note"
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                    <Input 
                      label="Description"
                      name="description"
                      multiline
                      rows={3}
                      required
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className="mt-4 justify-between sm:max-w-full"
                  >
                    <Box className="mt-5 d-flex justify-content-end">
                      <Button
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        type="submit"
                      >
                        Create
                      </Button>
                      <Button
                        color="secondary"
                        className="px-4 ml-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        onClick={onClose}
                      >
                        Close
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
