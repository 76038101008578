import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import RequestSvg from "../../../public/img/request.svg";

const RequestManagement = ({ pathname, user }) => {
  return (
    <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item accordion-item__custom">
        <h2
          className="accordion-header accordion-header__custom"
          id="flush-headingOne"
        >
          <button
            className="accordion-button accordion-button__custom collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            <img src={RequestSvg} alt="" />
            <span>Request Management</span>
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          className="accordion-collapse accordion-collapse__custom collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          {user?.user_role !== "User" && (
            <h2 className="accordion-header">
              <Link
                className="accordion-button accordion-button__custom-item accordion-button__custom"
                to="/request-overview"
              >
                <li className="ms-4 fw-normal">
                  -{pathname === "/request-overview" && ">"} Request Overview
                </li>
              </Link>
            </h2>
          )}
          <div className="accordion accordion-flush" id="accordionFlushCreate">
            <div className="accordion-item accordion-item__custom">
              <h2
                className="accordion-header accordion-header__custom"
                id="flush-headingCreate"
              >
                <button
                  className="accordion-button accordion-button__custom collapsed"
                  style={{ paddingLeft: "35px" }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseCreate"
                  aria-expanded="false"
                  aria-controls="flush-collapseCreate"
                >
                  <span>- Create New Request</span>
                </button>
              </h2>
              <div
                id="flush-collapseCreate"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingCreate"
                data-bs-parent="#accordionFlushCreate"
              >
                <h2 className="accordion-header">
                  <Link
                    className="accordion-button accordion-button__custom-item accordion-button__custom"
                    to="/request-services"
                  >
                    <li className="fw-normal ms-5">
                      -{pathname === "/request-services" && ">"} Service Request
                    </li>
                  </Link>
                </h2>
                <h2 className="accordion-header">
                  <Link
                    className="accordion-button accordion-button__custom-item accordion-button__custom"
                    to="/request-products"
                  >
                    <li className="fw-normal ms-5">
                      -{pathname === "/request-products" && ">"} Product Request
                    </li>
                  </Link>
                </h2>
              </div>
            </div>
          </div>

          <h2 className="accordion-header">
            <Link
              className="accordion-button accordion-button__custom-item accordion-button__custom"
              to="/requests-raised-by-me"
            >
              <li className="ms-4 fw-normal">
                -{pathname === "/requests-raised-by-me" && ">"} Requests Raised
                By Me
              </li>
            </Link>
          </h2>
          {(user?.user_role === "Service Manager" ||
            user?.user_role === "Admin") && (
              <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/first-approval-pending-requests"
                >
                  <li className="ms-4 fw-normal">
                    -{pathname === "/first-approval-pending-requests" && ">"}{" "}
                    First Approval Pending
                  </li>
                </Link>
              </h2>
            )}
          {(user?.is_skip_manager || user?.user_role === "Admin") && (
            <h2 className="accordion-header">
              <Link
                className="accordion-button accordion-button__custom-item accordion-button__custom"
                to="/second-approval-pending-requests"
              >
                <li className="ms-4 fw-normal">
                  -{pathname === "/second-approval-pending-requests" && ">"}{" "}
                  Second Approval Pending
                </li>
              </Link>
            </h2>
          )}
          {(user?.in_assets_team || user?.user_role === "Admin") && (
            <Fragment>
              {/* <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/search-requests"
                >
                  <li className="ms-4 fw-normal">
                    -{pathname === "/search-requests" && ">"} Search Requests
                  </li>
                </Link>
              </h2> */}
              <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/requests-for-return-and-replace"
                >
                  <li className="ms-4 fw-normal text-truncate">
                    -{pathname === "/requests-for-return-and-replace" && ">"}{" "}
                    Requests For Return/Replace
                  </li>
                </Link>
              </h2>
              <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/all-requests"
                >
                  <li className="ms-4 fw-normal">
                    -{pathname === "/all-requests" && ">"} All Requests
                  </li>
                </Link>
              </h2>

              <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/open-requests"
                >
                  <li className="ms-4 fw-normal">
                    -{pathname === "/open-requests" && ">"} Open Requests
                  </li>
                </Link>
              </h2>
              <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/closed-complete-requests"
                >
                  <li className="ms-4 fw-normal">
                    -{pathname === "/closed-complete-requests" && ">"} Closed
                    Complete Requests
                  </li>
                </Link>
              </h2>
            </Fragment>
          )}
          {(user?.is_asset_manager || user?.user_role === "Admin") && (
            <h2 className="accordion-header">
              <Link
                className="accordion-button accordion-button__custom-item accordion-button__custom"
                to="/other-requests"
              >
                <li className="ms-4 fw-normal text-truncate">
                  -{pathname === "/other-requests" && ">"} Other Product/Service
                  Requests
                </li>
              </Link>
            </h2>
          )}
          {user?.is_asset_manager && (
            <Fragment>
              <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/product"
                >
                  <li className="ms-4 fw-normal">
                    -{pathname === "/product" && ">"} Products
                  </li>
                </Link>
              </h2>
              <h2 className="accordion-header">
                <Link
                  className="accordion-button accordion-button__custom-item accordion-button__custom"
                  to="/service"
                >
                  <li className="ms-4 fw-normal">
                    -{pathname === "/service" && ">"} Services
                  </li>
                </Link>
              </h2>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestManagement;
