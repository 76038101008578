import React, { useState } from "react";
import Navbar from "../../components/layouts/Navbar";

import "../../styles/task-management.css";
import { url } from "../../urls";
import { useEffect } from "react";
import serverAPI from "../../utils/serverAPI";
import { PageHeader } from "../../components/layouts/pageHeader";
// import { columns } from "./task-columns";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { AddTaskDialog } from "./AddTaskDialog";
import { UpdateTaskDialog } from "./UpdateTaskDialog";

const MyTaskPageNew = () => {
  const [data] = useState(JSON.parse(localStorage.getItem("data")));
  const [task_server, set_task_server] = useState([]);
  const [tasks, setTasks] = useState(task_server);
  const [addTask, setAddTask] = useState(false);
  const [updateTask, setUpdateTask] = useState(false);

  // GET_ALL TASK
  const getAllTasks = (order = "desc") => {
    serverAPI
      .get(url + "/tasks/mytasks/" + data.USER_ID + "?sort=" + order)
      .then((res) => {
        set_task_server(res.data);
        setTasks(res.data);
      })
      .catch((err) => console.log(err.response));
  };
  useEffect(() => {
    getAllTasks();
    return () => {};
  }, [addTask, updateTask]);

 

  const columns = [
    {
        headerName: 'Title',
        field: 'title',
        flex: 2,
    },
    {
        headerName: 'Description',
        field: 'description',
        flex: 3,
    },
    // {
    //     headerName: 'Note',
    //     field: 'note',
    //     flex: 1.5,
    // },
    {
        headerName: 'Priority',
        field: 'priority',
        flex: 1,
    },
    {
        headerName: 'Due Date',
        field: 'due_date',
        flex: 1.5,
        renderCell: ({row}) => {
          const date = new Date(row.due_date);
          return date.toLocaleDateString('en-GB');
        }
    },
    {
        headerName: "Actions",
        field: "actions",
        flex: 1.5,
        renderCell: ({ row }) => {
          return (
            <UpdateTaskDialog data={row} onUpdateTask={() => setUpdateTask(!updateTask)} />
          );
        },
      },
] 

  console.log("Tasks",tasks);

  return (
    <>
      <PageHeader header="My Tasks"/>
      <Navbar heading="TASK MANAGEMENT / MY TASKS" />
      <section className="main-container task-list-page">
        <div className="flex flex-col task-list-wrapper">
          <div>
            <DataGrid url='/tasks/1' rows={tasks} columns={columns} getRowId={(row) => row?.task_num} addButton={
                <div className="flex-row-reverse">
                    <AddTaskDialog onAddTask={() => setAddTask(!addTask)}/>
                </div>
            }/>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyTaskPageNew;
