import React, { Fragment, useState, useEffect, useContext } from 'react';
import Navbar from '../../components/layouts/Navbar';
import UserAuthContext from '../../context/userAuthContext/userAuthContext';
import $ from 'jquery';
import { useHistory } from 'react-router';
import userImg from '../../public/img/user.jpg';

const UserInfoForm = (props) => {
  const userAuthContext = useContext(UserAuthContext);

  const {
    loadUser,
    UserInfoForm,
    isActivated,
    hasProfile,
    getDepartments,
    departments,
    isAuthenticated,
  } = userAuthContext;

  const history = useHistory();

  const redirect = props.location.search
    ? props.location.search.split('==')[1]
    : '/';
  const redirectToHasProfile = props.location.search
    ? `user-profile${props.location.search}`
    : '/user-profile';
  const redirectToNotActivated = props.location.search
    ? `/not-acivated${props.location.search}`
    : '/not-acivated';

  useEffect(() => {
    readURL(userImg);
    getDepartments('/accounts/users');
    loadUser();
    if (isAuthenticated) {
      if (hasProfile === false) {
        props.history.push(redirectToHasProfile);
      } else if (isActivated === false) {
        props.history.push(redirectToNotActivated);
      } else {
        props.history.push(redirect);
      }
    }
    document.title = 'User Profile| ITSM';
    // eslint-disable-next-line
  }, [props.history, isAuthenticated, isActivated, hasProfile]);

  const [formData, setFormData] = useState({
    user: JSON.parse(localStorage.getItem('data')).USER_ID,
    full_name: '',
    department: '',
    phone: '',
    business_phone: '',
    user_role: 'User',
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const { user, full_name, department, phone, business_phone, user_role } =
    formData;

  const onIpChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    readURL(event.target);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('user', user);
    data.append('full_name', full_name);
    data.append('department', department);
    data.append('phone', phone);
    data.append('business_phone', business_phone);
    data.append('user_role', user_role);
    UserInfoForm(data, selectedFile, history);
  };

  const readURL = (input) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        $('#img-preview').attr('src', e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  return (
    <Fragment>
      <Navbar
        heading='MORE ABOUT YOU'
        subheading='PLEASE ENTER YOUR INFORMATION'
      />
      <section className='my-profile-container col-md-10 mx-auto'>
        <main className='d-flex flex-column'>
          <div className='row p-3 pb-0'>
            <div className='col-md-12'>
              <button
                type='button'
                className='btn dark-blue fw-bold bg-light-gray'
                style={{ border: '2px solid var(--seven)' }}>
                Please Enter Basic Information, So We Can Create Your Profile
              </button>
            </div>
          </div>
          <form
            onSubmit={onSubmit}
            id='userProfile'
            className='row p-3 justify-content-around'>
            <div className='col-md-8 ps-2'>
              <div className='row'>
                <img
                  src={userImg}
                  style={{
                    height: '200px',
                    width: '220px',
                    backgroundPosition: 'center',
                  }}
                  className='img-fluid mx-auto rounded-circle my-2 mb-5'
                  alt=''
                  id='img-preview'
                />
              </div>
              <div className='row my-2'>
                <div className='col-md-5 p-0'>
                  <label
                    htmlFor='full_name'
                    className='col-form-label fs-6 dark-blue me-5'>
                    Full Name
                    <span className='d-inline text-danger'> *</span>
                  </label>
                </div>
                <div className='col-md-7 p-0 d-flex justify-content-start'>
                  <input
                    type='hidden'
                    id='user'
                    name='user'
                    value={user}
                    className='form-control d-inline'
                  />
                  <input
                    type='text'
                    id='full_name'
                    name='full_name'
                    value={full_name}
                    onChange={onIpChange}
                    className='form-control d-inline'
                  />
                </div>
              </div>
              <div className='row my-2'>
                <div className='col-md-5 p-0'>
                  <label
                    style={{ fontSize: '500' }}
                    htmlFor='department'
                    className='col-form-label fs-6 dark-blue me-5'>
                    Your Department
                  </label>
                </div>
                <div className='col-md-7 p-0'>
                  <select
                    className='form-select'
                    id='department'
                    onChange={onIpChange}
                    name='department'>
                    <option value=''>------</option>
                    {departments &&
                      Object.entries(departments).map(([key, value]) => (
                        <option key={value.id} value={value.id}>
                          {key}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className='row my-2'>
                <div className='col-md-5 p-0'>
                  <label
                    style={{ fontSize: '500' }}
                    htmlFor='mobile-num'
                    className='col-form-label fs-6 dark-blue me-5'>
                    Mobile Number
                    <span className='d-inline text-danger'> *</span>
                  </label>
                </div>
                <div className='col-md-7 p-0'>
                  <input
                    className='form-control'
                    id='mobile-num'
                    value={phone}
                    onChange={onIpChange}
                    name='phone'
                    required
                  />
                </div>
              </div>
              <div className='row my-2'>
                <div className='col-md-5 p-0'>
                  <label
                    style={{ fontSize: '500' }}
                    htmlFor='business_phone'
                    className='col-form-label fs-6 dark-blue me-5'>
                    Extension Number
                  </label>
                </div>
                <div className='col-md-7 p-0'>
                  <input
                    className='form-control'
                    id='business_phone'
                    value={business_phone}
                    onChange={onIpChange}
                    name='business_phone'
                  />
                </div>
              </div>
              <div className='row my-2'>
                <div className='col-md-5 p-0'>
                  <label
                    style={{ fontSize: '500' }}
                    htmlFor='role'
                    className='col-form-label fs-6 dark-blue me-5'>
                    Role
                    <span className='d-inline text-danger'> *</span>
                  </label>
                </div>
                <div className='col-md-7 p-0'>
                  <input
                    className='form-control'
                    id='role'
                    value={user_role}
                    onChange={onIpChange}
                    name='user_role'
                    readOnly
                  />
                </div>
              </div>
              <div className='row my-2'>
                <div className='col-md-5 p-0'>
                  <label
                    style={{ fontSize: '500' }}
                    htmlFor='profile_pic'
                    className='col-form-label fs-6 dark-blue me-5'>
                    Profile Picture
                    <span className='d-inline text-danger'> *</span>
                  </label>
                </div>
                <div className='col-md-7 p-0'>
                  <div>
                    <input
                      name='profilePic'
                      id='profile_pic'
                      className='form-control'
                      type='file'
                      accept='image/*'
                      onChange={changeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center mt-3'>
              <button
                className='btn white bg-blue fw-bold'
                type='submit'
                style={{
                  boxShadow: '0 4px 10px #109bf140',
                  marginRight: '2rem',
                }}>
                Update Profile
              </button>
            </div>
          </form>
        </main>
      </section>
    </Fragment>
  );
};

export default React.memo(UserInfoForm);
