import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { ReminderItem } from "./TaskReminder";

const TaskDueDate = ({ dueDate, id, putTask, onChange: handler, disabled }) => {
  const [value, onChange] = useState(dueDate || null);
  useEffect(() => {
    onChange(dueDate);
  }, [dueDate]);

  return (
    <ReminderItem
      noHoverEffect
      column
      icon={<i class="fal fa-calendar-alt"></i>}
      title="Task Due Date"
      time={
        disabled ? (
          <div className="mt-1">
            {moment(dueDate).format("DD MMM YYYY, HH:mm")}
          </div>
        ) : (
          <DateTimePicker
            disabled={disabled}
            //  onChange={onChange}
            onChange={(e) => {
              onChange(e);
              handler(e);
              // putTask({ id, due_date: e });
            }}
            value={value || null}
          />
        )
      }
    />
  );
};
// ReminderItem.props.closeWidgets;
export default TaskDueDate;
