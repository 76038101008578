import React, { useContext, useReducer } from "react";

import IncidentContext from "./incidentContext";
import incidentReducer from "./incidentReducer";
import {
  GET_INCIDENTS,
  GET_RESOLVED_INCIDENTS,
  GET_INC_OVERVIEW_DATA,
  GET_OPENED_INC,
  GET_OPENEUNASIGNED_INC,
  GET_RAISEDBY_ME,
  GET_ASSIGENDTO_ME,
  SET_LOADING,
  GET_TABLE_DATA,
  GET_M_TO_M_DATA,
  GET_INCIDENTS_TREND_DATA,
} from "../types";
import { url } from "../../urls";
import PortalContext from "../portalContext/portalContext";
import serverAPI from "../../utils/serverAPI";

const IncidentState = (props) => {
  const initialState = {
    incTableData: [],
    resolvedInc: [],
    openInc: [],
    openUnassigned: [],
    pichartData: {
      cancelled: 0,
      closed: 0,
      in_progress: 0,
      new: 0,
      on_hold: 0,
      resolved: 0,
    },
    pichartDataNew: {
      all: 0,
      cancelled: 0,
      closed: 0,
      in_progress: 0,
      new: 0,
      on_hold: 0,
      resolved: 0,
      all_inc_weekly: "",
      new_inc_weekly: "",
      inprogress_inc_weekly: "",
      onhold_inc_weekly: "",
      cancelled_inc_weekly: "",
    },
    linechartData: {},
    tableData: {},
    barData: {},
    error: null,
    updateLogs: [],
    raisedByMe: [],
    loading: false,
    assignedToMe: [],
    incidentTrendData: {},
  };

  const [state, dispatch] = useReducer(incidentReducer, initialState);

  const portalContext = useContext(PortalContext);
  const { getUpdateLogs, setAlert, clearError } = portalContext;

  const getIncidents = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/incidents/${pag_no}`);
    dispatch({ type: GET_INCIDENTS, payload: res.data });
    dispatch({ type: SET_LOADING });
  };

  // get reolved inc
  const getResolvedIncidents = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/incidents/resolved/${pag_no}`);
    dispatch({
      type: GET_RESOLVED_INCIDENTS,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };
  // get open Incidents
  const getOpenIncidents = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/incidents/opened/${pag_no}`);
    dispatch({
      type: GET_OPENED_INC,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get openUnasigned incidents

  const getOpenUnassignedIncidents = async (pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(`${url}/incidents/unassigned/${pag_no}`);
    dispatch({
      type: GET_OPENEUNASIGNED_INC,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get raised by me
  const getraisedByMe = async (id, pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(
      `${url}/incidents/myincidents/${id}/${pag_no}`
    );
    dispatch({
      type: GET_RAISEDBY_ME,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };
  // get Assigned to me
  const getassignedToMe = async (id, pag_no) => {
    dispatch({ type: SET_LOADING });
    const res = await serverAPI.get(
      `${url}/incidents/assignedtome/${id}/${pag_no}`
    );
    dispatch({
      type: GET_ASSIGENDTO_ME,
      payload: res.data,
    });
    dispatch({ type: SET_LOADING });
  };

  // get all data of inc overview in one req
  const getIncOverviewData = async () => {
    dispatch({ type: SET_LOADING });
    const pi = await serverAPI.get(`${url}/incidents/piechart`);
    const piNew = await serverAPI.get(`${url}/incidents/piechart/new`);
    const line = await serverAPI.get(`${url}/incidents/week`);
    const piData = pi.data;
    const piDataNew = piNew.data;

    // set data of line
    let data1 = [];
    let data2 = [];
    let lable1 = "";
    let lable2 = "";
    data1.push(Object.values(line.data)[0]);
    data2.push(Object.values(line.data)[1]);
    lable1 = Object.keys(line.data)[0];
    lable2 = Object.keys(line.data)[1];
    let lineData = {
      data1: data1[0],
      data2: data2[0],
      lable1: lable1.toUpperCase(),
      lable2: lable2.toUpperCase(),
    };

    dispatch({
      type: GET_INC_OVERVIEW_DATA,
      payload: { piDataNew, piData, lineData },
    });
    dispatch({ type: SET_LOADING });
  };

  const getTableData = async (page) => {
    const res = await serverAPI.get(`${url}/incidents/table/${page}`);
    if (res.status === 200) {
      dispatch({ type: GET_TABLE_DATA, payload: res.data });
    }
  };

  const getMtoMData = async (page) => {
    const res = await serverAPI.get(`${url}/incidents/permonth/${page}`);
    if (res.status === 200) {
      // set bar chart
      let lables = [];
      let data11 = [];
      let data21 = [];
      let last_month = "";
      let this_month = "";
      let remaining;
      for (const [key, value] of Object.entries(res.data)) {
        if (key !== "remaining") {
          lables.push(key);
          data11.push(Object.values(value)[0]);
          data21.push(Object.values(value)[1]);
          last_month = Object.keys(value)[0].toUpperCase();
          this_month = Object.keys(value)[1].toUpperCase();
        } else {
          remaining = value;
        }
      }
      let barData = {
        lables,
        data1: data11,
        data2: data21,
        last_month,
        this_month,
        remaining,
      };
      dispatch({ type: GET_M_TO_M_DATA, payload: barData });
    }
  };

  // create new inc
  const createInc = async (data, history) => {
    dispatch({ type: SET_LOADING });
    try {
      const res = await serverAPI.post(`${url}/incidents/0`, data);
      if (res.status === 201) {
        setAlert({
          msg: "Incident Created successfully.",
          type: "success",
          icon: "check-circle",
        });
        setTimeout(() => {
          clearError();
        }, 5000);

        history.push(`/update-incident/${res.data.inc_num}`);
      } else {
        setAlert({
          msg: Object.values(res.data)[0][0],
          type: "danger",
          icon: "exclamation-triangle",
        });
        setTimeout(() => clearError(), 5000);
      }
    } catch (error) {
      setAlert({
        msg: "Something Went wrong please try again!",
        type: "danger",
        icon: "exclamation-triangle",
      });
      setTimeout(() => clearError(), 5000);
    }
    dispatch({ type: SET_LOADING });
  };

  const updateInc = async (data, inc_num, logData, cloData, fetchData) => {
    dispatch({ type: SET_LOADING });
    const config = {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      },
    };
    const res = await serverAPI.put(
      `${url}/incidents/update/${inc_num}`,
      data,
      config
    );

    if (res.status === 202) {
      logData["timeline"] = res.data.timeline_id;
      await serverAPI.post(`${url}/portal/log/0`, logData);
      setAlert({
        msg: "Incident Updated successfully.",
        type: "success",
        icon: "check-circle",
      });
      fetchData();
      getUpdateLogs(inc_num);
      setTimeout(() => clearError(), 5000);
      if (cloData) {
        await serverAPI.put(`${url}/portal/closure/${inc_num}`, cloData);
      }
    } else {
      setAlert({
        msg: "Something Went wrong please try again!",
        type: "danger",
        icon: "exclamation-triangle",
      });

      setTimeout(() => clearError(), 5000);
    }
    dispatch({ type: SET_LOADING });
  };

  const getIncidentTrendData = async () => {
    const res = await serverAPI.get(`${url}/incidents/incident_trend`);
    if (res.status === 200) {
      dispatch({ type: GET_INCIDENTS_TREND_DATA, payload: res.data });
    }
  };

  return (
    <IncidentContext.Provider
      value={{
        incTableData: state.incTableData,
        resolvedInc: state.resolvedInc,
        openInc: state.openInc,
        openUnassigned: state.openUnassigned,
        pichartData: state.pichartData,
        pichartDataNew: state.pichartDataNew,
        linechartData: state.linechartData,
        tableData: state.tableData,
        barData: state.barData,
        error: state.error,
        raisedByMe: state.raisedByMe,
        assignedToMe: state.assignedToMe,
        loading: state.loading,
        incidentTrendData: state.incidentTrendData,
        getIncidents,
        getResolvedIncidents,
        getIncOverviewData,
        getOpenIncidents,
        getOpenUnassignedIncidents,
        createInc,
        updateInc,
        getraisedByMe,
        getassignedToMe,
        getTableData,
        getMtoMData,
        getIncidentTrendData,
      }}
    >
      {props.children}
    </IncidentContext.Provider>
  );
};

export default IncidentState;
