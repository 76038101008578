import React, { useState } from "react";

const TaskHeader = ({ searchHandler, sortTasksHandler = () => {} }) => {
  const [search, setSearch] = useState("");

  const [sort, toggleSort] = useState(true);

  return (
    <div
      className="task-header flex-ver px-5 py-4 justify-content-start"
      style={{ gap: "1rem" }}
    >
      <p
        style={{
          fontSize: "20px",
          position: "relative",
          top: "8px",
        }}
      >
        TASKS
      </p>
      <div
        className="task-searcher d-flex align-items-center"
        style={{ width: "300px" }}
      >
        <input
          value={search}
          type="text"
          placeholder="Search..."
          style={{
            outline: "none",
            border: "none",
            background: "transparent",
            width: "100%",
          }}
          onChange={(e) => {
            searchHandler(e.target.value);
            setSearch(e.target.value);
          }}
        />
        <i className="far fa-search fa-lg ms-2" style={{ cursor: "pointer" }}></i>
      </div>
      <div className="task-header-action-wrapper flex-ver ml-auto ms-auto">
        <div
          className="task-header-action-item flex-ver"
          onClick={() => {
            sortTasksHandler(sort ? "asc" : "desc");
            toggleSort(!sort);
          }}
        >
          <i
            className="fal fa-sort-alt"
            style={{
              color: !sort && "#3ddf3d",
            }}
          ></i>
          <span style={{ whiteSpace: "nowrap", width: "140px" }}>
            Sort : {sort ? "Ascending" : "Descending"}
          </span>
        </div>{" "}
        {/* <div className="task-header-action-item flex-ver">
          <i className="fad fa-lightbulb"></i>
          <span>Suggestions</span>
        </div> */}
      </div>
    </div>
  );
};

export default TaskHeader;
