import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import { FormContainer } from "../../components/layouts/commonLayouts/container/FormContainer";
import { Formik, Form } from "formik";
import { AutoComplete, Label } from "../../components/layouts/forms/inputs";
import { Box, Grid } from "@mui/material";
import { useCreateOrUpdate, useGetAll } from "../../hooks";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import * as yup from "yup";
import { toast } from "../../utils/toaster";

const EmailNotifications = ({ history }) => {
  const NotificationsFor = ["Incident", "Problem", "Change"];
  const [name, setName] = useState("Incident");

  const { data: configData, refetch } = useGetAll({
    key: `/portal/notificationsconf/${name}`,
  });
  const { data: agents } = useGetAll({
    key: `/accounts/agents`,
  });
  const { data: users } = useGetAll({
    key: `/accounts/users/dropdown`,
  });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      refetch();
      document.title = "Email Configuration";
    }
  }, []);

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/portal/notificationsconf/${name}`,
    method: "post",
    onSuccess: () => refetch(),
  });

  const initialState = {
    notifications_for: "Incident",
    agent: "",
    users: [],
  };

  const validationSchema = yup.object().shape({
    notifications_for: yup.string().required("Please select above"),
    agent: yup.object().shape({
      id: yup.number(),
      email: yup.string().email(),
      profile: yup.object().shape({ full_name: yup.string() }),
    }),
    users: yup.array().of(
      yup.object().shape({
        id: yup.number(),
        email: yup.string().email(),
        profile: yup.object().shape({ full_name: yup.string() }),
      })
    ),
  });

  return (
    <Fragment>
      <Navbar heading="EMAIL NOTIFICATIONS" subheading="" />
      <section
        className="main-container d-flex justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <FormContainer className="border rounded">
          <Formik
            initialValues={{
              ...initialState,
              ...configData,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const outObj = {
                notifications_for: name,
                users: values?.users?.map((user) => user.id).join(","),
                agent: values.agent.id,
              };
              mutate(outObj);
              toast(`Email configuration saved successfully`);
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid xs={12} item width={"100px"}>
                  <AutoComplete
                    options={NotificationsFor}
                    className="mb-2"
                    name="notifications_for"
                    label="Receive notifications for"
                    required
                    value={name}
                    onChange={(e, v) => {
                      setName(v);
                      refetch();
                    }}
                  />
                </Grid>

                <Grid xs={12} item>
                  <AutoComplete
                    options={agents || []}
                    className=" mb-2"
                    name="agent"
                    label="Agent"
                    getOptionLabel={(option) =>
                      option?.profile?.full_name || ""
                    }
                    helperText={
                      " Agent will receive notifications for all the Incidents"
                    }
                  />
                </Grid>

                <Grid xs={12} item 
                className="sm:flex sm:flex-col"
                >
                  <AutoComplete
                    options={users || []}
                    className=" mb-2"
                    name="users"
                    label="Users"
                    getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                    multiple
                    helperText={
                      " These people will receive notifications for all the Incidents"
                    }
                  />
                </Grid>
              </Grid>
              <Grid xs={12} item className="flex mt-2">
                <Label text={"Last Updated At : "} />{" "}
                {configData?.updated_on || "-"}
              </Grid>

              <Grid xs={12} item>
                <Box className="flex justify-end mt-8">
                  <Button
                    isLoading={isLoading}
                    variant="contained"
                    className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              </Grid>
            </Form>
          </Formik>
        </FormContainer>
      </section>
    </Fragment>
  );
};

export default EmailNotifications;
