import React from "react";

const TaskCheckbox = ({ checked = false, onClick, disabled = false }) => {
  return (
    <span onClick={onClick} style={{ cursor: !disabled && "pointer" }}>
      {!checked ? (
        <i class="far fa-circle fa-lg"></i>
      ) : (
        <i class="far fa-check-circle fa-lg"></i>
      )}
    </span>
  );
};
export const StarredChecbox = ({ checked = false, onClick }) => {
  return (
    <span onClick={onClick} style={{ cursor: "pointer" }}>
      {!checked ? (
        <i class="fal fa-star fa-lg"></i>
      ) : (
        <i class="fas fa-star fa-lg"></i>
      )}
    </span>
  );
};

export default TaskCheckbox;
