import React from 'react';

const SmallPagination = ({ page, getData, setPage, isSmall, data }) => {
  const getPageData = (which) => {
    if (which === 0) {
      getData(page - 1);
      setPage(page - 1);
    } else if (which === 1) {
      getData(page + 1);
      setPage(page + 1);
    }
  };
  return (
    <div className='d-flex justify-content-between p-2 align-item-center mt-3'>
      <p className='mb-2 text-muted d-flex align-items-end d-inline'>
        <em>
          *Fields sorted in descending manner of{isSmall ? <br /> : ''} tickets
          raised*
        </em>{' '}
      </p>
      <div
        className='btn-group'
        role='group'
        aria-label='Basic example'
        style={{ height: '38px' }}>
        <button
          type='button'
          className='btn bg-light-blue blue'
          onClick={() => getPageData(0)}
          disabled={page === 1 ? true : false}>
          <i className='fas fa-chevron-left'></i>
        </button>
        <button type='button' className='btn bg-blue dark-blue'>
          {page}
        </button>
        <button
          type='button'
          className={
            data && data.remaining <= 0
              ? 'disabled btn bg-light-blue blue'
              : 'btn bg-light-blue blue'
          }
          onClick={() => getPageData(1)}>
          <i className='fas fa-chevron-right'></i>
        </button>
      </div>
    </div>
  );
};

export default SmallPagination;
