import React, { useEffect } from "react";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { Link } from "react-router-dom";
import {
  Button,
  SecondaryButton,
} from "../../components/layouts/commonLayouts/buttons";
import { Download } from "@carbon/icons-react";
import { useDownaloadFile } from "../../hooks";
import { PageHeader } from "../../components/layouts/pageHeader";
import { useMediaQuery } from '@mui/material';
// import MyCard from "./MyCard2";
// import { columns } from "../incidents/IncidentColumns";
import MyCard from "../Problem/component/ProblemCard";
import { columns } from "./ProblemColumns";

const AllNewProblem = ({ history }) => {
  // const isMobile = useMediaQuery('(max-width:600px)');  
  const isMobile = useMediaQuery('(max-width:1024px)');  //same for tab and phone 
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/view-status");
    } else {
      document.title = "All Incidents";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page_data: {
          country: 'YourCountry',
          language: 'en',
          page_url: window.location.href,
          page_category: 'Problem Management',
          page_location: 'AllNewProblem',
          page_referrer: document.referrer,
          page_title: document.title,
          region: 'Pune',
          site_section: 'Support',
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: 'User',
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        }
      });
      console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const { data: allProblems } = useGetAll({
    key: "problems/allproblems",
    params: {
      page: 0,
      limit: 25,
    },
  });

  const { refetch, isLoading } = useDownaloadFile("/incidents/csv/0", () => {});
  const { refetch: refetch1, isLoading: isLoading1 } = useDownaloadFile(
    "/incidents/csv/1",
    () => {}
  );
  
  return (
    <>
 
      <PageHeader header={"All Problems"} className="mb-4" />  
      {
        isMobile?(<div className="flex-col h-60">
          <Link to="/create-problem">
            <Button className="ml-4 mt-8">New Problem</Button>
          </Link>
        </div>
      ):(<></>)
      }
        
      {isMobile ? (
      allProblems?.rows?.map((row, index) => <MyCard key={index} row={row}/>)
      ) : (
        <DataGrid
        columns={columns}
        url="problems/allproblems"
        rows={allProblems}
        getRowId={(row) => row?.prb_num}
       addButton={
          <div className="flex-row-reverse mt-4">
            <Link to="/create-problem">
              <Button className="ml-4">New Problem</Button>
            </Link>
          </div>
        }
      />
      )}
    </>
  );
};

export default AllNewProblem;
