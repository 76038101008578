import React, { Fragment, useState } from "react";
import { url } from "../../urls";
import $ from "jquery";
import SuccessAlert from "../alerts/SuccessAlert";
import serverAPI from "../../utils/serverAPI";

const AddConfigurationItem = ({ getConfigItems }) => {
  const [item, setItem] = useState({
    name: "",
    description: "",
  });
  const { name, description } = item;
  const [error, setError] = useState(null);
  const AddConfigItem = async (e) => {
    e.preventDefault();
    const res = await serverAPI.post(`${url}/portal/items/0`, item);
    if (res.status === 201) {
      setError({
        msg: "Configuration Item Added Successfully.",
        type: "success",
        icon: "check-circle",
      });

      getConfigItems();
      setItem({
        name: "",
        description: "",
      });
      $("#name").val("");
      $("#description").val("");
    } else {
      setError({
        msg: Object.values(res.data)[0][0],
        type: "danger",
        icon: "exclamation-triangle",
      });
    }
    setTimeout(() => setError(null), 5000);
  };
  return (
    <Fragment>
      <button
        type="button"
        className="btn fw-bold sec-gary blue px-3 py-1 me-1"
        style={{
          border: "2px solid var(--sec)",
        }}
        data-bs-toggle="modal"
        data-bs-target="#AddConfigModel"
      >
        Add Config Item
      </button>
      <div
        className="modal fade"
        id="AddConfigModel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title dark-blue" id="exampleModalLabel">
                Add Configuration Item
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {error ? (
              <div className="col-md-11 mx-auto mt-3">
                <SuccessAlert data={error} />
              </div>
            ) : (
              ""
            )}
            <form onSubmit={AddConfigItem}>
              <div className="modal-body">
                <div className="row mb-3">
                  <label
                    htmlFor="name"
                    style={{ fontWeight: "500" }}
                    className="col-form-label col-md-4 dark-blue fs-7"
                  >
                    Name <span className="d-inline text-danger"> *</span>
                  </label>
                  <div className="col-md-8 ps-0">
                    <input
                      type="text"
                      name="name"
                      defaultValue={name}
                      onChange={(e) =>
                        setItem({ ...item, [e.target.name]: e.target.value })
                      }
                      className="form-control"
                      id="name"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="description"
                    style={{ fontWeight: "500" }}
                    className="col-form-label col-md-4 dark-blue fs-7"
                  >
                    Description
                  </label>
                  <div className="col-md-8 ps-0">
                    <textarea
                      name="description"
                      defaultValue={description}
                      style={{ height: "100px" }}
                      onChange={(e) =>
                        setItem({ ...item, [e.target.name]: e.target.value })
                      }
                      className="form-control"
                      id="description"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn white bg-blue fw-bold"
                  style={{
                    boxShadow: "0 4px 10px #109bf140",
                  }}
                >
                  Add Item
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddConfigurationItem;
