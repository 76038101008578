import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
import { ToggleSideNavBar } from "../../utils/PortalUtils";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import userImg from "../../public/img/user.jpg";
import { url } from "../../urls";

import IncidentManagement from "./SideNavbar/IncidentManagement";
import RequestManagement from "./SideNavbar/RequestManagement";
import TaskManagement from "./SideNavbar/TaskManagement";

// import ProblemManagement from './SideNavbar/ProblemManagement';
// import ChangeManagement from './SideNavbar/ChangeManagement';
import AdminPanel from "./SideNavbar/AdminPanel";

const SideNavbar = () => {
  const userAuthContext = useContext(UserAuthContext);
  const { hasProfile, user } = userAuthContext;
  const [userData, setuserData] = useState({
    email: "example@mail.com",
    full_name: "UserName",
    profile_pic: "",
  });

  useEffect(() => {
    setuserData({
      email: user.email,
      full_name: user.full_name,
      profile_pic: user.profile_pic,
    });
    // eslint-disable-next-line
  }, [user.profile_pic, user.email, user.full_name]);

  const { email, full_name, profile_pic } = userData;

  const location = useLocation();
  const { pathname } = location;

  const myProfile = (e) => {
    $(".sidenav-item").each(function () {
      if ($(this).hasClass("active-list")) {
        $(this).removeClass("active-list");
      }
    });
    $(".myprofile").toggleClass("active-list");
  };

  return (
    <Fragment>
      <aside className="side-navbar">
        <div className="side-navbar-div" style={{ padding: "17px" }}>
          <Link to="/" className="h2 fs-4 mb-3 d-block blue logo">
            ITSM
          </Link>
          <Link
            to="/my-profile"
            onClick={myProfile}
            className={
              pathname === "/my-profile"
                ? "active-list myprofile d-flex justify-content-start align-items-center sidenav-item px-2"
                : "myprofile d-flex justify-content-start align-items-center sidenav-item px-2"
            }
          >
            <img
              src={profile_pic ? `${url}${profile_pic}` : `${userImg}`}
              className="rounded-circle img-fluid user-img ms-0"
              alt="user profile"
              style={{
                width: "60px",
                height: "60px",
                objectFit: "cover",
                objectPosition: "center",
                display: "block",
              }}
            />
            <div className=" mt-3 d-flex flex-column sidenav-item">
              <h5 className=" ms-3 h5 fs-6 dark mb-0">
                {full_name ? full_name : "Username"}
              </h5>
              <p
                className=" ms-3 text-muted mt-0 useremail"
                style={{
                  fontSize: "10px",
                }}
              >
                {email}
              </p>
            </div>
          </Link>
          <div className={hasProfile === false ? "mt-3 disabled" : "mt-3"}>
            <ul className="item-list p-0">
              <IncidentManagement pathname={pathname} user={user} />
              <div className="accordion" id="accordionExample">
                <TaskManagement pathname={pathname} user={user} />
              </div>
              <RequestManagement pathname={pathname} user={user} />

              {user.user_role === "Admin" && (
                <AdminPanel pathname={pathname} user={user} />
              )}
            </ul>
          </div>
        </div>
        <div
          className="navbar-toggle px-3 d-flex align-items-center sidenav-item"
          onClick={ToggleSideNavBar}
          style={{
            boxShadow: "0px -10px 20px rgba(0,0,0,0.1)",
            cursor: "pointer",
            width: "100%",
            margin: "0 -1rem !important",
          }}
        >
          <i
            className="fal fs-5 fa-columns blue d-block text-center"
            style={{ width: "60px" }}
          ></i>
          <p className="sec-gary fs-6 ms-3 m-0">Toggle sidebar</p>
        </div>
      </aside>
    </Fragment>
  );
};

export default React.memo(SideNavbar);
