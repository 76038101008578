import React from "react";

const TaskLabel = ({ icon: Icon, text }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        cursor: "pointer",
        marginTop: "0.5rem",
      }}
    >
      <span style={{ marginLeft: "0.3rem" }}>{Icon}</span>
      <p style={{ margin: 0, marginLeft: "1.1rem" }}>{text}</p>
    </div>
  );
};

export default TaskLabel;
