import { Grid, Box, useMediaQuery } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Input,
  AutoComplete,
  Label,
  DropZone,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import Navbar from "../../components/layouts/Navbar";
// import { Editor } from "react-draft-wysiwyg";
// import { useCreateOrUpdate } from "../../hooks";
import { toast } from "../../utils/toaster";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useGetAll } from "../../hooks";
import { UserAvatar } from "@carbon/icons-react";
import { openUserInfoBox, closeUserInfoBox } from "../../utils/PortalUtils";
import serverAPI from "../../utils/serverAPI";
import { url } from "../../urls";
import { useHistory } from "react-router-dom";
import BasicTabs from "./CreateIncidentTabs";
import { PageHeader } from "../../components/layouts/pageHeader";
import { importance } from "../../utils/IncidentUtils";
import { data } from "jquery";
import { datahandleClick } from "../../components/DataLayer/ButtonClick";
import { pushToDataLayer } from '../../components/DataLayer/FormData';

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const contactModeOption = ["Email", "Phone", "Self Service", "Walk-in"];
const userInfo = {
  user: localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : {},
};
const userDept = localStorage.getItem("user_dept")
  ? JSON.parse(localStorage.getItem("user_dept"))
  : {};

const initialState = {
  raised_by: { value: "", name: "Select" },
  caller: { value: "", name: "Select" },
  category: { value: "", name: "Select" },
  sub_category: { value: "", name: "Select" },
  service: "",
  assigned_grp: { value: "", name: "Select" },
  assigned_to: { value: "", name: "Select" },
  attachment: "",
  priority: "Normal",
  contact_type: "",
  state: "New",
  impact: "Low",
  urgency: "Low",
  contact_email: "",
  contact_phone: "",
};

const incidentValidations = yup.object({
  raised_by: yup
    .object()
    .required("Field is required")
    .typeError("Please enter valid data"),
  caller: yup
    .object()
    .required("Field is required")
    .typeError("Please enter valid data"),
  assigned_grp: yup.object().typeError("Please enter valid data"),
  category: yup.object().typeError("Please enter valid data"),
  sub_category: yup.object().typeError("Please enter valid data"),
  assigned_to: yup.object().typeError("Please enter valid data"),
  service: yup.string().required().typeError("Please enter valid data"),
  priority: yup.string().required().typeError("Please enter valid data"),
  state: yup.string().typeError("Please enter valid data"),
  impact: yup.string().typeError("Please enter valid data"),
  urgency: yup.string().typeError("Please enter valid data"),
  contact_type: yup.string().typeError("Enter valid data"),
  contact_phone: yup.string().when("contact_type", {
    is: "Phone",
    then: yup
      .string()
      .matches(
        /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{8,9}$/,
        "Please enter valid Number"
      ),
  }),
  contact_email: yup.string().when("contact_type", {
    is: "Email",
    then: yup.string().email("Plaese enter valid email"),
  }),
  attachment: yup.mixed().test(
    "fileSize",
    "Only documents up to 25MB are permitted.",
    (files) =>
      !files || // Check if `files` is defined
      Array.from(files).every((file) => file.size <= 25_000_000)
  ),
});

const CreateIncForm = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const history = useHistory();
  const [description, setDescription] = useState("");
  const [tooltipInfo, setTooltipInfo] = useState({
    username: userInfo?.user?.full_name,
    email: userInfo?.user?.email,
    phoneno: userInfo?.user?.phone,
    userRole: userInfo?.user?.user_role,
    department: userDept,
  });

  useEffect(() => {
    document.title = "Create Incident";
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Incident Management',
        page_location: 'Create-New',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Navbar heading="CREATE NEW INCIDENT" subheading="NEW INCIDENT" />
      <section className="main-container sm:p-0 m-0 ">
        <main className="d-flex flex-column px-4 pt-2 sm:p-0 sm:w-[100%] ">
          <Formik
            initialValues={{
              ...initialState,
              raised_by: localStorage.getItem("data")
                ? JSON.parse(localStorage.getItem("data"))
                : { value: "", name: "Select" },
              caller: localStorage.getItem("data")
                ? JSON.parse(localStorage.getItem("data"))
                : { value: "", name: "Select" },
            }}
            validationSchema={incidentValidations}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              const formData = new FormData(this);
              formData.append("raised_by", values?.raised_by?.USER_ID);
              formData.append(
                "caller",
                values?.caller?.USER_ID || values?.caller?.id
              );
              formData.append("category", values?.category?.id || "");
              formData.append("sub_category", values?.sub_category?.id || "");
              formData.append("service", values?.service);
              formData.append("assigned_grp", tooltipInfo.department.id);
              formData.append("assigned_to", values?.assigned_to?.id || "");
              Object.entries(values?.attachment).forEach(([key, value]) => {
                formData.append("attachment", values?.attachment[key]);
              });
              formData.append("priority", values?.priority);
              formData.append("contact_type", values?.contact_type);
              formData.append("state", values?.state);
              formData.append("impact", values?.impact);
              formData.append("urgency", values?.urgency);
              formData.append("contact_phone", values?.contact_phone);
              formData.append("contact_email", values?.contact_email);
              formData.append("short_desc", description);
              for (var pair of formData.entries()) {
                console.log(pair[0] + ", " + pair[1]);
              }
              // mutate(
              //   {
              //     formData,
              //   },
              //   {
              //     onSuccess: async () => {
              //       resetForm();
              //       toast(`Incident created successfully`);
              //     },
              //   }
              // );
              if (description.length !== 0) {
                const res = await serverAPI.post(`/incidents/0`, formData);
                if (res?.statusText === "Created") {
                  console.log("in if block");
                  resetForm();
                  setDescription("");
                  toast("Incident created successfully");
                  // <Redirect to={`/update-incident/${res?.data?.inc_num}`} />;
                  history.push(`/incidents-raised-by-me`);
                } else {
                  toast("Something went wrong", "error");
                }
              } else {
                toast("Description is required", "error");
              }
            }}
          > 
            {({ values, errors, setFieldValue,handleChange }) => (
              <>
                <Form 
                 onChange={(e) => {
                  handleChange(e);
                  pushToDataLayer(values);
              }}
                className="" >
                  <PageHeader header={"Create New Incident"} className="mb-4" />

                  <Grid
                    container
                    columnSpacing={isMobile?1:6}
                  className="sm:flex-col sm:p tab:grid-cols-2 pr-6 sm:w-[100%] "
                  >
                    <Grid
                      item
                      xs={6} 
                      className="mt-4 justify-between sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                
                        getOptionLabel={(opt) => opt.full_name}
                        label="Raised By"
                        name="raised_by"
                        value={values?.raised_by}
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <Grid container columnSpacing={0}>
                        <Grid item xs={12}>
                          {userInfo?.user?.user_role !== "User" ? (
                            <>
                              <AutoComplete
                                url="/accounts/usernamewithid"
                                getOptionLabel={(opt) => opt.full_name}
                                label="Raised For"
                                 value={values?.caller}
                                name="caller"
                                required
                                onChange={(_, value) => {
                                  setTooltipInfo({
                                    username: value.full_name,
                                    email: value.email,
                                    phoneno: value.phone,
                                    userRole: value.user_role,
                                    department: value.department,
                                  });
                                  setFieldValue("caller", value);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <Input
                                label="Raised For"
                                name="caller"
                                value={values?.caller.full_name}
                                disabled
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        value={tooltipInfo?.department || "-"}
                        getOptionLabel={(opt) => opt.name}
                        label="Your Department"
                        name="assigned_grp"
                        required
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        url={"/portal/allcats"}
                        getOptionLabel={(opt) => opt?.name || ""}
                        label="Category"
                        name="category"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        options={values?.category?.sub_categories}
                        getOptionLabel={(opt) => opt?.name || ""}
                        label="Sub Category"
                        name="sub_category"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        name="assigned_to"
                        label="Assign To"
                        options={values?.sub_category?.users}
                        getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        label="Status"
                        name="state"
                        required
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        options={importance}
                        label="Impact"
                        name="impact"
                        required
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        label="Urgency"
                        name="urgency"
                        options={importance}
                        required
                        disabled
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        label="Priority"
                        name="priority"
                        required
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        options={contactModeOption}
                        getOptionLabel={(opt) => opt || "-------"}
                        label="Contact Type"
                        name="contact_type"
                      />
                    </Grid>
                    {values.contact_type === "Email" ? (
                      <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                        <Input
                          name="contact_email"
                          label="Email"
                          value={
                            values.contact_type === "Email"
                              ? tooltipInfo?.email
                              : ""
                          }
                        />
                      </Grid>
                    ) : values.contact_type === "Phone" ? (
                      <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                        <Input
                          name="contact_phone"
                          label="Mobile Number"
                          value={
                            values.contact_type === "Phone"
                              ? tooltipInfo?.phoneno
                              : ""
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={6} className="mt-4"></Grid>
                    )}
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <Label text="Affected Service" required />
                      <Input
                        placeholder="Add your text here...."
                        multiline
                        required
                        rows={5}
                        name="service"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <DropZone
                        name="attachment"
                        label="Attachments"
                        fileType={{
                          "file/*": [".xlsx", ".xls", ".pdf", ".jpeg"],
                        }}
                        multiple={true}
                      />
                    </Grid>

                    <Grid item xs={12} className="mt-4 sm:w-[100%] tab:w-full">
                      <Label text="Short Description" required />
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "300px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>
                    <Grid xs={12} item className="sm:self-center tab:self-center" >
                      <Box className="mt-5 mb-4 d-flex justify-content-end">
                        <Button
                          variant="contained"
                          className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                          type="submit"
                          onClick={() => {
                            console.log({ errors }, { values });
                          }}
                        >
                          Raise Incident
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
          <BasicTabs />
        </main>
      </section> 
    </>
  );
};

export default CreateIncForm;
