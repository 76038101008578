import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ProductDialog } from "../../components/AdminPanelLayout/ProductDialog";
import { Button, Link } from "@mui/material";

export default function ServiceCard({ row, match,key }) {
  console.log("Service card", row);

  return (
    <>
    
        
      
      <Box sx={{ minWidth: 275 }} key={key}>
        <Card variant="outlined" className="mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.name}
            </Typography>
            <Typography variant="h5" component="div">
              {row?.category?.name}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.description}<br/>
              Price: {row?.price}
            </Typography>
            <div className="w-full flex justify-between">
              <ProductDialog
                isUpdate
                data={row}
                product_type={match.path.replace("/", "")}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
