import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Avatar, Divider } from "@mui/material";
import { FaCircle } from "react-icons/fa";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  padding: "0px",
  backgroundColor: "white",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  //   borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ name, workNotes, image }) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="flex flex-row justify-start items-center w-[50%]">
            <Avatar alt="Image" src={image} sx={{ width: 28, height: 28 }} />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "13px",
                color: "#006DCC",
                marginLeft: "10px",
              }}
            >
              {name}
            </Typography>
          </div>
          <div className="flex flex-row justify-end items-center w-[48%]">
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12px",
                color: "#616161",
                lineHeight: "20px",
              }}
            >
              Work Notes
            </Typography>
            <FaCircle
              style={{
                height: "7px",
                width: "7px",
                color: "#C0C0C0",
                margin: "0px 6px",
              }}
            />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12px",
                color: "#616161",
                lineHeight: "18px",
              }}
            >
              12:45:00 | 3/20/17
            </Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            borderLeft: "2px solid #F9AA56",
            marginLeft: "33px",
          }}
        >
          <div
            style={{
              border: "1px solid #E5E5E5",
              borderRadius: "5px",
              padding: "16px 20px",
            }}
          >
            <div className="flex flex-row items-center justify-start mb-3">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                State
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between mb-3">
              <div
                className="flex flex-col justify-start items-start"
                style={{ width: "50%" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: "20px",
                    color: "#006DCC",
                  }}
                >
                  Previous :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: "400",
                    fontSize: "13px",
                    lineHeight: "20px",
                  }}
                >
                  New
                </Typography>
              </div>
              <div
                className="flex flex-col justify-start items-start"
                style={{ width: "50%" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: "20px",
                    color: "#006DCC",
                  }}
                >
                  New :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: "400",
                    fontSize: "13px",
                    lineHeight: "20px",
                  }}
                >
                  In-progress
                </Typography>
              </div>
            </div>
            <Divider className="mb-3" sx={{ color: "#F5F5F5" }} />
            <div className="flex flex-col justify-start">
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "18px",
                }}
              >
                Work Notes
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: "18px",
                }}
              >
                {workNotes}
              </Typography>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
