import React from 'react';
import { Link } from 'react-router-dom';
import PreviewIncident from './PreviewIncident';
import { extractContent } from '../../utils/PortalUtils';

const IncTableItem = ({ item, text }) => {
  const {
    inc_num,
    created_on,
    short_desc,
    caller,
    priority,
    state,
    category,
    assigned_to,
    assigned_grp,
    raised_by,
    resolved_on,
    sub_category,
  } = item;

  const regx = new RegExp(`${text}`, 'gi');

  const getStates = (currState) => {
    if (currState === 'New') {
      return (
        <div
          style={{ width: '180px', background: '#1e6091' }}
          className='me-4 text-white px-1 rounded fw-bold text-center'
          dangerouslySetInnerHTML={{
            __html: state
              ? state.replace(
                  regx,
                  (match) =>
                    `<mark style="color: #5DE23C; background:none">${match}</mark>`
                )
              : '-',
          }}></div>
      );
    } else if (currState === 'In Progress') {
      return (
        <div
          style={{ width: '180px' }}
          className='bg-warning me-4 text-white px-1 rounded fw-bold text-center'
          dangerouslySetInnerHTML={{
            __html: state
              ? state.replace(
                  regx,
                  (match) =>
                    `<mark style="color: #5DE23C; background:none">${match}</mark>`
                )
              : '-',
          }}></div>
      );
    } else if (currState === 'Resolved') {
      return (
        <div
          style={{ width: '180px' }}
          className='bg-success me-4 text-white px-1 rounded fw-bold text-center'
          dangerouslySetInnerHTML={{
            __html: state
              ? state.replace(
                  regx,
                  (match) =>
                    `<mark style="color: #5DE23C; background:none">${match}</mark>`
                )
              : '-',
          }}></div>
      );
    } else if (currState === 'On Hold') {
      return (
        <div
          style={{ width: '180px' }}
          className='bg-secondary me-4 text-white px-1 rounded fw-bold text-center'
          dangerouslySetInnerHTML={{
            __html: state
              ? state.replace(
                  regx,
                  (match) =>
                    `<mark style="color: #5DE23C; background:none">${match}</mark>`
                )
              : '-',
          }}></div>
      );
    } else if (currState === 'Cancelled') {
      return (
        <div
          style={{ width: '180px', background: 'rgba(255, 109, 0, 0.8)' }}
          className='me-4 text-white px-1 rounded fw-bold text-center'
          dangerouslySetInnerHTML={{
            __html: state
              ? state.replace(
                  regx,
                  (match) =>
                    `<mark style="color: #5DE23C; background:none">${match}</mark>`
                )
              : '-',
          }}></div>
      );
    } else if (currState === 'Closed') {
      return (
        <div
          style={{ width: '180px', background: 'rgba(214, 40, 40, 0.8)' }}
          className='me-4 text-white px-1 rounded fw-bold text-center'
          dangerouslySetInnerHTML={{
            __html: state
              ? state.replace(
                  regx,
                  (match) =>
                    `<mark style="color: #5DE23C; background:none">${match}</mark>`
                )
              : '-',
          }}></div>
      );
    }
  };
  let State = getStates(state);

  return (
    <tr className='border-bottom list newRow' style={{ maxHeight: '42px' }}>
      <td style={{ fontWeight: '500', width: '130px', borderBottom: 'none' }}>
        <Link
          to={`/update-incident/${inc_num}`}
          className='dark-blue'
          dangerouslySetInnerHTML={{
            __html: inc_num
              ? inc_num.replace(
                  regx,
                  (match) => `<mark style="background: #5DE23C">${match}</mark>`
                )
              : '-',
          }}></Link>
      </td>
      <td style={{ width: '150px', borderBottom: 'none' }}>
        <Link
          to={`/update-incident/${inc_num}`}
          className='table-gary'
          dangerouslySetInnerHTML={{
            __html: created_on
              ? created_on
                  .slice(0, -3)
                  .replace(
                    regx,
                    (match) =>
                      `<mark style="background: #5DE23C">${match}</mark>`
                  )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ borderBottom: 'none', width: '250px' }}>
        <Link
          className='table-gary py-0 '
          to={`/update-incident/${inc_num}`}
          style={{ width: '100%' }}>
          <div className='short-desc' style={{ width: '90%' }}>
            <p
              className='d-flex align-items-center text-truncate'
              dangerouslySetInnerHTML={{
                __html: short_desc
                  ? extractContent(short_desc).replace(
                      regx,
                      (match) =>
                        `<mark style="background: #5DE23C">${match}</mark>`
                    )
                  : '-',
              }}></p>
          </div>
        </Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link
          className='table-gary text-truncate'
          to={`/update-incident/${inc_num}`}
          style={{ width: '90%' }}
          dangerouslySetInnerHTML={{
            __html: raised_by
              ? raised_by.profile.full_name.replace(
                  regx,
                  (match) =>
                    `<mark className="text-truncate "style="background: #5DE23C">${match}</mark>`
                )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link
          className='table-gary text-truncate'
          to={`/update-incident/${inc_num}`}
          style={{ width: '90%' }}
          dangerouslySetInnerHTML={{
            __html: caller
              ? caller.profile.full_name.replace(
                  regx,
                  (match) =>
                    `<mark className="text-truncate "style="background: #5DE23C">${match}</mark>`
                )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link
          className='table-gary'
          to={`/update-incident/${inc_num}`}
          dangerouslySetInnerHTML={{
            __html: priority
              ? priority.replace(
                  regx,
                  (match) => `<mark style="background: #5DE23C">${match}</mark>`
                )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow pe-2'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link className='table-gary' to={`/update-incident/${inc_num}`}>
          {state ? State : '-'}
        </Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '150px', borderBottom: 'none' }}>
        <Link
          className='table-gary'
          to={`/update-incident/${inc_num}`}
          dangerouslySetInnerHTML={{
            __html: resolved_on
              ? resolved_on
                  .slice(0, -3)
                  .replace(
                    regx,
                    (match) =>
                      `<mark style="background: #5DE23C">${match}</mark>`
                  )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link
          className='table-gary text-truncate'
          to={`/update-incident/${inc_num}`}
          style={{ width: '90%' }}
          dangerouslySetInnerHTML={{
            __html: category
              ? category.name.replace(
                  regx,
                  (match) => `<mark style="background: #5DE23C">${match}</mark>`
                )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link
          className='table-gary text-truncate'
          to={`/update-incident/${inc_num}`}
          style={{ width: '90%' }}
          dangerouslySetInnerHTML={{
            __html: sub_category
              ? sub_category.name.replace(
                  regx,
                  (match) => `<mark style="background: #5DE23C">${match}</mark>`
                )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link
          className='table-gary text-truncate'
          to={`/update-incident/${inc_num}`}
          style={{ width: '90%' }}
          dangerouslySetInnerHTML={{
            __html: assigned_grp
              ? assigned_grp.name.replace(
                  regx,
                  (match) => `<mark style="background: #5DE23C">${match}</mark>`
                )
              : '-',
          }}></Link>
      </td>
      <td
        className='flex-col-grow'
        style={{ width: '200px', borderBottom: 'none' }}>
        <Link
          className='table-gary text-truncate'
          to={`/update-incident/${inc_num}`}
          style={{ width: '90%' }}
          dangerouslySetInnerHTML={{
            __html:
              assigned_to && assigned_to.profile
                ? assigned_to.profile.full_name.replace(
                    regx,
                    (match) =>
                      `<mark style="background: #5DE23C">${match}</mark>`
                  )
                : '-',
          }}></Link>
      </td>
      <td
        className='table-gary p-2'
        width='80px'
        style={{ borderBottom: 'none' }}>
        <PreviewIncident item={item} />
      </td>
    </tr>
  );
};

export default IncTableItem;
