import React, { Fragment, useContext, useEffect, useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from '../../hooks';
import {columns} from './RequestsColumns'
import RequestCard from "./RequestCard";
import { useMediaQuery } from "@mui/material";
import RequestCard2 from "./RequestCard2";
import { PageHeader } from "../../components/layouts/pageHeader";
const ReqResolved = ({ history }) => {
  useEffect(() => {
    if (
      !JSON.parse(localStorage.getItem("data")).in_assets_team &&
      JSON.parse(localStorage.getItem("data")).user_role !== "Admin"
    ) {
      history.push("/view-status");
    }else{
      document.title = "All Closed Complete Requests";
      window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Reuquest Management',
        page_location: 'AllClosedCompleteRequests',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const { data: AllClosedRequests } = useGetAll({
    key: "/requests/complete/v2",
    params: {
      page: 0,
      limit: 25,
    },
  });
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <Navbar heading="All Closed  Requests" subheading="" />
      <PageHeader header={"All Closed Requests"} className="mb-4" />
      <section className="main-container">
      {isMobile ? (
        AllClosedRequests?.rows?.map((row, index) => <RequestCard2 key={index} row={row}/>)

       ) :(
        <DataGrid
          columns={columns}
          url="/requests/complete/v2"
          rows={AllClosedRequests}
          getRowId={(row) => row?.req_num + row?.id}
        />)}
      </section>
    </>
  );
};

export default React.memo(ReqResolved);
