import { Link } from "react-router-dom";
import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import { RequestPreview } from "../../components/requests/RequestPreview";

export const columns = [
  {
    headerName: "Number",
    field: "req_num",
    // minWidth: 130,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Link to={`/update-request/${row?.req_num}`}>
          <div className="text-black underline">{row?.req_num}</div>
        </Link>
      );
    },
  },
  {
    headerName: "Product",
    field: "product",
    // minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => {
      if (row?.product?.name) {
        return <div>{row?.product?.name}</div>;
      } else {
        return <div>-</div>;
      }
    },
  },
  {
    headerName: "Short Description",
    field: "short_desc",
    // minWidth: 250,
    flex: 1.5,
  },
  {
    headerName: "Raised By",
    field: "raised_by",
    // minWidth: 180,
    flex: 1,
    renderCell: ({ row }) => {
      if (row?.raised_by?.profile?.full_name) {
        return <div>{row?.raised_by?.profile?.full_name}</div>;
      } else {
        return <div>-</div>;
      }
    },
  },
  {
    headerName: "Requested For",
    field: "caller",
    // minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => {
      if (row?.requested_by?.profile?.full_name) {
        return <div>{row?.requested_by?.profile?.full_name}</div>;
      } else {
        return <div>-</div>;
      }
    },
  },

  {
    headerName: "Status",
    field: "state",
    // minWidth: 130,
    flex: 1.2,
    // headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <StatusCard
        text={row?.state}
        variant={
          row?.state?.toLowerCase()?.replace(/ /g, "_").replace(/\//g, "_") ||
          "in_progress"
        }
      />
    ),
  },
  //   {
  //     headerName: "Opened",
  //     field: "created_on",
  //     minWidth: 200,
  //   },

  //   {
  //     headerName: "Closed On",
  //     field: "completed_on",
  //     minWidth: 180,
  //     renderCell: ({ row }) => {
  //       if (row?.completed_on) {
  //         return <div>{row?.completed_on}</div>;
  //       } else {
  //         return <div>-</div>;
  //       }
  //     },
  //   },
  //   {
  //     headerName: "Category",
  //     field: "category",
  //     minWidth: 150,
  //     renderCell: ({ row }) => {
  //       if (row?.product?.category?.name) {
  //         return <div>{row?.product?.category?.name}</div>;
  //       } else {
  //         return <div>-</div>;
  //       }
  //     },
  //   },
  //   {
  //     headerName: "Sub-Category",
  //     field: "sub_category",
  //     minWidth: 160,
  //     renderCell: ({ row }) => {
  //       if (row?.product?.sub_category?.sc_name) {
  //         return <div>{row?.product?.sub_category?.sc_name}</div>;
  //       } else {
  //         return <div>-</div>;
  //       }
  //     },
  //   },

  {
    headerName: "Actions",
    field: "actions",
    // minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => <RequestPreview data={row} />,
  },
];
