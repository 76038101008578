import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import SettingSvg from "../../../public/img/setting.svg";

function AdminPanel({ pathname }) {
  return (
    <div className='accordion accordion-flush' id='accordionFlushExample'>
      <div className='accordion-item accordion-item__custom'>
        <h2
          className='accordion-header accordion-header__custom'
          id='flush-headingTwo'
        >
          <button
            className='accordion-button accordion-button__custom collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#flush-collapseTwo'
            aria-expanded='false'
            aria-controls='flush-collapseTwo'
          >
            <img src={SettingSvg} alt='' />
            <span>Settings</span>
          </button>
        </h2>
        <div
          id='flush-collapseTwo'
          className='accordion-collapse accordion-collapse__custom collapse'
          aria-labelledby='flush-headingTwo'
          data-bs-parent='#accordionFlushExample'
        >
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/unapproved-users'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/unapproved-users" && ">"} Unapproved Users
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/approved-users'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/approved-users" && ">"} Approved Users
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/workflow-configuration'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/workflow-configuration" && ">"} Workflow
                Configuration
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/sla-configuration'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/sla-configuration" && ">"} SLA Configuration
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/email-config'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/email-config" && ">"} Email Configuration
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/notifications'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/notifications" && ">"} Notifications
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/request-approval'
            >
              <li className='ms-4 text-truncate fw-normal'>
                -{pathname === "/request-approval" && ">"} Skip Level Management
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/asset-management'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/asset-management" && ">"} Asset Management
              </li>
            </Link>
          </h2>
          <Fragment>
            <h2 className='accordion-header'>
              <Link
                className='accordion-button accordion-button__custom-item accordion-button__custom'
                to='/category'
              >
                <li className='ms-4 fw-normal'>
                  -{pathname === "/category" && ">"} Category
                </li>
              </Link>
            </h2>
            <h2 className='accordion-header'>
              <Link
                className='accordion-button accordion-button__custom-item accordion-button__custom'
                to='/departments'
              >
                <li className='ms-4 fw-normal'>
                  -{pathname === "/departments" && ">"} Departments
                </li>
              </Link>
            </h2>
            {/* <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/configuration-item'>
              <li className='ms-4 fw-normal'>
                -{pathname === '/configuration-item' && '>'} Configuration Item
              </li>
            </Link>
          </h2> */}
          </Fragment>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/product'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/product" && ">"} Products
              </li>
            </Link>
          </h2>
          <h2 className='accordion-header'>
            <Link
              className='accordion-button accordion-button__custom-item accordion-button__custom'
              to='/service'
            >
              <li className='ms-4 fw-normal'>
                -{pathname === "/service" && ">"} Services
              </li>
            </Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
