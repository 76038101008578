import React, { useEffect, useState } from "react";
import TaskLabel from "../TaskLabel";
import { url } from "../../../urls";
import serverAPI from "../../../utils/serverAPI";

const src =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLeqsbTn6eqpr7PJzc/j5ebf4eLZ3N2wtrnBxsjN0NLGysy6v8HT1tissra8wMNxTKO9AAAFDklEQVR4nO2d3XqDIAxAlfivoO//tEOZWzvbVTEpic252W3PF0gAIcsyRVEURVEURVEURVEURVEURVEURVEURVEURVEURflgAFL/AirAqzXO9R7XNBVcy9TbuMHmxjN6lr92cNVVLKEurVfK/zCORVvW8iUBnC02dj+Wpu0z0Y6QlaN5phcwZqjkOkK5HZyPAjkIjSO4fIdfcOwFKkJlX4zPu7Ha1tIcwR3wWxyFhRG6g4Je0YpSPDJCV8a2Sv2zd1O1x/2WMDZCwljH+clRrHfWCLGK8REMiql//2si5+DKWKcWeAGcFMzzNrXC/0TUwQ2s6+LhlcwjTMlYsUIQzPOCb7YBiyHopyLXIEKPEkI/TgeuiidK/R9FniUDOjRDpvm0RhqjMyyXNjDhCfIMYl1gGjIMIuYsnGEYRMRZOMMunaLVwpWRW008v6fYKDIzxCwVAeNSO90BJW6emelYBRF/kHpYGVaoxTDAaxOFsfP9y8hpJ4xd7gOcij7JNGQ1EYFgkPJa1jQEiYZXRaRINKxSDUW9n+FT82lSKadkiru9/4XPqSLWOekGPoY05TAvLm9orm+YWuwHoBHkZKijNBJGmeb61eL6Ff/6q7bLr7yvv3vKGhpDRjvgjGaPz+gUg6YgcvpyAR2FIZ9U6nEEyZRTovmEU32KichpGn7C17XrfyH9gK/c0CMP05HZIM2uf9sEveizKveBy9/6Qt7o89ne33D525cfcIMW6ab+TMEukQbQbu+xu7X3A9bChmWaCeAkG17bpntwXgWxHaMzGPmUaR5dQZiKqRVeUZ3047fi3nAu28h4CHxCsZAgmEH8Y27jJAhm8c+5RQzRQNVGhVFSfxOYIjp/pP7RxzjevYXVGf4eLt+BJ1vCuLuLkrgABgCGXZ2wik5uty+oBvNirI6mkzhAf4Gsb58Hcm67Jzd+KwD10BYPLL3e0MjvKrgAULnOfveF/O4N2Xb9BZom3gJes3F9X5Zze8/6Yt09b4CrqsEjUv8oFBaR2rl+6CZr2xVrp24o/WitBKuGrrpl1+bFkmK2qXTON4VpbdfLa7o7y/WdLxG7lm2Lqh2clOwTegbvc/vj2U78CwhA87Bn8G5Nk3eOb0Nsr9flz3sG78UUtue4kpv1xvjg3TMay62BMlTlP+vrOMnJsRmt/ze0jsfkPPYdAH57hK+34PeOyc8XIXu5xT2HsUkdZz+adwg8HGFfQ3K5jtDvbUiO4Di9/ywHGrL88pDizZ++oTp+an+SMX/ndymUCwmHMdO7yuOx83pUx/eEMU0AvxWndwgidAqOZ8ypCwdEfvvEo6D9HwpA8wzvmOJEqAg9ySu8g4x0Hb9hSB/BANEKJ+LbPBU0lzbAJs4xt1AoshKkUGQmiH8/jJ0gdhTTLmSegHlPE0oOdXALnqDjKYh3px//fSgSWG8UqfrrIICzYYSJXRr9BSPbpNzw7gBjKjKOYI7ReIGqQRIap5+5MdjyvuDkExvGeXSlONWZAP3/AZBwJohU7QJRGU+cTVH18ELmRPNBmibW6MT/k1b0XhdkRBvyT6SB6EYv/GvhSmRNpGngRULsAlxMCGNXp7w3FfdEbTEEDdLI9TdIKRUzUesa3I461ER8cpNT7gMRhpKmYVS9ELOgCUQsa4SsulciKiLbY+AnHD8cpuhISsnxpamI84sbDq9qYJgf8wiiOBrC7Ml7M7ZECCqKoiiKoiiKoiiKoijv5AvJxlZRyNWWLwAAAABJRU5ErkJggg==";
const UserCard = ({ user, removeUserHandler }) => {
  return (
    <>
      <div className="d-flex  align-items-center w-100">
        <img className="rounded-circle" width={"30"} src={src} alt="" />
        <span style={{ marginLeft: "1rem", fontSize: "16px", width: "100%" }}>
          {user?.user?.profile?.full_name || user?.full_name || "Name Here"}
        </span>
        <span
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeUserHandler(user.user.id || user.user)}
        >
          Remove
        </span>
      </div>
      <hr />
    </>
  );
};

const TaskDetailsUserList = ({ id, onChange, userList = [], handleDelete }) => {
  const [userList_server, setUserList_server] = useState([]);
  useEffect(() => {
    const dept_id = JSON.parse(localStorage.getItem("data"))?.department;
    const user_id = JSON.parse(localStorage.getItem("data"))?.USER_ID;
    // console.log(user_id);
    serverAPI.get(url + "/accounts/dept/" + dept_id).then((res) => {
      // console.log(res.data, "userslist");
      setUserList_server(res.data.filter((u) => u.user !== user_id));
    });
  }, []);

  // const [userList, setUserList] = useState(
  //   users?.filter((u) => !u.is_owner) || []
  // );

  // useEffect(() => {
  //   setUserList([]);
  //   setUserList(users?.filter((u) => !u.is_owner) || []);
  // }, [users]);

  // const addUserHandler = (user) => setUserList([...new Set(...userList, user)]);
  // const addUserHandler = (user) => {
  //   // todo:set not working
  //   setUserList((list) => [...new Set([...list, user])]);
  //   serverAPI.post(url + "/tasks/user/", {
  //     task: id,
  //     user: user.user,
  //   });
  // };

  const removeUserHandler = (user_id) => {
    // setUserList((users) =>
    //   users.filter((u) => u.user.id !== user_id || u.user.id !== user_id)
    // );
    serverAPI.delete(url + "/tasks/user/" + id + "/" + user_id, {
      task: id,
      user: user_id,
    });
  };

  return (
    <div className="add-user-wrapper">
      <TaskLabel
        icon={<i class="fal fa-plus fa-lg"></i>}
        text={
          <>
            <select
              // class="form-select"
              aria-label="Default select example"
              style={{
                outline: "none",
                border: "none",
                marginBottom: "0.5rem",
                padding: "0.rem 0.5rem",
                width: "300px",
                marginLeft: "-0.5rem",
              }}
              onChange={(e) => {
                const user = JSON.parse(e.target.value);
                // addUserHandler(user);
                onChange(user);
              }}
              value={"Set User"}
            >
              <option
                value="0"
                style={{
                  padding: "1rem",
                  height: "40px",
                }}
              >
                Add Users
              </option>{" "}
              {userList_server?.map((user) => (
                <option
                  value={JSON.stringify(user)}
                  key={user.id}
                  style={{
                    padding: "1rem",
                    height: "40px",
                  }}
                >
                  {user.full_name}
                </option>
              ))}
            </select>
          </>
        }
      />

      {userList?.length !== 0 && <div style={{ marginBottom: "0.5rem" }} />}
      {userList?.map((user) => (
        <UserCard
          key={user.id}
          user={user}
          // removeUserHandler={removeUserHandler}
          removeUserHandler={() => handleDelete(user)}
        />
      ))}
    </div>
  );
};

export default TaskDetailsUserList;
