import React, { useEffect } from "react";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import Navbar from "../../components/layouts/Navbar";
import { columns } from "./IncidentColumns";
import queryString from "query-string";

const FilterIncident = ({ history, match, location }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role === "User") {
      history.push("/view-status");
    } else {
      document.title = "All Incidents";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page_data: {
          country: 'India',
          language: 'en',
          page_url: window.location.href,
          page_category: 'Incident Management',
          page_location: 'AllIncidents',
          page_referrer: document.referrer,
          page_title: document.title,
          region: 'Pune',
          site_section: 'Support',
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: 'User',
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        }
      });
      console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const parsed = queryString.parse(location.search);
  const state = parsed.state ? parsed.state : history.push("/page-not-found");

  const { data } = useGetAll({
    key: `/incidents/piecharthyperlinks/${state}`,
    params: {
      page: 0,
      limit: 25,
    },
  });

  return (
    <>
      <Navbar
        heading={`${
          state ? state.replace("-", " ").toUpperCase() : ""
        } INCIDENTS`}
        subheading=""
      />
      <section className="main-container">
        <DataGrid
          columns={columns}
          url={`/incidents/piecharthyperlinks/${state}`}
          rows={data}
          getRowId={(row) => row?.inc_num}
        />
      </section>
    </>
  );
};

export default FilterIncident;
