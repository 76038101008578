export const drawerWidth = 280;
export const colors = {
  // primary: { dark: "#6CBE45", light: "#EEFBDB" },
  // primary: { dark: "#109CF1", light: "#D7EDFF" },
  primary: { dark: "#0F62FE", light: "#EDF5FF" },
  secondary: { dark: "#192A3E", light: "#EEF0F2" },
  tertiary: { dark: "#78909C", light: "#E7EDF2", main: "#109cf185" }, //light"#F2F5F7" //light: #e3e3e3
  text: { dark: "#4D4D4D", light: "#616161", main: "#828282" },
  status: {
    initiated: {
      text: "#1ABBB9",
      background: "#EBFDFF",
    },
    // on_going: {
    //   text: "#1ABBB9",
    //   background: "#EBFDFF",
    // },
    new: {
      text: "#1ABBB9",
      background: "#EBFDFF",
    },

    in_progress: {
      text: "#FFB508",
      background: "#FEFFE5",
    },
    pending_approval: {
      text: "#839192",
      background: "#E5E8E8",
    },
    closed_complete: {
      text: "#18AB56",
      background: "#E8FFF4",
    },
    awaiting_replace_return: {
      text: "#EB28EE",
      background: "#F8D2F9",
    },
    completed: {
      text: "#6E1EAF",
      background: "#E9D3FB",
    },
    resolved: {
      text: "#18AB56",
      background: "#E8FFF4",
    },
    on_hold: {
      text: "#6A6A66",
      background: "#F0F0EB",
    },
    cancelled: {
      text: "#EB5757",
      background: "#FFF0F0",
    },
    closed: {
      text: "#6E1EAF",
      background: "#E9D3FB",
    },
  },
};

// export const menus = {
//   User: [
//     {
//       name: "Incident Management",
//       icon: MdOutlineReportProblem,
//       childrens: [
//         {
//           name: "Incident Overview",
//           path: "/incident-overview",
//         },
//         {
//           name: "Create New",
//           path: "/create-incident",
//         },
//         {
//           name: "All Incidents",
//           path: "/all-incidents",
//         },
//         {
//           name: "Search Incidents",
//           path: "/search-incidents",
//         },
//         {
//           name: "Incidents Assigned to me",
//           path: "/incidents-assigned-to-me",
//         },
//         {
//           name: "Incidents Raised By Me",
//           path: "/incidents-raised-by-me",
//         },
//         {
//           name: "Open-Unassigned Incidents",
//           path: "/open-unassigned-incidents",
//         },
//         {
//           name: "Open Incidents",
//           path: "/open-incidents",
//         },
//         {
//           name: "Resolved Incidents",
//           path: "/resolved-incidents",
//         },
//       ],
//     },
//     {
//       name: "Request Management",
//       icon: BiGitPullRequest,
//       childrens: [
//         {
//           name: "Request Overview",
//           path: "/request-overview",
//         },
//         {
//           name: "Create Service Request",
//           path: "/request-services",
//         },
//         {
//           name: "Create Product Request",
//           path: "/request-products",
//         },

//         {
//           name: "Requests Raised By Me",
//           path: "/requests-raised-by-me",
//         },
//         {
//           name: "First Approval Pending",
//           path: "/first-approval-pending-requests",
//         },
//         {
//           name: "Second Approval Pending",
//           path: "/second-approval-pending-requests",
//         },
//         {
//           name: "Search Requests",
//           path: "/search-requests",
//         },
//         {
//           name: "Requests For Return/Replace",
//           path: "/requests-for-return-and-replace",
//         },
//         {
//           name: "All Requests",
//           path: "/all-requests",
//         },
//         {
//           name: "Open Requests",
//           path: "/open-requests",
//         },
//         {
//           name: "Closed Complete Requests",
//           path: "/closed-complete-requests",
//         },
//         {
//           name: "Other Product/Service Requests",
//           path: "/other-requests",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//     {
//       name: "Task Management",
//       icon: BiTask,
//       childrens: [
//         {
//           name: "My Tasks",
//           path: "/my-tasks",
//         },
//         {
//           name: "Assigned Tasks",
//           path: "/assigned-tasks",
//         },
//         {
//           name: "All Tasks",
//           path: "/all-tasks",
//         },
//       ],
//     },
//     {
//       name: "Settings",
//       icon: FiSettings,
//       childrens: [
//         {
//           name: "Unapproved Users",
//           path: "/unapproved-users",
//         },
//         {
//           name: "Approved Users",
//           path: "/approved-users",
//         },
//         {
//           name: "Workflow",
//           path: "/workflow-configuration",
//         },
//         {
//           name: "SLA Configuration",
//           path: "/sla-configuration",
//         },
//         {
//           name: "Email Configuration",
//           path: "/email-config",
//         },
//         {
//           name: "Notifications",
//           path: "/notifications",
//         },
//         {
//           name: "Skip Level Management",
//           path: "/request-approval",
//         },
//         {
//           name: "Asset Management",
//           path: "/asset-management",
//         },
//         {
//           name: "Category",
//           path: "/category",
//         },
//         {
//           name: "Departments",
//           path: "/departments",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//   ],
//   Service: [
//     {
//       name: "Incident Management",
//       icon: MdOutlineReportProblem,
//       childrens: [
//         {
//           name: "Incident Overview",
//           path: "/incident-overview",
//         },
//         {
//           name: "Create New",
//           path: "/create-incident",
//         },
//         {
//           name: "All Incidents",
//           path: "/all-incidents",
//         },
//         {
//           name: "Search Incidents",
//           path: "/search-incidents",
//         },
//         {
//           name: "Incidents Assigned to me",
//           path: "/incidents-assigned-to-me",
//         },
//         {
//           name: "Incidents Raised By Me",
//           path: "/incidents-raised-by-me",
//         },
//         {
//           name: "Open-Unassigned Incidents",
//           path: "/open-unassigned-incidents",
//         },
//         {
//           name: "Open Incidents",
//           path: "/open-incidents",
//         },
//         {
//           name: "Resolved Incidents",
//           path: "/resolved-incidents",
//         },
//       ],
//     },
//     {
//       name: "Request Management",
//       icon: BiGitPullRequest,
//       childrens: [
//         {
//           name: "Request Overview",
//           path: "/request-overview",
//         },
//         {
//           name: "Create Service Request",
//           path: "/request-services",
//         },
//         {
//           name: "Create Product Request",
//           path: "/request-products",
//         },

//         {
//           name: "Requests Raised By Me",
//           path: "/requests-raised-by-me",
//         },
//         {
//           name: "First Approval Pending",
//           path: "/first-approval-pending-requests",
//         },
//         {
//           name: "Second Approval Pending",
//           path: "/second-approval-pending-requests",
//         },
//         {
//           name: "Search Requests",
//           path: "/search-requests",
//         },
//         {
//           name: "Requests For Return/Replace",
//           path: "/requests-for-return-and-replace",
//         },
//         {
//           name: "All Requests",
//           path: "/all-requests",
//         },
//         {
//           name: "Open Requests",
//           path: "/open-requests",
//         },
//         {
//           name: "Closed Complete Requests",
//           path: "/closed-complete-requests",
//         },
//         {
//           name: "Other Product/Service Requests",
//           path: "/other-requests",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//     {
//       name: "Task Management",
//       icon: BiTask,
//       childrens: [
//         {
//           name: "My Tasks",
//           path: "/my-tasks",
//         },
//         {
//           name: "Assigned Tasks",
//           path: "/assigned-tasks",
//         },
//         {
//           name: "All Tasks",
//           path: "/all-tasks",
//         },
//       ],
//     },
//     {
//       name: "Settings",
//       icon: FiSettings,
//       childrens: [
//         {
//           name: "Unapproved Users",
//           path: "/unapproved-users",
//         },
//         {
//           name: "Approved Users",
//           path: "/approved-users",
//         },
//         {
//           name: "Workflow",
//           path: "/workflow-configuration",
//         },
//         {
//           name: "SLA Configuration",
//           path: "/sla-configuration",
//         },
//         {
//           name: "Email Configuration",
//           path: "/email-config",
//         },
//         {
//           name: "Notifications",
//           path: "/notifications",
//         },
//         {
//           name: "Skip Level Management",
//           path: "/request-approval",
//         },
//         {
//           name: "Asset Management",
//           path: "/asset-management",
//         },
//         {
//           name: "Category",
//           path: "/category",
//         },
//         {
//           name: "Departments",
//           path: "/departments",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//   ],
//   Agent: [
//     {
//       name: "Incident Management",
//       icon: MdOutlineReportProblem,
//       childrens: [
//         {
//           name: "Incident Overview",
//           path: "/incident-overview",
//         },
//         {
//           name: "Create New",
//           path: "/create-incident",
//         },
//         {
//           name: "All Incidents",
//           path: "/all-incidents",
//         },
//         {
//           name: "Search Incidents",
//           path: "/search-incidents",
//         },
//         {
//           name: "Incidents Assigned to me",
//           path: "/incidents-assigned-to-me",
//         },
//         {
//           name: "Incidents Raised By Me",
//           path: "/incidents-raised-by-me",
//         },
//         {
//           name: "Open-Unassigned Incidents",
//           path: "/open-unassigned-incidents",
//         },
//         {
//           name: "Open Incidents",
//           path: "/open-incidents",
//         },
//         {
//           name: "Resolved Incidents",
//           path: "/resolved-incidents",
//         },
//       ],
//     },
//     {
//       name: "Request Management",
//       icon: BiGitPullRequest,
//       childrens: [
//         {
//           name: "Request Overview",
//           path: "/request-overview",
//         },
//         {
//           name: "Create Service Request",
//           path: "/request-services",
//         },
//         {
//           name: "Create Product Request",
//           path: "/request-products",
//         },

//         {
//           name: "Requests Raised By Me",
//           path: "/requests-raised-by-me",
//         },
//         {
//           name: "First Approval Pending",
//           path: "/first-approval-pending-requests",
//         },
//         {
//           name: "Second Approval Pending",
//           path: "/second-approval-pending-requests",
//         },
//         {
//           name: "Search Requests",
//           path: "/search-requests",
//         },
//         {
//           name: "Requests For Return/Replace",
//           path: "/requests-for-return-and-replace",
//         },
//         {
//           name: "All Requests",
//           path: "/all-requests",
//         },
//         {
//           name: "Open Requests",
//           path: "/open-requests",
//         },
//         {
//           name: "Closed Complete Requests",
//           path: "/closed-complete-requests",
//         },
//         {
//           name: "Other Product/Service Requests",
//           path: "/other-requests",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//     {
//       name: "Task Management",
//       icon: BiTask,
//       childrens: [
//         {
//           name: "My Tasks",
//           path: "/my-tasks",
//         },
//         {
//           name: "Assigned Tasks",
//           path: "/assigned-tasks",
//         },
//         {
//           name: "All Tasks",
//           path: "/all-tasks",
//         },
//       ],
//     },
//     {
//       name: "Settings",
//       icon: FiSettings,
//       childrens: [
//         {
//           name: "Unapproved Users",
//           path: "/unapproved-users",
//         },
//         {
//           name: "Approved Users",
//           path: "/approved-users",
//         },
//         {
//           name: "Workflow",
//           path: "/workflow-configuration",
//         },
//         {
//           name: "SLA Configuration",
//           path: "/sla-configuration",
//         },
//         {
//           name: "Email Configuration",
//           path: "/email-config",
//         },
//         {
//           name: "Notifications",
//           path: "/notifications",
//         },
//         {
//           name: "Skip Level Management",
//           path: "/request-approval",
//         },
//         {
//           name: "Asset Management",
//           path: "/asset-management",
//         },
//         {
//           name: "Category",
//           path: "/category",
//         },
//         {
//           name: "Departments",
//           path: "/departments",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//   ],
//   Admin: [
//     {
//       name: "Incident Management",
//       icon: MdOutlineReportProblem,
//       childrens: [
//         {
//           name: "Incident Overview",
//           path: "/incident-overview",
//         },
//         {
//           name: "Create New",
//           path: "/create-incident",
//         },
//         {
//           name: "All Incidents",
//           path: "/all-incidents",
//         },
//         {
//           name: "Search Incidents",
//           path: "/search-incidents",
//         },
//         {
//           name: "Incidents Assigned to me",
//           path: "/incidents-assigned-to-me",
//         },
//         {
//           name: "Incidents Raised By Me",
//           path: "/incidents-raised-by-me",
//         },
//         {
//           name: "Open-Unassigned Incidents",
//           path: "/open-unassigned-incidents",
//         },
//         {
//           name: "Open Incidents",
//           path: "/open-incidents",
//         },
//         {
//           name: "Resolved Incidents",
//           path: "/resolved-incidents",
//         },
//       ],
//     },
//     {
//       name: "Request Management",
//       icon: BiGitPullRequest,
//       childrens: [
//         {
//           name: "Request Overview",
//           path: "/request-overview",
//         },
//         {
//           name: "Create Service Request",
//           path: "/request-services",
//         },
//         {
//           name: "Create Product Request",
//           path: "/request-products",
//         },

//         {
//           name: "Requests Raised By Me",
//           path: "/requests-raised-by-me",
//         },
//         {
//           name: "First Approval Pending",
//           path: "/first-approval-pending-requests",
//         },
//         {
//           name: "Second Approval Pending",
//           path: "/second-approval-pending-requests",
//         },
//         {
//           name: "Search Requests",
//           path: "/search-requests",
//         },
//         {
//           name: "Requests For Return/Replace",
//           path: "/requests-for-return-and-replace",
//         },
//         {
//           name: "All Requests",
//           path: "/all-requests",
//         },
//         {
//           name: "Open Requests",
//           path: "/open-requests",
//         },
//         {
//           name: "Closed Complete Requests",
//           path: "/closed-complete-requests",
//         },
//         {
//           name: "Other Product/Service Requests",
//           path: "/other-requests",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//     {
//       name: "Task Management",
//       icon: BiTask,
//       childrens: [
//         {
//           name: "My Tasks",
//           path: "/my-tasks",
//         },
//         {
//           name: "Assigned Tasks",
//           path: "/assigned-tasks",
//         },
//         {
//           name: "All Tasks",
//           path: "/all-tasks",
//         },
//       ],
//     },
//     {
//       name: "Settings",
//       icon: FiSettings,
//       childrens: [
//         {
//           name: "Unapproved Users",
//           path: "/unapproved-users",
//         },
//         {
//           name: "Approved Users",
//           path: "/approved-users",
//         },
//         {
//           name: "Workflow",
//           path: "/workflow-configuration",
//         },
//         {
//           name: "SLA Configuration",
//           path: "/sla-configuration",
//         },
//         {
//           name: "Email Configuration",
//           path: "/email-config",
//         },
//         {
//           name: "Notifications",
//           path: "/notifications",
//         },
//         {
//           name: "Skip Level Management",
//           path: "/request-approval",
//         },
//         {
//           name: "Asset Management",
//           path: "/asset-management",
//         },
//         {
//           name: "Category",
//           path: "/category",
//         },
//         {
//           name: "Departments",
//           path: "/departments",
//         },
//         {
//           name: "Products",
//           path: "/product",
//         },
//         {
//           name: "Services",
//           path: "/service",
//         },
//       ],
//     },
//   ],
// };
