import React from "react";
import SelectFieldNF from "../../components/layouts/forms/NonFormik/SelectFieldNF";
import { Button, Checkbox, Grid, IconButton } from "@mui/material";
import {
  AddOutlined,
  RemoveCircleOutline,
  AddCircleOutline,
} from "@mui/icons-material";
import InputFieldNF from "../layouts/forms/NonFormik/InputFieldNF";
import InputLabel from "../../components/layouts/commonLayouts/InputLabel";

const ActionsForm = ({
  actions,
  setActions,
  actionsData,
  setActionsData,
  handleActionSubmit,
  approvers,
  setApprovers,
}) => {
  const incident = actionsData?.table === "Incident";
  const ConditionFields = (key) => {
    let obj = {
      IsCaller: () => {
        return {
          url: "/accounts/usernamewithid",
          label: "full_name",
          value: "id",
        };
      },
      "Raised By": () => {
        return {
          url: "/accounts/usernamewithid",
          label: "full_name",
          value: "id",
        };
      },
      Category: () => {
        return {
          url: `/portal/categories/0`,
          label: "name",
          value: "id",
        };
      },
      "Sub Category": () => {
        return {
          url: `/portal/get-all-subcategories`,
          label: "name",
          value: "id",
        };
      },
      service: () => "text",
      contact_type: () => "text",
      contact_email: () => "text",
      contact_phone: () => "text",
      Status: () =>
        incident
          ? ["New", "In Progress", "On Hold", "Resolved", "Closed", "Cancelled"]
          : [
              "Pending Approval",
              "In Progress",
              "Closed Complete",
              "Cancelled",
              "Awaiting Replace/Return",
            ],
      impact: () => ["High", "Medium", "Low"],
      urgency: () => ["High", "Medium", "Low"],
      assigned_grp: () => {
        return { url: `/portal/departments/0`, label: "name", value: "id" };
      },
      assigned_to: () => {
        return {
          url: `/portal/usersbysubs-workflow`,
          label: "name",
          value: "id",
        };
      },
      "Short Description": () => "text",
      "Requested By": () => {
        return {
          url: "/accounts/usernamewithid",
          label: "full_name",
          value: "id",
        };
      },
      "Product/Service": () => {
        return {
          url: "/requests/product/get-all",
          label: "name",
          value: "id",
        };
      },
      Price: () => "text",
      Quantity: () => "text",
      "Is Open": () => ["True", "False"],
      Justification: () => "text",
      Approval: () => [
        "Requested",
        "1st level approval",
        "Approved",
        "Rejected",
        "No Longer Required",
        "Duplicate",
      ],
      "Comments 1": () => "text",
      "Comments 2": () => "text",
    };
    return obj[key] ? obj[key]() : [];
  };

  const conditions = [
    "IsCaller",
    "Raised By",
    "Category",
    "Sub Category",
    "service",
    "contact_type",
    "contact_email",
    "contact_phone",
    "Status",
    "impact",
    "urgency",
    "assigned_grp",
    "assigned_to",
    "Short Description",
  ];
  const requestConditions = [
    "Raised By",
    "Requested By",
    "Product/Service",
    "Price",
    "Status",
    "Approval",
    "Quantity",
    "Is Open",
    "Short Description",
    "Justification",
    "Comments 1",
    "Comments 2",
  ];

  const handleChange = (array, id, name, value) => {
    return array.map((item) =>
      item.id === id
        ? {
            ...item,
            [name]: value,
          }
        : item
    );
  };

  return (
    <>
      <Grid container direction={"column"}>
        <h5 className="mb-4">ACTIONS</h5>

        <Grid container xs={6} className="px-2 mb-3">
          <Grid item xs={4}>
            <InputLabel>Action</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectFieldNF
              name="action_type"
              value={actionsData?.action_type ? actionsData?.action_type : ""}
              onChange={(value, reason) => {
                if (reason === "clear") {
                  setActionsData({
                    table: "",
                  });
                }
                setActionsData((prev) => ({ ...prev, action_type: value }));
              }}
              options={
                actionsData?.is_ad_related
                  ? ["Grant Access", "Revoke Access"]
                  : ["Update"]
              }
            />
          </Grid>
        </Grid>

        {!actionsData?.is_ad_related ? (
          <>
            <Grid container xs={6} className="px-2 mb-3">
              <Grid item xs={4}>
                <InputLabel required> Module </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <SelectFieldNF
                  name="table"
                  disable={!actionsData?.action_type}
                  value={actionsData?.table ? actionsData?.table : ""}
                  onChange={(value, reason) => {
                    setActionsData((prev) => ({ ...prev, table: value }));
                    if (reason === "clear") {
                      setActions([]);
                    }
                  }}
                  options={["Incident", "ServiceRequest"]}
                />
              </Grid>
            </Grid>

            <Grid container className="px-2 mb-3">
              <Grid item xs={2}>
                <InputLabel required>Fields</InputLabel>
              </Grid>
              <Grid item xs={10}>
                {actions?.map((item) => (
                  <Grid container key={item.id} className="mb-1">
                    <Grid container>
                      <Grid item xs={3} className="pe-1">
                        <SelectFieldNF
                          name="field"
                          value={item.field}
                          onChange={(value) => {
                            setActions(
                              handleChange(
                                handleChange(actions, item.id, "field", value),
                                item.id,
                                "act_on",
                                ""
                              )
                            );
                          }}
                          options={
                            actionsData?.table === "Incident"
                              ? conditions
                              : requestConditions
                          }
                          getOptionLabel={(option) => option.replace("_", " ")}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        {ConditionFields(item?.field) === "text" ? (
                          <InputFieldNF
                            name="act_on"
                            value={item?.act_on ? item?.act_on : ""}
                            onChange={(e) =>
                              setActions(
                                handleChange(
                                  actions,
                                  item.id,
                                  "act_on",
                                  e.target.value
                                )
                              )
                            }
                          />
                        ) : (
                          <SelectFieldNF
                            name="act_on"
                            value={item?.act_on ? item?.act_on : null}
                            onChange={(value) => {
                              setActions(
                                handleChange(actions, item.id, "act_on", value)
                              );
                            }}
                            getOptionLabel={(option) =>
                              !Array.isArray(ConditionFields(item?.field))
                                ? option[ConditionFields(item?.field)?.label]
                                : option
                            }
                            url={
                              ConditionFields(item?.field)
                                ? ConditionFields(item?.field)?.url
                                : false
                            }
                            options={
                              Array.isArray(ConditionFields(item?.field))
                                ? ConditionFields(item?.field)
                                : []
                            }
                          />
                        )}
                      </Grid>

                      <Grid item xs={3}>
                        <div className="d-flex align-items-center h-100">
                          <IconButton
                            onClick={() => {
                              setActions(
                                actions.filter((i) => item.id !== i.id)
                              );
                            }}
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}

                <Button
                  variant="outlined"
                  className="text-dark mt-2 border border-secondary"
                  sx={{
                    "&:disabled": {
                      border: "1px solid #e4e4e4 !important",
                      color: "#c1c1c1 !important",
                    },
                  }}
                  onClick={() =>
                    setActions((prev) => [
                      ...prev,
                      { id: Date.now(), field: "", act_on: "" },
                    ])
                  }
                  disabled={
                    actionsData?.is_ad_related
                      ? !actionsData?.groupname
                      : !actionsData?.table
                  }
                >
                  <AddOutlined /> Add Field Value
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container xs={6} className="px-2 mb-3">
              <Grid item xs={4}>
                <InputLabel required>Group</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <SelectFieldNF
                  name="groupname"
                  disable={!actionsData?.action_type}
                  value={actionsData?.groupname || null}
                  onChange={(value, reason) => {
                    setActionsData((prev) => ({ ...prev, groupname: value }));
                  }}
                  getOptionLabel={(option) => (option?.cn ? option?.cn : "")}
                  url={`/accounts/ad`}
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={6}
              className="px-2 mb-3 d-flex align-items-center "
            >
              <Grid item xs={4}>
                <InputLabel required>Is Approval Required?</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  name="is_approval_required"
                  checked={actionsData?.is_approval_required}
                  onChange={(e) =>
                    setActionsData((prev) => ({
                      ...prev,
                      is_approval_required: e.target.checked,
                    }))
                  }
                />
              </Grid>
            </Grid>

            {actionsData?.is_approval_required && (
              <>
                {" "}
                {approvers?.map((approver, index) => (
                  <Grid
                    container
                    className="px-2 mb-3 d-flex align-items-center"
                  >
                    <Grid item xs={2}>
                      <InputLabel required>
                        Approver {approver?.level}
                      </InputLabel>
                    </Grid>
                    <Grid container item xs={10}>
                      <Grid item xs={4.7}>
                        <SelectFieldNF
                          name="user"
                          value={approver?.user ? approver?.user : null}
                          onChange={(value) => {
                            setApprovers(
                              handleChange(
                                approvers,
                                approver.id,
                                "user",
                                value
                              )
                            );
                          }}
                          getOptionLabel={(option) => option?.full_name}
                          url={`/accounts/usernamewithid2`}
                        />
                      </Grid>
                      <Grid item xs={7.3} className="d-flex ">
                        {approvers?.length === index + 1 &&
                          approvers?.length !== 1 && (
                            <div className="d-flex align-items-center h-100">
                              <IconButton
                                onClick={() => {
                                  setApprovers(
                                    approvers?.filter(
                                      (i) => approver.id !== i.id
                                    )
                                  );
                                }}
                              >
                                <RemoveCircleOutline />
                              </IconButton>
                            </div>
                          )}
                        {approvers?.length === index + 1 &&
                          approvers?.length < 2 && (
                            <div className="d-flex align-items-center h-100">
                              <IconButton
                                onClick={() =>
                                  setApprovers((prev) => [
                                    ...prev,
                                    {
                                      id: Date.now(),
                                      user: "",
                                      level: index + 2,
                                    },
                                  ])
                                }
                              >
                                <AddCircleOutline />
                              </IconButton>
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </>
        )}
      </Grid>
      <Grid container className="my-3" justifyContent={"flex-end"}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            handleActionSubmit();
          }}
          // disabled={!actions[0]?.act_on}
        >
          save
        </Button>
      </Grid>
    </>
  );
};

export default ActionsForm;
