import React from 'react';

const Spinner = () => {
  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{
        height: '100vh',
        width: '100%',
        background: 'rgba(255,255,255,1)',
        zIndex: '1000',
      }}>
      <div className='spinner-border text-info' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  );
};

export default React.memo(Spinner);
