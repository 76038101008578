import React, { useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { SubCategoryDialog } from "../../components/AdminPanelLayout/SubCategoryDialog";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox/delete";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";

const Categories = ({ match, history }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = `All Sub Categories Of ${match.params.name}`;
    }
    // eslint-disable-next-line
  }, []);

  const { data: subCategories } = useGetAll({
    key: `/portal/subcategories/${match.params.id}`,
    params: {
      page: 0,
      limit: 25,
    },
  });

  const columns = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      cellClassName: "text-dark",
    },
    {
      headerName: "Head",
      field: "head",
      flex: 1,
      cellClassName: "text-dark",
      renderCell: ({ row }) => (
        <p className="m-0 text-truncate">{row?.head?.profile?.full_name}</p>
      ),
    },
    {
      headerName: "Users",
      field: "users",
      flex: 1,
      cellClassName: "text-dark",
      renderCell: ({ row }) => (
        <>
          {row?.users?.map((item, index) => (
            <p className="m-0 text-truncate">{`${item?.profile?.full_name} ${
              index + 1 !== row?.users?.length ? " , " : " "
            } `}</p>
          ))}
        </>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1.2,
      renderCell: ({ row }) => (
        <div className="flex items-center">
          <SubCategoryDialog isUpdate data={row} id={match.params.id} />
          <div className="border rounded-xl py-2 mx-3"></div>

          <DeleteBox
            title="Sub Category"
            url="/portal/subcategories"
            data={row.id}
            refetchUrl={`/portal/subcategories/${match.params.id}`}
          >
            Do you really want to delete this Category?
          </DeleteBox>
        </div>
      ),
    },
  ];
  return (
    <>
      <Navbar heading="SUB-CATEGORIES" subheading="" />
      <section className="main-container">
        <DataGrid
          columns={columns}
          url={`/portal/subcategories/${match.params.id}`}
          rows={subCategories}
          addButton={<SubCategoryDialog id={match.params.id} />}
        />
      </section>
    </>
  );
};

export default React.memo(Categories);
