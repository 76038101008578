import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import UserLogin from "./UserLogin";
import UserLoginNew from "./UserLoginNew";
import UserRegister from "./UserRegister";
import UserRegisterNew from "./UserRegisterNew";
// import PasswordChange from "./PasswordChange";
import PasswordChange from "./PasswordChangeNew";

const UserAuthentication = () => {
  return (
    <Fragment>
      <Route path="/user-login" component={UserLoginNew} />
      {/* <Route path="/user-login" component={UserLogin} /> */}
      {/* <Route path="/user-register" component={UserRegister} /> */}
      <Route path="/user-register" component={UserRegisterNew} />
      <Route path="/password-change" component={PasswordChange} />
    </Fragment>
  );
};

export default UserAuthentication;
