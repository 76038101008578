import { useQuery } from "react-query";
import serverAPI from "../utils/serverAPI";

const get = async (endPoint, params = {}) => {
  if (endPoint) {
    return await serverAPI.get(`${endPoint}`, {
      params,
    });
  }
};

const useGetAll = (endPoint, options = {}) => {
  // console.log(endPoint, "endpoints from usegetall");
  return useQuery(
    options.params ? [`${endPoint}`, options.params] : `${endPoint}`,
    () => get(endPoint, options.params),
    {
      select: (data) => data.data,
      onError: (err) => console.error(err),
      ...options,
      retry: false,
    }
  );
};

export default useGetAll;
