import React, { useEffect } from "react";
import { useState } from "react";
import TaskDateFilter from "./TaskDateFilter";
import TaskPriorityFilter from "./TaskPriorityFilter";
const TaskFilterWrapper = ({ task_server, setTasks }) => {
  const OPTIONS = [
    "Date",
    "Priority",
    "Completed",
    "Important",
    "Pending",
    "All",
  ];

  const styles = {
    label: {},
    option: {
      padding: "0.6rem",
    },
    select: {
      outline: "none",
      padding: "0.4rem",
    },
    select_input: {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
    },
  };

  const [option, setOption] = useState("All");

  const optionHandler = ({ target: { value } }) => {
    onChange(value);
    setOption(value);
  };

  function onChange(type, data) {
    switch (type) {
      case "Completed": {
        return setTasks(task_server.filter((task) => task.state));
      }
      case "Pending": {
        return setTasks(task_server.filter((task) => !task.state));
      }
      case "All": {
        return setTasks(task_server);
      }
      case "Important": {
        return setTasks(task_server.filter((task) => task.favorite));
      }
      case "High": {
        return setTasks(task_server.filter((task) => task.priority === "High"));
      }
      case "Medium": {
        return setTasks(
          task_server.filter((task) => task.priority === "Medium")
        );
      }
      case "Low": {
        return setTasks(task_server.filter((task) => task.priority === "Low"));
      }
      case "Date": {
        if (data == null) return;
        const { from, to } = data;
        if (!from || !to) return;
        const fromDate = new Date(`${from.month} ${from.day} ${from.year}`);
        const toDate = new Date(`${to.month} ${to.day} ${to.year}`);
        return setTasks(() => {
          let returnValue = task_server.filter((task) => {
            if (fromDate.getTime() === toDate.getTime()) {
              return fromDate.getDate() === new Date(task.created_on).getDate();
            }
            let isRangedDate =
              fromDate.getTime() <= new Date(task.created_on).getTime() &&
              toDate.getTime() >= new Date(task.created_on).getTime();
            console.log({
              isRangedDate,
              created_on: new Date(task.created_on).getTime(),
              fromDate: fromDate.getTime(),
              toDate: toDate.getTime(),
            });
            return isRangedDate;
          });
          return returnValue;
        });
      }
    }
    return type;
  }

  useEffect(() => {
    onChange(option);
    setOption(option);
  }, [task_server]);

  return (
    <div style={styles.select_input}>
      <div style={styles.label}>Filter By : </div>
      <div className="d-flex ">
        <select
          className="form-select mr-2"
          onChange={optionHandler}
          value={option}
        >
          {OPTIONS.map((opt) => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </select>
        <div className="ms-2">
          {option === "Date" && <TaskDateFilter onChange={onChange} />}
          {option === "Priority" && <TaskPriorityFilter onChange={onChange} />}
        </div>
      </div>
    </div>
  );
};

export default TaskFilterWrapper;
