import * as yup from "yup";
import { Grid, Box, Typography, Divider, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Input,
  AutoComplete,
  Label,
  Checkbox,
} from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
// import Navbar from "../../components/layouts/Navbar";
import { toast } from "../../utils/toaster";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useGetAll } from "../../hooks";
import { PageHeader } from "../../components/layouts/pageHeader";
import serverAPI from "../../utils/serverAPI";
import { useHistory, useLocation } from "react-router-dom";
import { CalculatePriority, importance } from "../../utils/IncidentUtils";
import { url } from "../../urls";
import Uitabs from "./component/Uitabs";
import { Tabs } from "../../components/layouts/commonLayouts/tabs"
import NotesForm from "./component/Notes";
import AnalysisInformation from "./component/Analysis";
import ResolutionInformation from "./component/Resolution.jsx";

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

// Validation Schema
const problemUpdateValidationSchema = yup.object({
  prb_statement: yup.string().required('Problem Statement is required')
});

const UpdateProblem = () => {
  const history = useHistory();
  const location = useLocation();
  const arr = location.pathname.split("/");
  const [prbStatement, setPrbStatement] = useState('');
  // const [majorProblem, setMajorProblem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [statusOption, setStatusOption] = useState([]); // Move this inside the component
  const { data } = useGetAll({
    key: `/problems/update/${arr[arr.length - 1]}`
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 200); // 2-second delay
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setPrbStatement(data?.prb_statement || "");
    setDescription(data?.description || "");
    getPossibleStates(data?.state);
  }, [data]);

  // const userInfo = {
  //   user: localStorage.getItem("data")
  //     ? JSON.parse(localStorage.getItem("data"))
  //     : {},
  // };

  // {id: 1 name : "New" }
  //   {id: 2, name : "Assess"}
  //   {id: 3, name :"Root Cause Analysis"}
  //   {id: 4, name : "Fix in Progress"},
  //   {id: 5, name : "Resolved"}
  //   {id: 6, name : "Discard" }
  //   {id: 7, name : "Duplicate"}
  //   {id: 8, name : "Closed"}


  const getPossibleStates = (currState) => {
    if (currState === "New") {
      setStatusOption([
        { id: 1, name: "New" },
        { id: 2, name: "Assess" }

      ]);
    } else if (currState === "Assess") {
      setStatusOption([
        { id: 2, name: "Assess" },
        { id: 3, name: "Root Cause Analysis" },
        { id: 6, name: "Discard" },
        { id: 7, name: "Duplicate" },
        { id: 8, name: "Closed" },

      ]);
    } else if (currState === "Root Cause Analysis") {
      setStatusOption([
        { id: 4, name: "Fix in Progress" },
        { id: 6, name: "Discard" },
        { id: 7, name: "Duplicate" },
        { id: 8, name: "Closed" },
      ]);
    } else if (currState === "Fix in Progress") {
      setStatusOption([
        { id: 3, name: "Root Cause Analysis" },
        { id: 2, name: "Fix in Progress" },
        { id: 4, name: "Resolved" },
        { id: 6, name: "Discard" },
        { id: 7, name: "Duplicate" },
        { id: 8, name: "Closed" }
      ]);
    } else if (currState === "Resolved") {
      setStatusOption([
        { id: 8, name: "Closed" }
      ]);
    }
    else if (currState === "Closed" || currState === "Discard") {
      setStatusOption([
        { id: 2, name: "Fix in Progress" },
        { id: 3, name: "Assess" },
        { id: 5, name: "Closed" },
        { id: 6, name: "Discard" },
      ]);
    }
  };
  if (isLoading) {
    return (
      <Box className="d-flex justify-content-center align-items-center w-full" style={{ height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const New = data.state === 'New' ? false : true;
  const Assess = data.state === 'Assess' ? false : true;
  const Analysis = data.state === 'Root Cause Analysis' ? false : true;
  const Fix = data.state === 'Fix in Progress' ? false : true;
  const Resolved = data.state === 'Resolved' ? false : true;
  const Closed = data.state === 'Closed' ? false : true;
  const Discard = data.state === 'Discard' ? false : true;

  console.log('major_problem', data.major_problem)

  // {
  //   caller: data?.caller ? data?.caller : {},
  //   category: data?.category || { id: "", name: "Select" },
  //   sub_category: data?.sub_category || { id: "", name: "Select" },
  //   config_item: data?.config_item || { id: "", name: "Select" },
  //   assigned_grp: data?.assigned_grp || { id: "", name: "Select" },
  //   state: data?.state || "New",
  //   impact: data?.impact || "Low",
  //   urgency: data?.urgency || "Low",
  //   priority: data?.priority || "Normal",
  //   service: data?.service || "",
  //   assigned_to: data?.assigned_to || { id: "", name: "Select" },
  //   major_problem: data?.major_problem || false,
  //   affected_incidents: data?.affected_incidents || [],

  // }
  const initValues = { ...data }

  return (
    <>
      <section className="main-container">
        <main className="d-flex flex-column px-4 pt-2 sm:p-0 sm:w-[100%]">
          <Formik
            initialValues={initValues}
            onSubmit={async (values, { resetForm }) => {
              // console.log('!!!!!!!!!!!!!!!!!!!!!!')
              const payload = {
                caller: values?.caller?.USER_ID || values?.caller?.id,
                state: values?.state.name,
                affected_incidents: values?.affected_incidents.map(incident => incident.id),
                impact: values?.impact.name,
                config_item: values?.config_item?.id || "",
                urgency: values?.urgency.name,
                category: values?.category?.id || "",
                priority: values?.priority,
                sub_category: values?.sub_category?.id,
                assigned_grp: values?.assigned_grp?.id,
                service: values?.service,
                assigned_to: values?.assigned_grp?.manager?.id,
                major_problem: values?.major_problem,
                prb_statement: prbStatement,
                description: description,
              }
              if (description.length !== 0) {
                const config = {
                  headers: {
                    "Content-Type":
                      "multipart/form-data; boundary=<calculated when request is sent>",
                  },
                };
                const res = await serverAPI.put(
                  `${url}/problems/update/${data?.prb_num}`,
                  payload,
                );
                if (res?.status === 202) {
                  // resetForm();
                  // setDescription("");
                  // getPossibleStates(
                  //   values?.state?.name ? values?.state?.name : values?.state
                  // );
                  toast("Problem Updated successfully");
                  history.push(`/all-new-create-problem`);
                } else {
                  toast("Something went wrong", "error");
                }
              } else {
                toast("Description is required", "error");
              }
            }}
          >
            {({ values, errors, setFieldValue }) => (
              <>
                <Form >
                  <PageHeader header={"Update Problem"} className="mb-4" />
                  <div className="flex justify-between sm:flex-col">
                    <Uitabs disable={New}>New</Uitabs>
                    <Uitabs disable={Assess}>Assess</Uitabs>
                    <Uitabs disable={Analysis}>Root Cause Analysis</Uitabs>
                    <Uitabs disable={Fix}>Fix in Progress</Uitabs>
                    <Uitabs disable={Resolved}>Resolved</Uitabs>
                    <Uitabs disable={Closed}>Closed</Uitabs>
                    <Uitabs disable={Discard}>Discard</Uitabs>
                  </div>
                  <Grid
                    container
                    columnSpacing={6}
                    className="sm:flex-col sm:grid-cols-1 p-4 tab:flex-col tab:grid-cols-1 "
                  >
                    <Grid
                      item
                      xs={6}
                      className="mt-4 justify-between sm:max-w-full tab:max-w-full"
                    >
                      <AutoComplete
                        label="Raised By"
                        name="caller"
                        getOptionLabel={(option) =>
                          option?.profile?.full_name || "-"
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        options={statusOption}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        label="Status"
                        name="state"
                      // disabled={
                      //   (data?.caller || data?.raised_by) &&
                      //   (data?.state === "Closed" ||
                      //     (data?.assigned_to &&
                      //     data?.assigned_to.id === data?.caller
                      //       ? true
                      //       : userInfo?.user?.USER_ID !== data?.caller))
                      //     ? false
                      //     : true
                      // }
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        url={"/incidents/all-incidents"}
                        getOptionLabel={(opt) => opt?.inc_num || ""}
                        label="Parent Incident"
                        name="affected_incidents"
                        multiple
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        options={importance}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        label="Impact"
                        name="impact"
                        // disabled={
                        //   !data?.caller ||
                        //   !data?.raised_by ||
                        //   (data?.raised_by &&
                        //     userInfo?.user.USER_ID === data?.raised_by?.id) ||
                        //   data?.state === "Closed" ||
                        //   data?.state === "Discard"
                        //     ? true
                        //     : false
                        // }
                        onChange={(_, value) => {
                          let pt = CalculatePriority(
                            value?.name,
                            values?.urgency?.name
                          );
                          setFieldValue("priority", pt);
                          // impact: $("#Impact").val(),
                          // urgency: $("#Urgency").val()
                          setFieldValue("urgency", values?.urgency);
                          setFieldValue("impact", value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        url={"/portal/items/0"}
                        getOptionLabel={(opt) => opt?.name || ""}
                        label="Configuration Item"
                        name="config_item"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <AutoComplete
                        options={importance}
                        getOptionLabel={(opt) => opt?.name || opt || ""}
                        label="Urgency"
                        name="urgency"
                        onChange={(_, value) => {
                          let pt = CalculatePriority(
                            values?.impact?.name,
                            value?.name
                          );
                          setFieldValue("priority", pt?.name);
                          // impact: $("#Impact").val(),
                          // urgency: $("#Urgency").val()
                          setFieldValue("impact", values?.impact);
                          setFieldValue("urgency", value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        url={"/portal/allcats"}
                        getOptionLabel={(opt) => opt?.name || ""}
                        label="Category"
                        name="category"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input label="Priority" name="priority" disabled />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        options={values?.category?.sub_categories}
                        getOptionLabel={(opt) => opt?.name || ""}
                        label="Sub Category"
                        name="sub_category"
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        url={"/portal/departments/0"}
                        getOptionLabel={(opt) => opt.name}
                        label="Assigned group"
                        name="assigned_grp"
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full">
                      <Input
                        label="Bssiness Service"
                        name="service"
                        required
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 sm:max-w-full tab:max-w-full">
                      <AutoComplete
                        name="assigned_to"
                        label="Assign To"
                        options={[values?.assigned_grp?.manager]}
                        getOptionLabel={(opt) => opt?.profile?.full_name || ""}
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4 ml-10 sm:max-w-full">
                      <Checkbox
                        label="Major Problem"
                        name="major_problem"
                        checked={values.major_problem}
                        // defaultChecked={values?.major_problem}
                        value={values.major_problem}
                        onChange={(_, checked) => {
                          setFieldValue("major_problem", checked);

                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4 h-10px sm:max-w-full h-10px tab:max-w-full">
                      <Label text="Short Description" required />
                      <CKEditor
                        editor={ClassicEditor}
                        data={prbStatement}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "100px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPrbStatement(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-4 h-20px sm:max-w-full tab:max-w-full">
                      <Label text="Detailed Description" required />
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onReady={(editor) => {
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "300px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                        }}
                        onError={() => toast("Error in CKEditor", "error")}
                      />
                    </Grid>

                    <Grid xs={12} item className="sm:self-center tab:self-center" >
                      <Box className="mt-5 mb-4 d-flex justify-content-end">
                        <Button
                          variant="contained"
                          className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                          type="submit"
                          onClick={() => {
                            console.log('sssssssssssssssssssssssssssssssssssssss')
                          }}
                        >
                          Update Problem
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
          <Grid container
                    columnSpacing={6}
                    className="sm:flex-col sm:grid-cols-1 p-4 tab:flex-col tab:grid-cols-1 ">
            <Grid  item
                      xs={12}
                      className="mt-4 justify-between sm:max-w-full tab:max-w-full">
            <Tabs
              tabs={[
                {
                  id: 0,
                  buttonLabel: "Notes",
                  component: (
                    <NotesForm id={data.prb_num}/>
                  ),
                  visible:true
                },
                {
                  id: 1,
                  buttonLabel: "Analysis Info",
                  component: (
                    <AnalysisInformation id={data.prb_num} state={data.state} />
                  ),
                  visible:  data.state !=='New'
                },
                {
                  id: 2,
                  buttonLabel: "Resolved Info",
                  component: (
                    <ResolutionInformation id={data.prb_num} state={data.state} />
                  ),
                  visible: data.state !== 'New' && data.state !== 'Closed' && data.state !== 'Discard'  
                },

              ].filter(tab => tab.visible === true)}
            />
            </Grid>
          </Grid>
        </main>
      </section>
    </>
  );
};

export default UpdateProblem;
