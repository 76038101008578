import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Dialog } from "../../components/layouts/commonLayouts/dialogBox/dialog";
import { Edit } from "@carbon/icons-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { StatusCard } from "../../components/layouts/commonLayouts/cards/StatusCard";
import UserDialouge from "../../components/users/UserDialouge";

export default function Admincard({ row, key }) {
  console.log("Adimin card", row);

  return (
    <>
      <Box sx={{ minWidth: 275 }} key={key}>
        <Card variant="outlined" className="mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.user?.email}
            </Typography>
            <Typography variant="h5" component="div">
              {row?.full_name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {row?.user_role}
            </Typography>
            <Typography
              variant="p"
              className="text-ellipsis overflow-hidden w-[30px]"
            >
              {row?.phone}
            </Typography>
            <br />
            <Typography variant="button">{row?.department?.name}</Typography>
            {row?.is_active ? <p>Active</p> : <p>Unactive</p>}
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
