import React, { Fragment } from 'react';

const PreviewIncident = ({ item }) => {
  const {
    assigned_grp,
    service,
    caller,
    category,
    inc_num,
    assigned_to,
    sub_category,
    priority,
    contact_type,
    state,
    short_desc,
    impact,
    urgency,
    contact_email,
    contact_phone,
    resolved_on,
    raised_by,
  } = item;
  return (
    <Fragment>
      <button
        type='button'
        className='blue bg-white text-center text-white px-3 rounded mx-auto'
        style={{
          border: '1px solid var(--sec)',
          boxShadow: '0 4px 5px #109bf110',
          outline: 'none',
        }}
        data-bs-toggle='modal'
        data-bs-target={`#incidentViewModel${item.id}`}>
        view
      </button>

      <div
        className='modal fade'
        id={`incidentViewModel${item.id}`}
        tabIndex='-1'
        aria-labelledby='IncidentViewModelLabel'
        aria-hidden='true'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='row p-3 pb-0'>
                <div className='col-md-12'>
                  <div
                    className='ms-2 py-2 px-4 dark-blue fw-bold bg-light-gray rounded'
                    style={{ border: '2px solid var(--seven)' }}>
                    INCIDENT PREVIEW
                  </div>
                </div>
              </div>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <div className='modal-body ' style={{ cursor: 'default' }}>
              <main className='d-flex flex-column mx-3'>
                <form className='d-flex justify-content-around mt-3'>
                  <div style={{ width: '45%' }}>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='inc_num'
                          style={{ fontWeight: '500' }}
                          className='col-form-label fs-7 dark-blue me-5'>
                          Number{' '}
                          <span className='d-inline text-danger'> *</span>
                        </label>
                      </div>
                      <div className='col-md-7 p-0 d-flex justify-content-start'>
                        <input
                          style={{ cursor: 'default' }}
                          type='text'
                          id='inc_num'
                          name='inc_num'
                          value={inc_num ? inc_num : '-'}
                          className='form-control d-inline'
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='raised_by'
                          style={{ fontWeight: '500' }}
                          className='col-form-label fs-7 dark-blue me-5'>
                          Raised By{' '}
                          <span className='d-inline text-danger'> *</span>
                        </label>
                      </div>
                      <div className='col-md-7 p-0 d-flex justify-content-start'>
                        <input
                          style={{ cursor: 'default' }}
                          type='text'
                          id='raised_by'
                          name='raised_by-data'
                          value={
                            raised_by && raised_by.profile
                              ? raised_by.profile.full_name
                              : '-'
                          }
                          className='form-control d-inline'
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='caller'
                          style={{ fontWeight: '500' }}
                          className='col-form-label fs-7 dark-blue me-5'>
                          Raised For{' '}
                          <span className='d-inline text-danger'> *</span>
                        </label>
                      </div>
                      <div className='col-md-7 p-0 d-flex justify-content-start'>
                        <input
                          style={{ cursor: 'default' }}
                          type='text'
                          id='caller'
                          name='caller-data'
                          value={caller ? caller.profile.full_name : '-'}
                          className='form-control d-inline'
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='cat'
                          style={{ fontWeight: '500' }}
                          className='col-form-label fs-7 dark-blue me-5'>
                          Category
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          className='form-control'
                          id='cat'
                          name='category'
                          value={category ? category.name : '-'}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='sub-cat'
                          style={{ fontWeight: '500' }}
                          className='col-form-label dark-blue me-5 fs-7'>
                          Sub Category
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          className='form-control'
                          id='sub-cat'
                          name='sub_category'
                          value={sub_category ? sub_category.name : '-'}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='Assignment-Group'
                          style={{ fontWeight: '500' }}
                          className='col-form-label dark-blue me-5 fs-7'>
                          Department
                        </label>
                      </div>
                      <div className='col-md-7 p-0 d-flex justify-content-start'>
                        <input
                          id='Assigned-Group'
                          name='assigned_grp'
                          className='form-control'
                          value={assigned_grp ? assigned_grp.name : '-'}
                          style={{ height: '35px', cursor: 'default' }}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          style={{ fontWeight: '500' }}
                          htmlFor='Assigned-to'
                          className='col-form-label dark-blue me-5 fs-7'>
                          Assigned to
                        </label>
                      </div>
                      <div className='col-md-7 p-0 d-flex justify-content-start'>
                        <input
                          id='Assigned-to'
                          name='assigned_to'
                          className='form-control'
                          value={
                            assigned_to ? assigned_to.profile.full_name : '-'
                          }
                          readOnly
                          style={{ height: '35px', cursor: 'default' }}></input>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '45%' }}>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='cont-type'
                          style={{ fontWeight: '500' }}
                          className='col-form-label fs-7 dark-blue me-5'>
                          Preferred Contact Mode
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          className='form-control'
                          id='cont-type'
                          name='contact_type'
                          value={contact_type ? contact_type : '-'}
                          readOnly
                          placeholder='Select Contact Type'
                        />
                      </div>
                    </div>
                    {contact_type && contact_type === 'Email' && (
                      <div className='row my-2'>
                        <div className='col-md-5 p-0'>
                          <label
                            htmlFor='emali'
                            style={{ fontWeight: '500' }}
                            className='col-form-label fs-7 dark-blue me-5'>
                            Your Email
                          </label>
                        </div>
                        <div className='col-md-7 p-0'>
                          <input
                            style={{ cursor: 'default' }}
                            className='form-control'
                            id='emali'
                            name='contact_email'
                            type='email'
                            value={contact_email ? contact_email : '-'}
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                    {contact_type && contact_type === 'Phone' && (
                      <div className='row my-2'>
                        <div className='col-md-5 p-0'>
                          <label
                            htmlFor='phone'
                            style={{ fontWeight: '500' }}
                            className='col-form-label fs-7 dark-blue me-5'>
                            Your Phone
                          </label>
                        </div>
                        <div className='col-md-7 p-0'>
                          <input
                            style={{ cursor: 'default' }}
                            className='form-control'
                            id='phone'
                            name='phone'
                            value={contact_phone ? contact_phone : '-'}
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='State'
                          style={{ fontWeight: '500' }}
                          className='col-form-label fs-7 dark-blue me-5'>
                          State
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          className='form-control'
                          id='state'
                          name='state'
                          value={state ? state : '-'}
                          readOnly></input>
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          style={{ fontWeight: '500' }}
                          htmlFor='Impact'
                          className='col-form-label fs-7 dark-blue me-5'>
                          Impact
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          className='form-control'
                          id='Impact'
                          name='impact'
                          value={impact ? impact : '-'}
                          readOnly></input>
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='Urgency'
                          style={{ fontWeight: '500' }}
                          className='col-form-label dark-blue me-5 fs-7'>
                          Urgency
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          className='form-control'
                          id='Urgency'
                          name='urgency'
                          value={urgency ? urgency : '-'}
                          readOnly></input>
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='Priority'
                          style={{ fontWeight: '500' }}
                          className='col-form-label dark-blue me-5 fs-7'>
                          Priority
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          type='text'
                          id='Priority'
                          name='priority'
                          value={priority ? priority : '-'}
                          readOnly
                          className='form-control'
                        />
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='col-md-5 p-0'>
                        <label
                          htmlFor='resolved_on'
                          style={{ fontWeight: '500' }}
                          className='col-form-label dark-blue me-5 fs-7'>
                          Resolved At
                        </label>
                      </div>
                      <div className='col-md-7 p-0'>
                        <input
                          style={{ cursor: 'default' }}
                          type='text'
                          id='resolved_on'
                          name='resolved_on'
                          value={resolved_on ? resolved_on.slice(0, -3) : '-'}
                          readOnly
                          className='form-control'
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  className='d-flex justify-content-between mx-auto mt-3'
                  style={{ width: '97%' }}>
                  <div className='' style={{ width: '16%' }}>
                    <label
                      htmlFor='busi-ser'
                      style={{ fontWeight: '500' }}
                      className='col-form-label dark-blue me-5 fs-7'>
                      Affected Service
                    </label>
                  </div>
                  <div className='' style={{ width: '80%' }}>
                    <textarea
                      type='text'
                      id='busi-ser'
                      name='service'
                      readOnly
                      value={service ? service : '-'}
                      style={{
                        resize: 'none',
                        minHeight: '10px',
                        cursor: 'default',
                      }}
                      className='form-control'
                    />
                  </div>
                </div>
                <div
                  className='d-flex justify-content-between my-2 mb-4 mx-auto'
                  style={{ width: '97%' }}>
                  <div className='' style={{ width: '16%' }}>
                    <label
                      htmlFor='shortdicription'
                      style={{ fontWeight: '500' }}
                      className='col-form-label dark-blue me-5 fs-7'>
                      Short Description
                      <span className='d-inline text-danger'> *</span>
                    </label>
                  </div>
                  <div className='' style={{ width: '80%' }}>
                    <div
                      id='shortdicription'
                      style={{
                        minHeight: '100px !important',
                        cursor: 'default',
                      }}
                      readOnly
                      dangerouslySetInnerHTML={{ __html: short_desc }}
                      className='form-control rounded bg-secondory p-2'
                    />
                  </div>
                </div>
              </main>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary me-3'
                data-bs-dismiss='modal'>
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(PreviewIncident);
