import { InputLabel } from "@mui/material";
import { inputStyles } from "../input";
import React from "react";

export const Label = ({
  text,
  className,
  colorClass,
  outerClassName,
  required,
}) => {
  return (
    <p className={outerClassName ? outerClassName : "my-0"}>
      <InputLabel
        className={`${colorClass && colorClass} ${className} ml-0 mb-2`}
        required={required}
        sx={inputStyles}
      >
        {text}
      </InputLabel>
    </p>
  );
};
