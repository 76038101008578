import { useMutation } from "react-query";
import { onError } from "../utils/onError";
import serverAPI from "../utils/serverAPI";

export function useCreateOrUpdate({
  url,
  method = "post",
  refetch,
  onSuccess,publicAPI=false
}) {
  function sendData(data) {
    // if(publicAPI){
    //   return serverAPI[method](url, data, {});
    // }else{

      return serverAPI[method](url, data, {});
    // }
  }

  return useMutation(sendData, {
    onSuccess: (response, variables, context) => {
      onSuccess && onSuccess(response, variables, context);
      refetch && refetch();
    },
    onError: (data) => {
      onError(data);
    },
  });
}
