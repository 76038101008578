import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox";
import { ProductDialog } from "../../components/AdminPanelLayout/ProductDialog";

export default function ProductsCard({ row, match,key }) {
  console.log("ProductsCard card", row);

  return (
    <>
      <Box sx={{ minWidth: 275 }} key={key} className="">
        <Card variant="outlined" className="mt-[30px] shadow-lg transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.name}
            </Typography>
            <Typography variant="h5" component="div">
              {row?.category?.name}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {row?.description}<br/>
              Price: {row?.price}
            </Typography>
            <div className="w-full flex justify-between">
              <ProductDialog
                isUpdate
                data={row}
                product_type={match.path.replace("/", "")}
              />

              <DeleteBox
                title="Department"
                url="/requests/product/Product"
                data={row.id}
                refetchUrl="/requests/product/Product/0"
              >
                Do you really want to delete this Category?
              </DeleteBox>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
