// // variables

// const approvalsChoice = [
//   { id: 1, name: "Requested", as: "Requested" },
//   { id: 2, name: "1st level approval", as: "1st level approved" },
//   { id: 7, name: "Approved", as: "2nd level Approved" },
//   { id: 3, name: "Rejected", as: "Rejected" },
//   { id: 4, name: "No Longer Required", as: "No Longer Required" },
//   { id: 5, name: "Duplicate", as: "Duplicate" },
// ];

// const reqState = [
//   { id: 1, name: "Pending Approval" },
//   { id: 2, name: "In Progress" },
//   { id: 3, name: "Awaiting Replace/Return" },
//   { id: 4, name: "Closed Complete" },
//   { id: 5, name: "Cancelled" },
// ];

// //functions

// const getSearchReq = (text, tableData) => {
//   let data = tableData.filter((inc) => {
//     if (text === "") {
//       return inc;
//     } else {
//       const regx = new RegExp(`${text}`, "gi");
//       return (
//         (inc.req_num ? inc.req_num.match(regx) : "") ||
//         (inc.requested_by &&
//           (inc.requested_by.profile
//             ? inc.requested_by.profile.full_name.match(regx)
//             : "-")) ||
//         (inc.raised_by &&
//           (inc.raised_by.profile
//             ? inc.raised_by.profile.full_name.match(regx)
//             : "-")) ||
//         (inc.short_desc ? inc.short_desc.match(regx) : "") ||
//         (inc.assigned_to &&
//           (inc.assigned_to.profile
//             ? inc.assigned_to.profile.full_name.match(regx)
//             : "-")) ||
//         (inc.assigned_grp ? inc.assigned_grp.name.match(regx) : "") ||
//         (inc.product ? inc.product.name.match(regx) : "") ||
//         (inc.state ? inc.state.match(regx) : "") ||
//         (inc.product ? inc.product.category.name.match(regx) : "") ||
//         (inc.product ? inc.product.product_type.match(regx) : "")
//       );
//     }
//   });
//   return data;
// };

// const onIpChange = (text, data) => {
//   let prd = data.filter((item) => {
//     if (text === "") {
//       return item;
//     } else {
//       const regx = new RegExp(`${text}`, "gi");
//       return (
//         (item.category ? item.category.name.match(regx) : "") ||
//         (item.name ? item.name.match(regx) : "") ||
//         (item.price ? item.price.match(regx) : "")
//       );
//     }
//   });
//   return prd;
// };
// const getPrdBycategory = (text, data) => {
//   let prd = data.filter((item) => {
//     if (text === "") {
//       return item;
//     } else {
//       const regx = new RegExp(`^${text}`, "gi");
//       return item.category ? item.category.name.match(regx) : "";
//     }
//   });
//   return prd;
// };

// export {
//   onIpChange,
//   getSearchReq,
//   getPrdBycategory,
//   approvalsChoice,
//   reqState,
// };


const approvalsChoice = [
  { id: 1, name: "Requested", as: "Requested" },
  { id: 2, name: "1st level approval", as: "1st level approved" },
  { id: 7, name: "Approved", as: "2nd level Approved" },
  { id: 3, name: "Rejected", as: "Rejected" },
  { id: 4, name: "No Longer Required", as: "No Longer Required" },
  { id: 5, name: "Duplicate", as: "Duplicate" },
];

const reqState = [
  { id: 1, name: "Pending Approval" },
  { id: 2, name: "In Progress" },
  { id: 3, name: "Awaiting Replace/Return" },
  { id: 4, name: "Closed Complete" },
  { id: 5, name: "Cancelled" },
];

const getSearchReq = (text, tableData) => {
  const regx = new RegExp(text, "gi");

  return tableData.filter(inc => {
    if (text === "") {
      return true;
    }

    const propertiesToSearch = [
      inc.req_num,
      inc.requested_by?.profile?.full_name,
      inc.raised_by?.profile?.full_name,
      inc.short_desc,
      inc.assigned_to?.profile?.full_name,
      inc.assigned_grp?.name,
      inc.product?.name,
      inc.state,
      inc.product?.category?.name,
      inc.product?.product_type,
    ];

    return propertiesToSearch.some(property => property?.match(regx));
  });
};

const onIpChange = (text, data) => {
  const regx = new RegExp(text, "gi");

  return data.filter(item => {
    if (text === "") {
      return item;
    }

    const propertiesToSearch = [item.category?.name, item.name, item.price];

    return propertiesToSearch.some(property => property?.match(regx));
  });
};

const getPrdBycategory = (text, data) => {
  const regx = new RegExp(`^${text}`, "gi");

  return data.filter(item => item.category?.name.match(regx));
};

export { onIpChange, getSearchReq, getPrdBycategory, approvalsChoice, reqState };
