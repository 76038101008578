import React from "react";

import bg1 from "../../../public/contact-us/contact-us-bg-1.png";
import bg2 from "../../../public/contact-us/contact-us-bg-2.png";
import bg3 from "../../../public/contact-us/contact-us-bg-3.png";
import phoneBg1 from "../../../public/contact-us/phone-contact-us-bg.png";
import { Link } from "react-router-dom";
import WebsiteLayout from "../components/WebsiteLayout";
import { useEffect } from "react";
import { tenantApiUrl, url } from "../../../urls";
import serverAPI from "../../../utils/serverAPI";
import { useState } from "react";
import { Input } from "../../../components/layouts/forms/inputs";
import { Form, Formik, useFormik } from "formik";
import * as yup from "yup";
import { useCreateOrUpdate } from "../../../hooks";
import { useQueryClient } from "react-query";
import { toast } from "../../../utils/toaster";

function ContactUs() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    message: "",
  };
  const data = initialState;
  const formValidations = yup.object({
    first_name: yup
      .string()

      .required("First name is required")
      .typeError("Name must be a string"),
    last_name: yup
      .string()

      .required("Last name is required")
      .typeError("Name must be a string"),
    email: yup
      .string()

      .required("Email is required")
      .typeError("Email must be a string"),
    mobile_no: yup.number().required("Email is required"),
    message: yup
      .string()
      .required("Message is required")
      .typeError("Message must be a string"),
  });
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({ ...initialState });
  const { mutate, isLoading } = useCreateOrUpdate({
    url: "api/tenants/enquiry/nt/",
    method: "post",
    publicAPI: true,
  });

  const [enq, setEnq] = useState(undefined);
  // const postEnq = async () => {
  //   try {
  //     const response = await serverAPI.get(
  //       `${tenantApiUrl}/tenants/plans/nt/`
  //     );

  //     console.log(response.data, "Reponse ^^");
  //     // setPlans(response.data);
  //   } catch (error) {
  //     console.log(error, "ERROR");
  //   }
  // };

  useEffect(() => {
    // getPlanDetails();
  }, []);
  return (
    <WebsiteLayout>
      <section
        className="min-h-screen w-full relative py-[200px] montserrat pb-[107px]
          phone:bg-[#E6EFFF] phone:pt-[71px] phone:pb-[84px]
        "
      >
        <div
          className="relative w-[80%] m-auto bg-[#E6EFFF] pt-[90px] px-[1rem] pb-[80px] text-center rounded-[27px]
                        tab:w-[89%] tab:py-[64px]
                        phone:pt-[129x] phone:pb-0

        "
        >
          <div className="w-full relative z-50 pb-[58px] m-auto">
            <div
              className="text-[#313131] text-[30px] leading-[40px] mb-[33px]
              tab:text-[21px] tab:leading-[28px] tab:mb-[23px]
              phone:text-[28px] phone:leading-[40px] phone:mb-[24px]
              font-[800] gillory

            "
            >
              Contact Us
            </div>
            <div
              className="text-[#425466] text-[18px] font-normal leading-[27px] 
             tab:text-[12px] tab:leading-[19px] tab:mb-[40px]
             phone:text-[14px] phone:leading-[22px]
             poppins font-[400] 
            "
            >
              Transform your business with our IT service mangement. Contact us
              to get started today.
            </div>
          </div>
          <Formik
            initialValues={{ ...data }}
            onSubmit={(values, { resetForm }) => {
              console.log("ff", values);
              resetForm();
              mutate({
                ...values,
              });
              toast(`Form sent successfully!`);
            }}
            validationSchema={formValidations}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              errors,
              touched,
              submitForm,
              resetForm,
            }) => (
              <Form className="contact-us-form w-[83%] m-auto phone:w-full">
                {/* {console.log(values)} */}
                {/* <form className="contact-us-form"  onSubmit={() => formik.handleSubmit}> */}
                <div
                  class="contact-us-form__inputs 
                "
                >
                  <div class="contact-us-form__inputs__input poppins">
                    <label class="capitalize" for="0">
                      first name{" "}
                    </label>
                    <input
                      required
                      name="first_name"
                      id="0"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />
                    {touched["first_name"] && errors["first_name"] && (
                      <p className="text-red-600 m-0 text-[12px] tab:text-[10px] phone:text-[12px] ">
                        {errors["first_name"]}
                      </p>
                    )}
                  </div>

                  <div class="contact-us-form__inputs__input poppins">
                    <label class="capitalize" for="1">
                      last name{" "}
                    </label>
                    <input
                      required
                      name="last_name"
                      id="1"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                    />
                    {touched["last_name"] && errors["last_name"] && (
                      <p className="text-red-600 m-0 text-[12px] tab:text-[10px] phone:text-[12px] ">
                        {errors["last_name"]}
                      </p>
                    )}
                  </div>

                  <div class="contact-us-form__inputs__input poppins">
                    <label class="capitalize" for="2">
                      email id{" "}
                    </label>
                    <input
                      required
                      name="email"
                      id="2"
                      type="email"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {touched["email"] && errors["email"] && (
                      <p className="text-red-600 m-0 text-[12px] tab:text-[10px] phone:text-[12px] ">
                        {errors["email"]}
                      </p>
                    )}
                  </div>

                  <div class="contact-us-form__inputs__input poppins">
                    <label class="capitalize" for="3">
                      contact no{" "}
                    </label>
                    <input
                      required
                      name="mobile_no"
                      id="3"
                      type="tel"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile_no}
                    />
                    {touched["mobile_no"] && errors["mobile_no"] && (
                      <p className="text-red-600 m-0 text-[12px] tab:text-[10px] phone:text-[12px] ">
                        {errors["mobile_no"]}
                      </p>
                    )}
                  </div>

                  <div class="contact-us-form__inputs__input poppins text-area">
                    <label for="99">Your message</label>
                    <textarea
                      name="message"
                      rows="4"
                      id="99"
                      type="text"
                      placeholder=""
                      class="contact-us-form__inputs__input poppins"
                      spellcheck="false"
                      onChange={handleChange}
                      value={values.message}
                      onBlur={handleBlur}
                    ></textarea>
                    {touched["message"] && errors["message"] && (
                      <p className="text-red-600 m-0 text-[12px] tab:text-[10px] phone:text-[12px] ">
                        {errors["message"]}
                      </p>
                    )}
                  </div>
                </div>
                <button
                  className="contact-us-form__submit poppins font-[700]"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    submitForm();
                    // console.log(formik.values);
                  }}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>

          <img
            src={bg2}
            className="absolute top-0 left-0 phone:hidden"
            alt=""
          />
          <img
            src={bg3}
            className="absolute top-0 right-0 phone:hidden"
            alt=""
          />
          <img
            src={phoneBg1}
            className="absolute hidden top-0 left-0 phone:block"
            alt=""
          />

          
        </div>
        <img src={bg1} className="absolute top-0 left-0 phone:hidden" alt="" />
      </section>
    </WebsiteLayout>
  );
}

export default ContactUs;
