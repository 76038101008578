import { Grid } from "@mui/material";
import { Search } from "../../../forms/inputs/Search";
import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid as MuiDataGrid,
  GridFeatureModeConstant,
} from "@mui/x-data-grid";
import { colors } from "../../../../../constants/theme";
import { Pagination } from "../../pagination";
import { NoDataOverlay } from "../NoDataOverlay";
import { useDebounce } from "../../../../../hooks";
import { useGetAll } from "../../../../../hooks";
import { Margin } from "@mui/icons-material";

const dataGridStyles = {
  borderRadius: 0,
  border: "none",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: colors.tertiary.light,
    borderRadius: 0,
    color: colors.text.dark,
    border: "1px solid #E0E7ED",
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      paddingLeft: "6px",
      paddingRight: "8px",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "600",
      fontFamily: "'Century Gothic Bold', sans-serif",
      color: "#2E3545",
      fontSize: {
        sm: "13px",
        xl: "15px",
        lg: "14px",
      },
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    border: "1px solid #E0E0E0",
    // borderRight: "1px solid #E0E7ED",
  },
  "& .MuiDataGrid-cell": {
    background: "#ffffff",
    color: "#49556D",
    paddingLeft: "16px",
    paddingRight: "16px",
    border: "1px solid #E0E0E0",
    fontSize: {
      sm: "13px",
      xl: "15px",
      lg: "14px",
    },
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .MuiDataGrid-cellContent": {
    fontSize: {
      sm: "13px",
      xl: "15px",
      lg: "14px",
    },
    fontFamily: "inherit",
  },
  "& .MuiDataGrid-columnSeparator .MuiSvgIcon-root": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    border: "1px solid #D0E2FF",
    backgroundColor: "#EDF5FF",
  },
  "& .MuiDataGrid-selectedRowCount": {
    width: "130px",
    border: `1px solid #E0E0E0`,
    height: "32px",
    marginRight: 0,
  },
  "& .MuiCheckbox-root": {
    color: "#c4c4c4",
  },
};

export const DataGrid = ({
  columns = [],
  rows = [],
  addButton,
  noSearch = false,
  url = "",
  refetchInside = false,
  ...otherProps
}) => {
  const [query, setQuery] = useState(null);
  const [data, setData] = useState([]);
  const [queryKey, setQueryKey] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 25,
  });
  
  const search = useDebounce(query || "", 1000);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  useEffect(() => {
    if (refetchInside) {
      setQueryKey(url);
    }
  }, [refetchInside, url]);

  useGetAll({
    key: url,
    params: {
      page: pagination.page,
      limit: pagination.limit,
      search: search,
    },
    enabled: refetchInside || Boolean(queryKey),
    onSuccess(data) {
      setData(data);
    },
  });

  const onPageChange = useCallback(
    (page) => {
      setPagination((prev) => ({ ...prev, page }));
      setQueryKey(
        `${url}?page=${page}&limit=${pagination.limit}&search=${search}`
      );
    },
    [url, pagination, search]
  );

  const onPageLimitChange = useCallback(
    (limit) => {
      setPagination((prev) => ({ ...prev, limit, page: 0 }));
      setQueryKey(`${url}?page=${0}&limit=${limit}&search=${search}`);
    },
    [url, search]
  );

  useEffect(() => {
    if (search) {
      setQueryKey(
        `${url}?page=${0}&limit=${pagination.limit}&search=${search}`
      );
      setPagination((prev) => ({ ...prev, page: 0 }));
    }
  }, [search, pagination.limit, url]);

  const getPaginationState = useCallback(() => {
    if (url) {
      return {
        onPageChange,
        pageSize: pagination.limit,
        onPageSizeChange: onPageLimitChange,
        page: pagination?.page,
        paginationMode: GridFeatureModeConstant.server,
      };
    } else {
      return {
        pageSize: 25,
      };
    }
  }, [url, pagination, onPageChange, onPageLimitChange]);

  const onClear = () => {
    setQuery("");
    if (url) {
      setQueryKey(`${url}?page=${pagination.page}&limit=${pagination.limit}`);
    } else {
      setData(rows);
    }
  };

  const onChange = (e) => {
    if (e.target.value) {
      setQuery(e.target.value);
    } else {
      setQueryKey(`${url}?page=${pagination.page}&limit=${pagination.limit}`);
      setQuery(e.target.value);
    }
  };

  // useEffect(() => {
  //   if (!url && search) {
  //     const newData = searchData(rows, search);
  //     setData(newData);
  //   }
  // }, [url, search, rows]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="flex items-center justify-between sm:flex-col sm:text-center tab:text-center flex-wrap"> 
        {!noSearch ? (
          <Search
            sx={{ width: "300px" ,}} //m set
            value={query}
            className="xl:h-9    2xl:h-11 mt-4"
            onClear={onClear}
            onChange={onChange}
          />
        ) : (
          <div />
        )}
        {addButton}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: !("rows" in data ? data.rows : data || []).length
            ? "500px"
            : "auto",
        }}
      >
        <MuiDataGrid
          {...otherProps}
          columns={columns}
          rows={"rows" in data ? data.rows : data || []}
          sx={dataGridStyles}
          components={{
            Pagination,
            NoRowsOverlay: NoDataOverlay,
          }}
          {...getPaginationState()}
          autoHeight={Boolean(("rows" in data ? data.rows : data || []).length)}
          rowCount={"count" in data ? data.count : data.length}
          disableSelectionOnClick
        />
      </Grid>
    </Grid>
  );
};
