import { SET_LOADING, GET_LIST_DATA, CLEAR_LIST_DATA } from '../types';

const hyperlinkReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_LIST_DATA:
      return {
        ...state,
        list: action.payload,
      };
    case CLEAR_LIST_DATA:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
};

export default hyperlinkReducer;
