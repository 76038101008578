import React, { Fragment, useState, useEffect, useContext } from "react";
import Navbar from "../../components/layouts/Navbar";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import { url } from "../../urls";
import userImg from "../../public/img/user.jpg";

import PortalContext from "../../context/portalContext/portalContext";
import UserProfileUpdate from "./UserProfileUpdate";
import PasswordUpdate from "./PasswordUpdate";
import EmailUpdate from "./EmailUpdate";
import ProfilePicUpdate from "./ProfilePicUpdate";
import serverAPI from "../../utils/serverAPI";

const UserInfoProfileUpdate = () => {
  const userAuthContext = useContext(UserAuthContext);
  const portalContext = useContext(PortalContext);

  const { loadUser, getDepartments, departments } = userAuthContext;
  const { clearError, setAlert } = portalContext;

  const [formData, setFormData] = useState({
    user: JSON.parse(localStorage.getItem("data")).USER_ID,
    full_name: "",
    department: "",
    phone: "",
    business_phone: "",
    user_role: "User",
    profile_pic: userImg,
    isActive: true,
  });

  const fetchData = async () => {
    const res = await serverAPI.get(
      `${url}/accounts/profile/${
        JSON.parse(localStorage.getItem("data")).USER_ID
      }`
    );
    setFormData({
      user: res.data.user,
      department: res.data.department,
      business_phone: res.data.business_phone
        ? res.data.business_phone
        : res.data.phone,
      full_name: res.data.full_name,
      phone: res.data.phone,
      profile_pic: res.data.profile_pic,
      user_role: res.data.user_role,
      isActive: res.data.is_active,
    });
  };

  useEffect(() => {
    getDepartments("/accounts/users");
    fetchData();
    document.title = "My Profile";
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Navbar heading="USER PROFILE" subheading="" />
      <section className="main-container">
        <main className="d-flex flex-column">
          <div className="row p-3 pb-0">
            <div className="col-md-12">
              <div
                className="py-2 px-3 rounded fw-bold d-inline dark-blue bg-light-gray"
                style={{ border: "2px solid var(--seven)" }}
              >
                Update Profile
              </div>
            </div>
          </div>
          <ProfilePicUpdate
            user={formData.user}
            loadUser={loadUser}
            setAlert={setAlert}
            clearError={clearError}
            profile_pic={formData.profile_pic}
            full_name={formData.full_name}
          />
          <UserProfileUpdate
            loadUser={loadUser}
            formData={formData}
            setAlert={setAlert}
            clearError={clearError}
            setFormData={setFormData}
            departments={departments}
          />
          <PasswordUpdate setAlert={setAlert} clearError={clearError} />
          <EmailUpdate
            setAlert={setAlert}
            clearError={clearError}
            loadUser={loadUser}
          />
        </main>
      </section>
    </Fragment>
  );
};

export default React.memo(UserInfoProfileUpdate);
