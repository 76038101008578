import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip,
  Sector,
} from "recharts";
import CardHeader from "./CardHeader";

const DashboardPieChart = ({ data, cellColors, dataKey, width, title }) => {
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;

    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      value,
      name,
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#797979"
          fontSize={13}
        >
          {`${name}: ${value}%`}
        </text>
      </g>
    );
  };

  return (
    <>
      <div className="d-flex flex-column flex-start h-100 pt-3">
        <CardHeader title={title} />
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={width}>
            <Pie
              dataKey={`${dataKey}`}
              innerRadius={80}
              outerRadius={120}
              data={data}
              paddingAngle={2}
              activeIndex={data.map((_, index) => index)}
              activeShape={renderActiveShape}
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={cellColors[index % cellColors.length]}
                />
              ))}
            </Pie>
            {/* <Legend /> */}
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default DashboardPieChart;
