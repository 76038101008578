import React, { Fragment, useEffect, useContext, useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import { FormContainer } from "../../components/layouts/commonLayouts/container/FormContainer";
import { Formik, Form } from "formik";
import { Input, Label } from "../../components/layouts/forms/inputs";
import { Box, Grid, IconButton, InputAdornment } from "@mui/material";
import { useCreateOrUpdate, useGetAll } from "../../hooks";
import { Button } from "../../components/layouts/commonLayouts/buttons";
import * as yup from "yup";
import { toast } from "../../utils/toaster";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordConfig = ({ history }) => {
  const userAuthContext = useContext(UserAuthContext);
  const { user } = userAuthContext;

  const [show, setShow] = useState(false);

  const { data: configData, refetch } = useGetAll({
    key: `/portal/emailconf`,
  }); 

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      refetch();
      document.title = "Email Configuration";
    }
    // eslint-disable-next-line
  }, []);

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/portal/emailconf`,
    method: "post",
    onSuccess: () => refetch(),
  });

  const initialState = {
    email: "",
    password: "",
    port_number: "",
  };

  const handleClickShowPassword = () => setShow((show) => !show);

  const validationSchema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
    port_number: yup.string().required("Port Number is required"),
  });

  return (
    <Fragment>
      <Navbar heading="EMAIL CONFIGURATION" subheading="" />
      <section
        className="main-container d-flex justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <FormContainer>
          <Formik
            initialValues={{
              ...initialState,
              ...configData,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              mutate({ ...values, updated_by: user.USER_ID });
              toast(`Email configuration saved successfully`);
            }}
            >
            <Form>
              <Grid container spacing={4}>
                <Grid xs={12} item>
                  <Input
                    className="mb-2"
                    name="email"
                    label="Email"
                    required
                    helperText={
                      "All notifications and communications will be sent by this email."
                    }
                  />
                </Grid>

                <Grid xs={12} item>
                  <Input
                    className="mb-2"
                    name="password"
                    label="Password"
                    type={show ? "text" : "password"}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {show ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid id xs={12} item>
                  <Input
                    className="mb-2"
                    name="port_number"
                    label="Port"
                    required
                  />
                </Grid>
              </Grid>

              <Grid xs={12} item className="flex">
                <Label text={"Last Updated At : "} /> {configData?.updated_at}
              </Grid>

              <Grid xs={12} item>
                <Box className="flex justify-end mt-8">
                  <Button
                    isLoading={isLoading}
                    variant="contained"
                    className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Form>
          </Formik>
        </FormContainer>
      </section>
      {/* <section
        className="main-container d-flex justify-content-center align-items-center"
        style={{ height: "85vh" }}
      >
        <div className="p-5 col-md-6 border">
          <h1
            className="fs-5 dark-blue"
            style={{ borderBottom: "2px solid var(--ninth)" }}
          >
            ITSM Email Notification Configuration
          </h1>{" "}
          <form>
            <div className="row mb-4 mt-5">
              <label
                htmlFor="email"
                style={{ fontWeight: "500" }}
                className="col-form-label col-md-4 dark-blue fs-7"
              >
                Email <span className="d-inline text-danger"> *</span>
              </label>
              <div className="col-md-8 ps-0">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ outline: "none" }}
                />
                <small id="email" className="form-text text-muted">
                  All notifications and communications will be sent by this
                  email.
                </small>
              </div>
            </div>
            <div className="row mt-4">
              <label
                htmlFor="password"
                style={{ fontWeight: "500" }}
                className="col-form-label col-md-4 dark-blue fs-7"
              >
                Password <span className="d-inline text-danger"> *</span>
              </label>
              <div className="col-md-8 ps-0" style={{ position: "relative" }}>
                <input
                  type="password"
                  name="password"
                  id="password"
                  defaultValue={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  style={{ outline: "none" }}
                />
                <div className="emailConfigPass" onClick={toggleShowPassword}>
                  <i className="far fa-eye-slash eye-open fs-6"></i>
                </div>
                <small id="password" className="form-text text-muted">
                  Password for this email.
                </small>
              </div>
            </div>
            <div className="row mt-4">
              <label
                htmlFor="port_number"
                style={{ fontWeight: "500" }}
                className="col-form-label col-md-4 dark-blue fs-7"
              >
                Port Number <span className="d-inline text-danger"> *</span>
              </label>
              <div className="col-md-8 ps-0" style={{ position: "relative" }}>
                <input
                  type="text"
                  name="port_number"
                  id="port_number"
                  defaultValue={port_number}
                  onChange={(e) => setPort(e.target.value)}
                  className="form-control"
                  style={{ outline: "none" }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between my-3 align-items-center">
              {updated_at !== "" ? (
                <div className="text-muted mb-0 pt-2">
                  Last Updated At : {updated_at !== "" ? updated_at : " -"}
                </div>
              ) : (
                ""
              )}
              <button
                className="btn white bg-blue fw-bold ms-auto"
                type="submit"
                onClick={onSubmit}
                style={{
                  boxShadow: "0 4px 10px #109bf140",
                }}
              >
                {emailConfigData.length === 0 ? "Add" : "Update"}
              </button>
            </div>
          </form>
        </div>
      </section> */}
    </Fragment>
  );
};

export default PasswordConfig;
