import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox/delete";
import { useGetAll } from "../../hooks";
import { ProductSubCategoryDialog } from "../../components/AdminPanelLayout/AddProdSubCategoryNew";

const ProductSubCategory = ({ match, history }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = `All Sub Categories Of ${match.params.name}`;
    }
    //eslint-disable-next-line
  }, []);

  const location = useLocation();
  const { title } = location.state ? location.state : "";
  const { data: productsSubCategory } = useGetAll({
    key: `/requests/subcategory/${match.params.id}`,
    params: {
      page: 0,
      limit: 25,
    },
  });
  const columns = [
    {
      headerName: "Sub Category",
      field: "sc_name",
      flex: 1,
    },

    {
      headerName: "Actions",
      field: "actions",
      flex: 1.2,
      renderCell: ({ row }) => (
        <div className="flex items-center">
          <ProductSubCategoryDialog isUpdate data={row} />
          <div className="border rounded-xl py-2 mx-3"></div>

          <DeleteBox
            title="Product Sub Category"
            url="/requests/subcategory"
            data={row.id}
            refetchUrl={`/requests/subcategory/${match.params.id}`}
          >
            Do you really want to delete this Product Sub Category?
          </DeleteBox>
        </div>
      ),
    },
  ];
  return (
    <>
      <Navbar
        heading={`${title ? title.toUpperCase() : " "} SUB CATEGORIES`}
        subheading=""
      />
      <section className="main-container">
        <DataGrid
          columns={columns}
          url={`/requests/subcategory/${match.params.id}`}
          rows={productsSubCategory}
          addButton={
            <div>
              <ProductSubCategoryDialog />
            </div>
          }
        />
      </section>
    </>
  );
};

export default React.memo(ProductSubCategory);
