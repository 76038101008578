import React, { useState } from "react";
import { tenantApiUrl } from "../../urls";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import serverAPI from "../../utils/serverAPI";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../components/layouts/forms/inputs";
import { Button } from "../../components/layouts/commonLayouts/buttons";
const initialState = {
  full_name: "",
  email: "",
  password: "",
  phone: "",
  job_title: "",
  domain: "",
  company_name: "",
  no_of_employee: 1,
};

const validations = yup.object({
  full_name: yup.string().required("Full Name required"),
  email: yup
    .string()
    .email("Valid email required")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
  phone: yup.string().required("Phone number required"),
  job_title: yup.string().required("Job Title required"),
  company_name: yup.string().required("Company Name required"),
  no_of_employee: yup
    .number()
    .min(1, "At least 1 employee required")
    .required("No. of Employees is required"),
  domain: yup.string().required("Domain name required"),
  // .matches(
  //   "^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$",
  //   "Valid Domain Name required"
  // ),
});
const TenantRegister = (props) => {
  const [alert, setAlert] = useState({ msg: "", type: "success" });
  const [isLoading, setIsLoading] = useState(false);

  const saveTenant = async (values, { resetForm, setSubmitting }) => {
    values = {
      ...values,
      no_of_employee: props.location.state,
      email: values.email.toLowerCase(),
    }
    console.log("values are ::", values);
    setIsLoading(true);
    //e.preventDefault();
    try {
      const res = await serverAPI.post(`${tenantApiUrl}/tenants/user/nt/`, {
        ...values,
      });
      if (res.status === 201) {
        try {
          const res2 = await serverAPI.post(
            `${tenantApiUrl}/tenants/tenant/nt/`,
            {
              ...values,
              user: res.data.id,
              domain: values.domain + ".bizitsm.com",
            }
          );

          if (res2.status === 201) {
            const newDomain = `https://${values.domain}.bizitsm.com`;
            window.location.href = newDomain;
            setIsLoading(false);
            setAlert({ type: "success", msg: res2.data.msg });
          }
        } catch (error) {
          const obj = error.response.data;
          setIsLoading(false);
          setAlert({
            msg: `${Object.keys(obj)[0]} -> ${obj[Object.keys(obj)[0]][0]}`,
            type: "error",
          });
        }
      }
      resetForm();
    } catch (error) {
      resetForm();
      const obj = error.response.data;
      setIsLoading(false);
      setAlert({
        msg: `${Object.keys(obj)[0]} -> ${obj[Object.keys(obj)[0]][0]}`,
        type: "error",
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: "888" }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {alert.msg && <Alert severity={alert.type}>{alert.msg}</Alert>}

      <Grid container>
        <Grid item md={6} xs={15} className="sm:hidden">
          <Box className="h-[100%] relative">
            <img src="register.png" className="object-cover  h-[100%] w-[100%] bg-black" />
            <Box className="glass text-base  absolute bottom-[80px]  p-8 left-1/2 translate-x-[-50%]  text-center w-3/4 text-white">
              Transforming IT service management for a better <br />
              tomorrow
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          className="flex items-center justify-center flex-col"
        >
          <Box className="flex items-start justify-center mt-10  flex-col w-3/5 py-10 sm:py-0 sm:w-[90%]">
          <Typography className="text-4xl text-[#2F9DD0] font-bold">
              Create Account
            </Typography>
            <Formik
              initialValues={initialState}
              onSubmit={saveTenant}
              validationSchema={validations}
            >
              {({ values, submitForm, errors }) => (
                <div className="flex w-[120%] flex-col gap-3 sm:w-[100%] ">
                  {console.log(errors, "ERROS")}
                  <Input
                    name="full_name"
                    defaultValue={values.full_name}
                    placeholder="Full Name"
                    className="my-2"
                    autoComplete="off"
                    outline
                  />
                  <Input
                    name="email"
                    defaultValue={values.email}
                    placeholder="Email"
                    className="my-2"
                    autoComplete="off"
                    outline
                  />
                  <Input
                    name="phone"
                    defaultValue={values.phone}
                    placeholder="Phone"
                    className="my-2"
                    autoComplete="off"
                    outline
                  />
                  <Input
                    name="password"
                    defaultValue={values.password}
                    placeholder="Password"
                    type="password"
                    className=" my-2"
                    autoComplete="off"
                    outline
                  />
                  {/* <Box className="flex w-100 items-center justify-between gap-3"> */}
                    <Input
                      name="job_title"
                      defaultValue={values.job_title}
                      placeholder="Job Title"
                      autoComplete="off"
                      outline
                      className=" my-2"
                      />
                    <Input
                      name="domain"
                      defaultValue={values.domain}
                      placeholder="Domain"
                      autoComplete="off"
                      outline
                      className=" my-2"
                      />
                  {/* </Box> */}
                  {/* <Box className="flex w-100 items-center justify-between gap-3"> */}
                    <Input
                      name="company_name"
                      defaultValue={values.company_name}
                      placeholder="Company Name"
                      autoComplete="off"
                      outline
                      // className="w-1/2"
                    />

                    <Input

                      name="no_of_employee d"
                      type="number"
                      className=" my-2"
                      disabled={props.location.state != undefined ? true : false}
                      defaultValue={props.location.state}
                      InputProps={{ inputProps: { min: 1 } }}
                      placeholder="No. of Employee"
                      autoComplete="off"
                      outline
                    />
                  {/* </Box> */}
                  <Button
                    variant="contained w-full bg-[#2F9DD0] text-white rounded-md h-12 mt-4"
                    onClick={() => submitForm()}
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Register
                  </Button>
                </div>
              )}
            </Formik>
          </Box>
        </Grid>
        {/*
        
            margin: 0 !important;
    left: 50%;
    transform: translateX(-50%);
        <Grid item xs={12} md={6} className="h-screen">
          <Box className="h-full relative">
            <Box
              className="glass p-7 w-3/4 absolute bottom-0 left-1/2 text-white text-base text-center"
              sx={{ transform: "translate(-50%,-50%)" }}
            >
              Transforming IT service management for a better <br />
              tomorrow
            </Box>
            <img
              alt="Banner Image"
              src="register.png"
              layout="fill"
              style={{
                zIndex: -100,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          className="flex items-center justify-center flex-col"
        >
          <Formik
            initialValues={initialState}
            onSubmit={saveTenant}
            validationSchema={validations}
          >
            {({ values, submitForm }) => (
              <>
                <Label text={"Name"} className="my-2" />
                <Input name="full_name" defaultValue={values.full_name}></Input>
                <Label text={"Email"} className="my-2" />
                <Input name="email" defaultValue={values.email}></Input>
                <Label text={"Password"} className="my-2" />
                <Input
                  name="password"
                  type="password"
                  defaultValue={values.password}
                ></Input>
                <Label text={"Phone"} className="my-2" />
                <Input name="phone" defaultValue={values.phone}></Input>
                <Label text={"Job Title"} className="my-2" />
                <Input name="job_title" defaultValue={values.job_title}></Input>
                <Label text={"Company Name"} className="my-2" />
                <Input
                  name="company_name"
                  defaultValue={values.company_name}
                ></Input>
                <Label text={"Domain"} className="my-2" />
                <Input name="domain" defaultValue={values.domain}></Input>
                <Label text={"No of Employee"} className="my-2" />
                <Input
                  name="no_of_employee"
                  InputProps={{ inputProps: { min: 1 } }}
                  type="number"
                  defaultValue={values.no_of_employee}
                ></Input>
                <Button onClick={() => submitForm()} className="mt-4">
                  Register
                </Button>
              </>
            )}
          </Formik>
        </Grid> */}
      </Grid>
    </>
  );
};

export default TenantRegister;
