import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

const WebsiteLayout = ({ children }) => {
  return (
    <div className="phone:overflow-x-hidden">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default WebsiteLayout;
