import React, { useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { Typography } from "@mui/material";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox";

import { OtherRequestDialog } from "../../components/requests";
import RequestCard3 from "./RequestCard3";
import { useMediaQuery } from "@mui/material";
import { PageHeader } from "../../components/layouts/pageHeader";
const OtherRequest = ({ history }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  useEffect(() => {
    if (
      !JSON.parse(localStorage.getItem("data")).in_assets_team &&
      JSON.parse(localStorage.getItem("data")).user_role !== "Admin"
    ) {
      history.push("/view-status");
    } else {
      document.title = "Other Requests";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "page_view",
        page_data: {
          country: "India",
          language: "en",
          page_url: window.location.href,
          page_category: "Request Management",
          page_location: "OtherRequest",
          page_referrer: document.referrer,
          page_title: document.title,
          region: "Pune",
          site_section: "Support",
        },
        user_data: {
          user_id: JSON.parse(localStorage.getItem("data")).user_id,
          user_login_state: "User",
          user_type: JSON.parse(localStorage.getItem("data")).user_role,
        },
      });
      console.log(window.dataLayer);
    }
    // eslint-disable-next-line
  }, []);

  const { data } = useGetAll({
    key: "/requests/product/request/0",

    params: {
      page: 0,
      limit: 25,
    },
  });

  //   const onDelete = async () => {
  //     const res = await serverAPI.delete(
  //       `${url}/requests/product/request/${item.id}`
  //     );
  //     if (res.status === 204) {
  //       getOtherRequests("0");
  //     }
  //   };

  const columns = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1.5,
    },
    {
      headerName: "Reference Link",
      field: "ref_link",
      flex: 1,
    },
    {
      headerName: "Requested By",
      field: "requested_by",
      flex: 1,
      //TODO: API CALL
      renderCell: ({ row }) => {
        return (
          <>
            <Typography>
              {row.requested_by?.profile?.full_name ?? ""}
            </Typography>
          </>
        );
      },
    },
    {
      headerName: "Available",
      field: "available",
      flex: 1,
      align: "center",
      renderCell: ({ row }) => {
        return <>{row.available ? "Yes" : "No"}</>;
      },
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1.2,
      renderCell: ({ row }) => {
        return (
          <>
            {isMobile ? (
              data?.rows.map((row, index) => (
                <RequestCard3 key={index} row={row} />
              ))
            ) : (
              <>
                <OtherRequestDialog data={row} />
                <div className="border py-2 mx-3"></div>
                <DeleteBox
                  title={"Request"}
                  data={row.id}
                  url={`/requests/product/request`}
                  refetchUrl={`/requests/product/request/0`}
                ></DeleteBox>
              </>
            )}
          </>
        );
      },
    },
  ];
  console.log("Data from other Req", data);
  return (
    <>
      <Navbar heading="OTHER REQUESTS" subheading="" />
      <PageHeader header={"OTHER REQUESTS"} className="mb-4" />
      {isMobile ? (
        data?.rows?.map((row, index) => <RequestCard3 key={index} row={row} />)
      ) : (
        <DataGrid
          columns={columns || []}
          rows={data || []}
          url={"/requests/product/request/0"}
        />
      )}
    </>
  );
};

export default OtherRequest;
