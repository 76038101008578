import React from "react";
import ReactApexChart from "react-apexcharts";
import CardHeader from "./CardHeader";

const DashboardLineChart = ({ data, title, colors, categories }) => {
  let options = {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: colors,
      width: 3,
    },

    fill: {
      colors: ["#ffffff", "#ffffff"],
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "#889696",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#889696",
        },
      },
    },
    legend: {
      labels: {
        colors: colors,
      },
    },
  };

  return (
    <div className="d-flex flex-column flex-start h-100 pt-3 bg-white"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 6px 18px',
      }}
    >
      <CardHeader title={title} onChange={() => {}} />
      <ReactApexChart
        options={options}
        series={data ? data : []}
        type="area"
        height={"90%"}
      />
    </div>
  );
};

export default DashboardLineChart;
