import React, { Fragment, useState } from "react";
import { url } from "../../urls";
import SuccessAlert from "../alerts/SuccessAlert";
import serverAPI from "../../utils/serverAPI";

const PreviewConfigurationItem = ({ item, getConfigItems }) => {
  const [data, setData] = useState(item);
  const [error, setError] = useState(null);
  const updateConfigurationItem = async (e) => {
    e.preventDefault();
    const res = await serverAPI.put(`${url}/portal/items/${data.id}`, data);
    if (res.status === 202) {
      setError({
        msg: "Configuration Item Updated Successfully.",
        type: "success",
        icon: "check-circle",
      });

      getConfigItems();
    } else {
      setError({
        msg: "Something Went Wrong Please Try Again!",
        type: "danger",
        icon: "exclamation-triangle",
      });
    }
    setTimeout(() => setError(null), 5000);
  };
  return (
    <Fragment>
      <td
        style={{
          width: "30px",
          borderBottom: "none",
          marginLeft: "auto",
        }}
      >
        <i
          className="far fa-edit blue fs-6"
          data-bs-toggle="modal"
          data-bs-target={`#PrevConfigModel${item.id}`}
        ></i>

        <div
          className="modal fade"
          id={`PrevConfigModel${item.id}`}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title dark-blue" id="exampleModalLabel">
                  Update Configuration Item
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {error ? (
                <div className="col-md-11 mx-auto mt-3">
                  <SuccessAlert data={error} />
                </div>
              ) : (
                ""
              )}
              <form onSubmit={updateConfigurationItem}>
                <div className="modal-body">
                  <div className="row mb-3">
                    <label
                      htmlFor="name"
                      style={{ fontWeight: "500" }}
                      className="col-form-label col-md-4 dark-blue fs-7"
                    >
                      Item Name <span className="d-inline text-danger"> *</span>
                    </label>
                    <div className="col-md-8 ps-0">
                      <input
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={(e) =>
                          setData({ ...data, [e.target.name]: e.target.value })
                        }
                        className="form-control"
                        id="name"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="description"
                      style={{ fontWeight: "500" }}
                      className="col-form-label col-md-4 dark-blue fs-7"
                    >
                      Item Description
                    </label>
                    <div className="col-md-8 ps-0">
                      <textarea
                        name="description"
                        value={data.description}
                        style={{ height: "100px" }}
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="form-control"
                        id="description"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn white bg-blue fw-bold"
                    style={{
                      boxShadow: "0 4px 10px #109bf140",
                    }}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </td>
    </Fragment>
  );
};

export default PreviewConfigurationItem;
