import { Box, Grid, Typography } from "@mui/material";
import { Button } from "../../layouts/commonLayouts/buttons";
import { Dialog } from "../../layouts/commonLayouts/dialogBox/dialog";
import defaultImg from "../../../public/img/default_product.png";
import defaultserviceImg from "../../../public/img/default_service.png";
import { url, mediaUrl } from "../../../urls";
import { colors } from "../../../constants/theme";
import { PrimaryAccordion } from "../../layouts/commonLayouts/accordion/PrimaryAccordion";

export const ServiceDetailDialoge = ({ data, history }) => {
  return (
    <Dialog
      title="Details"
      button={
        <Button
          variant="contained"
          className="px-4 py-2 ml-1 mb-3 capitalize xl:text-sm  2xl:text-semi-base h-9"
        >
          Select
        </Button>
      }
    >
      {({ onClose }) => (
        <Grid container className="sm:flex-col max-w-full">
          <Grid
            item
            xs={6}
            className="border-end d-flex justify-content-center py-4 px-2 align-items-center sm:max-w-full sm:py-0 sm:px-0"
          >
            <img
              src={
                data?.image
                  ? mediaUrl + data?.image
                  : data?.product_type === "Product"
                  ? defaultImg
                  : defaultserviceImg
              }
              alt={data?.name ? data?.name : ""}
              style={{ width: "90%" }}
              className="img-fuild w-[100%]"
            />
          </Grid>
          <Grid item xs={6} className="pt-2 pl-4 sm:max-w-full">
            <Typography
              variant="h3"
              className="fs-5 text-uppercase mb-2 pb-0 sm:max-w-full"
              color={colors?.primary?.dark}
              style={{ borderBottom: "2px solid #dee2e6" }}
            >
              {data?.name ? data?.name : ""}
            </Typography>
            <Typography
              variant="h6"
              color={colors?.tertiary?.dark}
              className="text-capitalize block sm:max-w-full"
            >
              {data.category ? data.category.name : "-"}
            </Typography>
            <Typography
              variant="body"
              color={colors?.tertiary?.dark}
              className="text-capitalize block sm:max-w-full "
            >
              {data.sub_category ? data.sub_category.sc_name : "-"}
            </Typography>
            {data.price !== "-" && (
              <Typography
                variant="h6"
                color={colors?.tertiary?.dark}
                className="text-capitalize block sm:max-w-full"
              >
                {data.price}
              </Typography>
            )}
            <div className="mt-3 sm:text-[15px] sm:max-w-full ">
              <PrimaryAccordion header={"Description"}>
                <Typography className="ml-2 sm:max-w-full">{data?.description}</Typography>
              </PrimaryAccordion>
            </div>
            <div className="mt-3 sm:text-[15px] sm:sm:max-w-full">
              <PrimaryAccordion header={"Additional Info"}>
                <Typography className="ml-2 sm:max-w-full">
                  {data?.additional_detail}
                </Typography>
              </PrimaryAccordion>
            </div>
            <Grid xs={12} item>
              <Box className="mt-5 d-flex justify-content-end">
                <Button
                  color="secondary"
                  className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                  variant="contained"
                  onClick={onClose}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                  type="submit"
                  onClick={() => {
                    history.push(
                      `/create-new-request/${
                        data?.product_type === "Product" ? "product" : "service"
                      }?name=${data?.name.replace(" ", "-")}&price=${
                        data?.price
                      }&id=${data?.id}`
                    );
                  }}
                >
                  Select
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};
