import React from "react";
import { Line } from "react-chartjs-2";

function Trend({ name, data, options }) {
  return (
    <div
      className="
              bg-white
              d-flex
              flex-column
              justify-content-around
            "
      style={{
        boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)",
        height: "48%",
      }}
    >
      <h2
        className="fs-6 pt-3 ps-3 text-left sec-gary"
        style={{ width: "100%" }}
      >
        {name} TREND
      </h2>
      <div className="px-2 pb-4">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}
export default Trend;
