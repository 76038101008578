import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { useGetAll } from "../../hooks";
import { columns } from "./RequestsColumns";
import RequestCard from "./RequestCard";
import { useMediaQuery } from "@mui/material";
import RequestCard2 from "./RequestCard2";
import { PageHeader } from "../../components/layouts/pageHeader";
const FullFillmentReport = ({ history }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  useEffect(() => {
    if (
      !JSON.parse(localStorage.getItem("data")).in_assets_team &&
      JSON.parse(localStorage.getItem("data")).user_role !== "Admin"
    ) {
      history.push("/requests-raised-by-me");
    } else {
      document.title = "All Requests For Return & Replace";
      window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page_data: {
        country: 'India',
        language: 'en',
        page_url: window.location.href,
        page_category: 'Request Management',
        page_location: 'AllRequestForReturnAndReplace',
        page_referrer: document.referrer,
        page_title: document.title,
        region: 'Pune',
        site_section: 'Support',
      },
      user_data: {
        user_id: JSON.parse(localStorage.getItem("data")).user_id,
        user_login_state: 'User',
        user_type: JSON.parse(localStorage.getItem("data")).user_role,
      }
    });
    console.log(window.dataLayer)
    }
    // eslint-disable-next-line
  }, []);

  const { data } = useGetAll({
    key: "requests/open-approved/v2",
    params: {
      page: 0,
      limit: 25,
    },
  });
  console.log("mosin",data);
  
  return (
    <>
      <Navbar
        heading={"All Requests For Return & Replace".toUpperCase()}
        subheading=""
      />
      <PageHeader header={"All Requests For Return & Replace"} className="mb-4" />
      {isMobile ? (
        data?.rows?.map((row, index) => <RequestCard2 key={index} row={row}/>)

       ) :(
      <DataGrid
        columns={columns}
        rows={data || []}
        url={"/requests/open-approved/v2"}
      />)}
    </>
  );
};

export default FullFillmentReport;
