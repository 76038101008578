import { Add, Edit } from "@carbon/icons-react";
import { Grid, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Form, Formik } from "formik";
import { Input } from "../../../components/layouts/forms/inputs";
import { Button } from "../../../components/layouts/commonLayouts/buttons";
import { toast } from "../../../utils/toaster";
import { useGetAll } from "../../../hooks";
import serverAPI from "../../../utils/serverAPI";
import { url } from "../../../urls";

const NotesForm = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const { data } = useGetAll({
    key: `/problems/notes/${id}`
  });
  const initialState = {
    additional_comments: "",
    work_notes: "",
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);
  if (loading) {
    return (
      <Box className="d-flex justify-content-center align-items-center w-full" style={{ height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Formik
        initialValues={{ ...initialState, ...data }}
        onSubmit={async(values, { resetForm }) => {
          const payload ={
            additional_comments: values.additional_comments|| "",
            work_notes: values?.work_notes || "",
          }
          const res = await serverAPI.put(
            `${url}/problems/notes/${id}`,
            payload,
          );
          if (res?.status === 202) {  
            console.log("Form submitted with values:", values);
            toast(`Clouser added successfully`);
          }
        }}
      >
        <Form>
          <Grid container spacing={3} className="p-4 sm:flex-col justify-content-left sm:grid-cols-1 tab:flex-col tab:grid-cols-1">
            <Grid item xs={11} >
              <Input name="work_notes" label="Work Notes" multiline rows={2} required />
            </Grid>
            <Grid item xs={11}>
              <Input name="additional_comments" label="Activity" multiline rows={2} required />
            </Grid>
            <Grid item xs={12}>
              <Box className="mt-5 d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="capitalize ml-4 px-4 xl:text-sm 2xl:text-semi-base"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default NotesForm;
