import { useMutation } from "react-query";
import errorHandler from "../utils/errorHandler";
import serverAPI from "../utils/serverAPI";

export default function useCreateOrUpdate({
  url,
  method = "post",
  refetch,
  onSuccess,
}) {
  function sendData(data) {
    return serverAPI[method](url, data, {});
  }

  return useMutation(sendData, {
    onSuccess: (response, variables, context) => {
      onSuccess && onSuccess(response, variables, context);
      refetch && refetch();
    },
    onError: (data) => {
      errorHandler(data);
    },
  });
}
