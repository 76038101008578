import React from "react";
import { Link } from "react-router-dom";
import {
  DownwardTrendArrow,
  LinkIcon,
  UpwardTrendArrow,
} from "../../public/img/Icons";

const DashboardCard = ({ title, info, count, url, is_cancelled }) => {
  return (
    <div className="dashboard-card">
      <p className="mb-0" style={{ fontSize: "15px", fontWeight: "500" }}>
        {title}
      </p>
      <div className="d-flex align-items-center">
        <h3 className="me-2 fw-7 mb-0"> {count}</h3>
        <Link to={url}>
          {" "}
          <LinkIcon />
        </Link>{" "}
      </div>
      <p
        className="mb-0"
        style={{
          fontSize: "12px",
          color: `${
            info?.toLowerCase()?.includes("no changes")
              ? "#889696"
              : is_cancelled
              ? "#00947D"
              : "#F7685B"
          }`,
        }}
      >
        {info
          ? !info?.toLowerCase()?.includes("no changes") &&
            (is_cancelled ? <DownwardTrendArrow /> : <UpwardTrendArrow />)
          : ""}{" "}
        {info}
      </p>
    </div>
  );
};

export default DashboardCard;
