import { Grid, Box } from "@mui/material";
import React from "react";
import { AutoComplete, Input, Checkbox } from "../layouts/forms/inputs";
import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
import { Button } from "../layouts/commonLayouts/buttons";
import { Form, Formik } from "formik";
import { useGetAll } from "../../hooks";
import { useCreateOrUpdate } from "../../hooks/useCreateOrUpdate";
import { useQueryClient } from "react-query";
import { toast } from "../../utils/toaster";
import serverAPI from "../../utils/serverAPI";
import { url } from "../../urls";
import { useState } from "react";
import * as yup from "yup";

const initialValues = {
  full_name: "",
  email: "",
  department: {},
  user_role: "",
  phone: "",
  business_phone: "",
  is_active: false,
};
const validations = yup.object({});

const UserDialouge = ({ data, text }) => {
  const [srcImg, setSrcImg] = useState("");

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/accounts/profile/${data.user.id}`,
    method: "put",
    onSuccess: async(_,values) => {
      queryClient.refetchQueries("/accounts/v2/unapprovedprofiles");
      queryClient.refetchQueries("/accounts/v2/approvedprofiles");

      if (data.user.email !== values.email) {
        console.log(
          "email not equal",
          data.user.email,
          values.email
        );
        await serverAPI
          .post(`${url}/accounts/change/email`, {
            email: data.user.email.toLocaleLowerCase(),
            id: data.user.id,
          })
          .then(() => {
          toast(`User updated successfully`);
            
          });
      } 

      toast(`User updated successfully`);
    },
  });

  // console.log("data is ", data);
  const { data: allDepartment } = useGetAll({
    key: "/portal/departments/0",
    params: {
      page: 0,
      limit: 25,
    },
  });

  return (
    <Dialog title="USER PREVIEW" button={text}>
      {({ onClose }) => (
        <Formik
          initialValues={{
            ...initialValues,
            ...data,
            email: data?.user?.email,
          }}
          validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            mutate(
              {
                ...values,
                department: values.department?.id,
                user: values.user?.id,
              },
              {
                onSuccess: () => {
                  resetForm()
                  onClose();
                },
              }
            );
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container columnSpacing={4} className="mt-2">
                <Grid xs={12} item className="mx-auto">
                  <div
                    className="mx-auto my-3"
                    style={{
                      width: "220px",
                      height: "200px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={
                        srcImg
                          ? srcImg
                          : `${url}/media${data.profile_pic.split("media")[1]}`
                      }
                      alt={values.full_name}
                      style={{
                        height: "200px",
                        width: "220px",
                      }}
                      className="img-fluid rounded-circle"
                      // id="img-preview"
                    />
                    <span className="iconwrapper">
                      <input
                        name="profile_pic"
                        id="profile_pic"
                        className="form-control mx-auto dp-update"
                        type="file"
                        accept="image/*"
                        onChange={async (e) => {
                          let file = e.target.files[0];
                          const reader = new FileReader();
                          reader.onload = () => {
                            setSrcImg(reader.result);
                          };

                          reader.readAsDataURL(file);
                          setFieldValue("profile_pic", file);
                          //  console.log("file url is ", file);
                          // if (file?.name) {
                          // let data = URL.createObjectUrl(file);
                          // console.log("file url is ", data);
                          // let link = await getBase64(e.target.files[0],setFieldValue);
                          //   console.log("file url is ", data);
                          // }
                        }}
                      />
                      <i className="fas fa-camera text-light bg-blue camera-icon"></i>
                    </span>
                  </div>
                </Grid>
                <Grid xs={6} item>
                  <Input
                    className="mt-2"
                    name="full_name"
                    label="Full Name"
                    required
                    defaultValue={values?.name ? values?.name : "-"}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Input
                    className="mt-2"
                    name="email"
                    label="Email"
                    required
                    defaultValue={values?.user.email ? values?.user.email : "-"}
                  />
                </Grid>
                <Grid xs={6} item>
                  <AutoComplete
                    className="mt-2"
                    name="department"
                    label="Department"
                    required
                    defaultValue={values?.department ? values?.department : "-"}
                    getOptionLabel={(opt) => opt?.name || ""}
                    options={allDepartment.rows || []}
                  />
                </Grid>
                <Grid xs={6} item>
                  <AutoComplete
                    className="mt-2"
                    name="user_role"
                    label="User Role"
                    required
                    defaultValue={values?.user_role ? values?.user_role : "-"}
                    options={["Service Manager", "Agent", "Admin"]}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Input
                    className="mt-2"
                    name="phone"
                    label="Mobile Number"
                    required
                    defaultValue={values?.phone ? values?.phone : "-"}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Input
                    className="mt-2"
                    name="business_phone"
                    label="Buiseness Number"
                    defaultValue={
                      values?.business_phone ? values?.business_phone : "-"
                    }
                  />
                </Grid>
                <Grid xs={6} item>
                  <Checkbox
                    className="mt-2 pt-[30px]"
                    name="is_active"
                    label="Is Active"
                    required
                    checked={values?.is_active ? values?.is_active : false}
                    onChange={(_, checked) => {
                      setFieldValue("is_active", checked);
                    }}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Box className="mt-5 d-flex justify-content-end">
                    <Button
                      color="secondary"
                      className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                      variant="contained"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      isLoading={isLoading}
                      variant="contained"
                      className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default UserDialouge;
