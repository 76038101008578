import React, { Fragment, useContext, useEffect } from "react";
import UserAuthContext from "../../context/userAuthContext/userAuthContext";
import Navbar from "./Navbar";
import SideNavbar from "./SideNavbar";
import NotFound from "../../public/img/404 Error Page not Found with people connecting a plug.gif";

const PageNotFound = () => {
  const userAuthContext = useContext(UserAuthContext);
  const { loadUser } = userAuthContext;
  useEffect(() => {
    loadUser();
    document.title = "Page Not Found | ITSM";
    // eslint-disable-next-line
  }, []);

  console.log("In 404");
  return (
    <Fragment>
      <SideNavbar />
      <Navbar heading="404 NOT FOUND" toHome={true} />
      <div className="text-center">
        <section
          className="main-container d-flex align-items-center flex-column justify-content-center"
          style={{ height: "100% !important", marginTop: "4rem" }}
        >
          <img src={NotFound} alt="404 Page Not Faound" />
          <h1 className="h1 fs-1 py-3 fw-bold dark-blue pt-0">OOPS! 404</h1>
          <h3 className="h3 fs-5 dark-blue">
            Hey I am afraid that the page you are looking does not exist on
            ITSM!
          </h3>
        </section>
      </div>
    </Fragment>
  );
};

export default PageNotFound;
