import { onError } from "../utils/onError";
import { useGetAll } from "./useGetAll";
import { url } from "../urls";

export const useDownaloadFile = (path, onSuccess) => {
  return useGetAll({
    key: path,
    select: (data) => data?.data,
    enabled: false,
    onSuccess: (data) => {
      // console.log(data, "data");
      let a = document.createElement("a");
      a.href = `${url}/${data}`;
      // console.log(a.href, "a href");
      a.target = "_blank";
      // a.download =
      //   data?.message.split("/")[data?.message.split("/")?.length - 1];
      a.download = a.href;
      // console.log(a.download, "donload link");
      a.click();
      onSuccess && onSuccess();
    },
    onError(err) {
      onError(err);
    },
  });
};
