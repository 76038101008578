  import { Add, Edit } from "@carbon/icons-react";
  import { Grid, Box } from "@mui/material";
  import React from "react";
  import { Input } from "../layouts/forms/inputs";
  import { Dialog } from "../layouts/commonLayouts/dialogBox/dialog";
  import { Button } from "../layouts/commonLayouts/buttons";
  import { useCreateOrUpdate } from "../../hooks/useCreateOrUpdate";
  import { useQueryClient } from "react-query";
  import * as yup from "yup";
  import { Form, Formik } from "formik";
  import { toast } from "../../utils/toaster";
  import { useGetAll } from "../../hooks";
  import { AutoComplete } from "../layouts/forms/inputs";

  const initialState = {
    name: "",
    head: {},
    users: [],
  };

  const subcategoryValidations = yup.object({
    name: yup
      .string()
      .trim("This field cannot include leading and trailing spaces")
      .strict(true)
      .required("Name is required")
      .typeError("Name must be a string"),
    head: yup.object().required("Head is required").typeError("Please select valid head"),

    // head: yup.object().shape({
    //   id: yup.number().required("Head is required"),
    //   full_name: yup.string().required("Head name is required"),
    // }).required("Head is required"),
  });


  export const SubCategoryDialog = ({ isUpdate = false, data, id }) => {
    const queryClient = useQueryClient();


    const { data: managerData } = useGetAll({
      key: `/accounts/managers`,
    });
    const { data: usersData } = useGetAll({
      key: `/accounts/usernamewithid`,
    });

    const { mutate, isLoading } = useCreateOrUpdate({
      url: isUpdate
        ? `/portal/subcategories/${data.id}`
        : "/portal/subcategories/0",
      method: isUpdate ? "put" : "post",
    });
    // console.log(usersData,"'''''''''''''''''''''")
    console.log(managerData,"~~~~~~~~~~~~~~~~~~~~~~")

    return (
      <Dialog
        title={`${isUpdate ? "Update" : "Add New"} Sub Category`}
        button={
          isUpdate ? (
            <Button
              startIcon={<Edit />}
              className="capitalize xl:text-sm 2xl:text-semi-base"
              variant="text"
            >
              View/Edit
            </Button>
          ) : (
            <Button
              variant="outlined"
              className="px-4 ml-6 capitalize xl:text-sm  2xl:text-semi-base h-9"
              startIcon={<Add size={24} />}
            >
              New Sub-Category
            </Button>
          )
        }
      >
        {({ onClose }) => (
          <Formik
            initialValues={{ ...initialState, ...data, users: data?.users }}
            validationSchema={subcategoryValidations}
            onSubmit={(values, { resetForm }) => {
              const array = values?.users?.map((item) => item.id);
              mutate(
                {
                  category: id,
                  head: values?.head?.id,
                  name: values?.name,
                  users: array.toString(),
                },
                {
                  onSuccess: () => {
                    resetForm();
                    onClose();
                    queryClient.refetchQueries({
                      queryKey: [`/portal/subcategories/${id}`],
                    });
                    toast(
                      `Sub-Category ${
                        isUpdate ? "updated" : "created"
                      } successfully`
                    );
                  },
                }
              );
            }}
          >
            {({ values, errors }) => (
              <Form>
                <Grid container columnSpacing={4} className="mt-2">
                  <Grid xs={12} item>
                    <Input className="mt-2" name="name" label="Name" required />
                  </Grid>
                  <Grid xs={12} item>
                    <AutoComplete
                      options={managerData}
                      className="mt-2"
                      name="head"
                      label="Head"
                      getOptionLabel={(opt) =>
                        opt?.profile?.full_name || "-------"
                      }
                      // helperText={"Select Head for Sub Category."}
                      required
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <AutoComplete
                      options={usersData}
                      className="mt-2"
                      name="users"
                      label="Users"
                      multiple
                      getOptionLabel={(opt) => opt?.full_name || opt?.profile?.full_name || ""}
                      // helperText={"Select Users for Sub Category."}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Box className="mt-5 d-flex justify-content-end">
                      <Button
                        color="secondary"
                        className="px-4 capitalize  xl:text-sm 2xl:text-semi-base"
                        variant="contained"
                        onClick={onClose}
                      >
                        Discard
                      </Button>
                      <Button
                        isLoading={isLoading}
                        variant="contained"
                        className="capitalize ml-4 px-4  xl:text-sm 2xl:text-semi-base"
                        type="submit"
                        onClick={() => console.log(errors, "errors")}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
        </Formik>
      )}
    </Dialog>
  );
};
