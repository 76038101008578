import React, { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/layouts/Navbar";
import { DataGrid } from "../../components/layouts/commonLayouts/dataGrid";
import { DeleteBox } from "../../components/layouts/commonLayouts/dialogBox/delete";
import { useGetAll } from "../../hooks";
import { ProductCategoryDialog } from "../../components/AdminPanelLayout/AddProdCategoryNew";

const ProductCategory = ({ history }) => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("data")).user_role !== "Admin") {
      history.push("/view-status");
    } else {
      document.title = `All Products Categories `;
    }
    //eslint-disable-next-line
  }, []);
  const location = useLocation();
  const { title } = location.state ? location.state : "";
  const { data: productsCategory } = useGetAll({
    key: "/requests/v2/category",
    params: {
      page: 0,
      limit: 25,
    },
  });

  const columns = [
    {
      headerName: "Category",
      field: "name",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Link
            to={{
              pathname: `/request-sub-category/${row.name.replace(" ", "-")}/${
                row.id
              }`,
              state: { title: `${title}` },
            }}
            style={{ borderBottom: "1px solid #334d6eb8" }}
            className="text-black"
          >
            {row?.name}
          </Link>
        );
      },
    },

    {
      headerName: "Description",
      field: "description",
      flex: 1,
    },

    {
      headerName: "Actions",
      field: "actions",
      flex: 1.2,
      renderCell: ({ row }) => (
        <div className="flex items-center">
          <ProductCategoryDialog isUpdate data={row} />
          <div className="border rounded-xl py-2 mx-3"></div>

          <DeleteBox
            title="Product Category"
            url="/requests/category"
            data={row.id}
            refetchUrl="/requests/v2/category"
          >
            Do you really want to delete this Product Category?
          </DeleteBox>
        </div>
      ),
    },
  ];
  return (
    <>
      <Navbar
        heading={`${title ? title.toUpperCase() : ""} CATEGORY`}
        subheading=""
      />
      <section className="main-container">
        <DataGrid
          columns={columns}
          rows={productsCategory}
          url="/requests/v2/category"
          addButton={
            <div>
              <ProductCategoryDialog />
            </div>
          }
        />
      </section>
    </>
  );
};

export default React.memo(ProductCategory);
