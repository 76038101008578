import React from "react";
import TaskLabel from "../TaskLabel";

const TaskPriority = ({
  priority,
  putTask,
  id,
  formData,
  onChange,
  disabled = false,
}) => {
  if (disabled) return <>Priority : {priority || "Not Set"}</>;

  return (
    <TaskLabel
      icon={<i className="fas fa-tag ms-1"></i>}
      text={
        <>
          <select
            aria-label="Default select example"
            style={{
              outline: "none",
              border: "none",
              marginBottom: "0.5rem",
              padding: "0 0.5rem",
              marginLeft: "-0.5rem",
              width: "300px",
            }}
            name="priority"
            value={priority}
            // onChange={priorityHandler}
            onChange={onChange}
          >
            {/* <option
              style={{
                padding: "1rem",
                height: "40px",
              }}
              disabled
              value={0}
            >
              Set Priority
            </option>{" "} */}
            {["Set Priority", "High", "Medium", "Low"]?.map((p, index) => (
              <option
                key={index}
                style={{
                  padding: "1rem",
                  height: "40px",
                }}
                value={p === "Set Priority" ? null : p}
              >
                Priority : {p}
              </option>
            ))}
          </select>
        </>
      }
    />
  );
};

export default TaskPriority;
