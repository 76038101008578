import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#EDF5FF",
      main: "#0F62FE",
      contrastText: "#fff",
    },
    secondary: {
      main: "#192A3E",
      light: "#EEF0F2",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "'Aller', sans-serif",
      "'Aller Display', sans-serif",
      "'Aller Light', sans-serif",
      "'Century Gothic', sans-serif",
      "'Monstserrat', sans-serif",
    ].join(),
  },
});
